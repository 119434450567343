import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import bannerImg from './imgs/banner.jpg';

const styles = theme => ({
  title: {
    fontSize: '28px',
    color: theme.palette.textPrimary.main,
    width: '70%',
    lineHeight: '47px',
    fontWeight: 400,
    fontFamily: theme.fontFamily.primary,
    marginBottom: '30px',
  },
  subtitle: {
    fontSize: '20px',
    color: theme.palette.text.primary,
    width: '100%',
    fontWeight: 400,
    fontFamily: theme.fontFamily.primary,
    whiteSpace: 'pre',
  },
  button: {
    marginTop: '37px',
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 30,
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '400',
    width: '198px',
    height: '64px',
    textTransform: 'none',
    fontSize: '1.125rem',
  },
});
const Banner = (props) => {
  const { classes, onLearnMoreClickedHandler } = props;
  return (
    <Wrapper>
      <Wrapper1>
        <Typography className={classes.title}>
          <FormattedMessage id="banner_title" />
        </Typography>
        {/*<Typography className={classes.subtitle}>*/}
        {/*  <FormattedMessage id="banner_subTitle" />*/}
        {/*</Typography>*/}
        <Button className={classes.button} p={100} onClick={onLearnMoreClickedHandler}>
          <FormattedMessage id="banner_learnMore" />
        </Button>
      </Wrapper1>
    </Wrapper>
  );
};
const Wrapper = styled.div`
    background-image: url(${bannerImg});
    background-size: cover;
    width: 100%;
    height: 669px;
    position: relative
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper1 = styled.div`
    width: 80%;
`;
Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  onLearnMoreClickedHandler: PropTypes.func.isRequired,
};
export default withStyles(styles)(Banner);
