// GoogleMap Setting
import createSearchController from './SearchController';

function handleApiLoaded(map, maps, toggleSearchWhenMapMoveHandler, language) {
  // Set controllers
  const centerControlDiv = document.createElement('div');
  centerControlDiv.style.marginTop = '10px';
  createSearchController(centerControlDiv, map, toggleSearchWhenMapMoveHandler, language);
  centerControlDiv.index = 1;
  map.controls[maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
}
export default handleApiLoaded;
