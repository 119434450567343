import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { jumpTo } from '../../utils/route';
import Banner from './Banner/BannerContainer';
import SearchBar from '../../components/SearchBar/SearchBar';
import Category from './Category';
import AdsSliders from './AdsSlider/AdsSlider';
import Events from './Events/EventsContainer';
import Coupons from './Coupons/CouponsContainer';
import AdsBanner from './AdsBanner/AdsBanner';
import Testimony from './Testimony/Testimony';
import PhotoShow from '../../components/PhotoShow';
import Partners from './Partners/Partners';
import BottomBanner from './BottomBanner/BottomBannerContainer';
import { changeSearchFieldValue } from '../../actions/searchBar';

class HomePage extends Component {
  onSignUpHandler = () => {
    const { history, location } = this.props;
    jumpTo('/signUp', history, location);
  };

  onSearchHandler = () => {
    const { history, location } = this.props;
    jumpTo('/result-list', history, location);
  };

  onInputChange = (e) => {
    const { changeSearchFieldValue } = this.props;
    changeSearchFieldValue(e.target.value);
  };

  render() {
    const { theme, searchBarInputValue } = this.props;
    return (
        <div>
          <div>
            <Banner />
            <SearchBar
              backgroundColor={theme.palette.background.primary}
              inputBackground="#DDF5FF"
              inputPlaceHolderColor="#797979"
              onInputChange={this.onInputChange}
              onSearchHandler={this.onSearchHandler}
              value={searchBarInputValue}
            />
            <Category />
            <AdsSliders />
            <Events />
            <Coupons />
            <AdsBanner />
            <Testimony />
            <PhotoShow />
            <Partners />
            <BottomBanner onSignUpHandler={this.onSignUpHandler} />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  searchBarInputValue: state.searchBar.input,
});
const mapDispatchToProps = {
  changeSearchFieldValue,
};
HomePage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  changeSearchFieldValue: PropTypes.func.isRequired,
  searchBarInputValue: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withRouter(HomePage)));
