import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  BrowserRouter, Switch, Route,
} from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { addLocaleData, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import axios from './axios';
import enUS from './translation/english';
import zhCN from './translation/chinese';
import styles from './theme';
import HomePage from './container/HomePage/HomePage';
import SignIn from './container/SignInPage/SignInPage';
import SignUp from './container/SignUpPage/SignUpPage';
import Search from './container/SearchPage/SearchPage';
import ResultListingPage from './container/ResultListingPage/ResultListingPage';
import SchoolDetailPage from './container/SchoolDetailPage/SchoolDetailPage';
import ResetPasswordPage from './container/ResetPasswordPage/ResetPasswordPage';
import { setUser } from './actions/user';
import AboutUsPage from './container/AboutUsPage/AboutUsPage';
import ContactPage from './container/ContactPage/ContactPage';
import CouponPage from './container/CouponPage/CouponPage';
import EventPage from './container/EventPage/EventPage';
import Dialog from './components/Dialog';
import ScrollToTop from './components/widget/ScrollToTop';
import ErrorPage from './container/ErrorPage/ErrorPage';
import { PAGE_NOT_FOUND, SERVER_ERROR } from './container/ErrorPage/errorTypes';
import withNavAndFooter from './HOC/withNavAndFooter';

addLocaleData([...en, ...zh]); // add languages
// Inject font family into Global, you can access each type of font by writting their name into css
// const GlobalStyles = createGlobalStyle`
//   @font-face {
//     font-family: ${font.font_1.fontName};
//     fontWeight: 500,
//   }
//   @font-face {
//     font-family: ${font.font_2.fontName};
//     fontWeight: 200,
//   }
//   @font-face {
//     font-family: ${font.font_3.fontName};
//     fontWeight: 100,
//   }
//   @font-face {
//     font-family: ${font.font_4.fontName};
//     fontWeight: 400,
//   }
// `;

const theme = createMuiTheme(styles); // create MuiTheme
class App extends React.Component {
  componentDidMount() {
    const { setAUser } = this.props;
    axios.get('/account/self/')
      .then((res) => {
        setAUser(res.data);
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { language } = this.props;
    let message;
    if (language === 'zh-hans') {
      message = zhCN;
    } else {
      message = enUS;
    }
    return (
      <IntlProvider locale={language} messages={message}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={styles}>
            <BrowserRouter>
              <ScrollToTop>
                <Wrapper>
                  <Dialog />
                  <Switch>
                    <Route exact path="/" component={withNavAndFooter(HomePage)} />
                    <Route exact path="/signIn" component={withNavAndFooter(SignIn)} />
                    <Route exact path="/signUp" component={withNavAndFooter(SignUp)} />
                    <Route exact path="/search" component={withNavAndFooter(Search)} />
                    <Route exact path="/result-list" component={withNavAndFooter(ResultListingPage)} />
                    <Route exact path="/school-detail/:slug" component={withNavAndFooter(SchoolDetailPage)} />
                    <Route exact path="/account/password/reset/key/:key" component={withNavAndFooter(ResetPasswordPage)} />
                    <Route exact path="/contact" component={withNavAndFooter(ContactPage)} />
                    <Route exact path="/about-us" component={withNavAndFooter(AboutUsPage)} />
                    <Route exact path="/coupons" component={withNavAndFooter(CouponPage)} />
                    <Route exact path="/event" component={withNavAndFooter(EventPage)} />
                    <Route path="/error">
                      <Route exact path="/error/page-not-found" render={() => <ErrorPage type={PAGE_NOT_FOUND} />} />
                      <Route exact path="/error/server-error" render={() => <ErrorPage type={SERVER_ERROR} />} />
                    </Route>
                    <Route render={() => <ErrorPage type={PAGE_NOT_FOUND} />} />
                  </Switch>
                </Wrapper>
              </ScrollToTop>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}
// Container for all components
const Wrapper = styled.div`
    width: 100%;
    min-width: 1440px;
    margin: 0 auto;
`;
const mapStateToProps = state => ({
  language: state.language.language,
});
const mapDispatchToProps = dispatch => ({
  setAUser: user => dispatch(setUser(user)),
});
App.propTypes = {
  language: PropTypes.string.isRequired,
  setAUser: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
