import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { jumpTo } from '../../utils/route';

function CouponCard(props) {
  const {
    user, history, type, location,
  } = props;
  function _onClick(e) {
    if (_.isEmpty(user)) {
      jumpTo('/signIn', history, location);
    } else if (!_.isEmpty(e.target.dataset.url)) {
      window.open(e.target.dataset.url, '_blank');
    }
  }
  const {
    primary,
    secondary,
    url,
  } = props;
  if (type === 'small') {
    return (
      <CardSmall
        src={_.isEmpty(user) ? primary : secondary || primary}
        onClick={_onClick}
        data-url={url}
      />
    );
  }
  return (
    <Card
      src={_.isEmpty(user) ? primary : secondary || primary}
      onClick={_onClick}
      data-url={url}
    />
  );
}
const CardSmall = styled.div`
  background-color: white;
  width: 289px;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: url("${props => props.src}");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
const Card = styled.div`
  //width: 371px;
  height: 197px;
  //margin: 0 5px;
  border: 1px solid #d8d8d8;
  background-image: url("${props => props.src}");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

CouponCard.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
CouponCard.defaultProps = {
  url: undefined,
  type: 'default',
  secondary: undefined,
};
const mapStateToProps = state => ({
  user: state.authentication.user,
});
export default connect(mapStateToProps, null)(withRouter((withTheme(CouponCard))));
