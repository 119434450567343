import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import UserIcon from './UserIcon';
import keys from '../../config';
import createMapOptions from './createMapOptions';
import handleApiLoaded from './handleApiLoaded';
import icon from './imgs/markers/schoolMarker.png';
import School from './School/School';
import star1 from '../../assets/imgs/rating/1stars.png';
import star2 from '../../assets/imgs/rating/2stars.png';
import star3 from '../../assets/imgs/rating/3stars.png';
import star4 from '../../assets/imgs/rating/4stars.png';
import star5 from '../../assets/imgs/rating/5stars.png';

const { googleMapKey } = keys;

/**
 * @return {null}
 */
class Map extends React.Component {
  render() {
    const {
      userLocation,
      mapCenter,
      schools,
      toggleSearchWhenMapMoveHandler,
      onMapChangeHandler,
      showInfoIndex,
      onSchoolClickedHandler,
    } = this.props;

    // Prepare GoogleMap Icons
    const contents = schools.map((school, index) => {
      // Create InfoWindow
      const ratingCount = school.five_star_count
        + school.four_star_count
        + school.three_star_count
        + school.two_star_count
        + school.one_star_count;
      let rateImgURL;
      switch (school.average_rating) {
        case 1: {
          rateImgURL = star1;
          break;
        }
        case 2: {
          rateImgURL = star2;
          break;
        }
        case 3: {
          rateImgURL = star3;
          break;
        }
        case 4: {
          rateImgURL = star4;
          break;
        }
        case 5: {
          rateImgURL = star5;
          break;
        }
        default: {
          rateImgURL = star1;
        }
      }
      // for each tag create a button
      let tags;
      if (school.tags !== null) {
        if (school.tags.length > 1) {
          tags = (
            <button
              type="button"
              disabled
              style={{
                borderRadius: '10px', border: '1px solid black', backgroundColor: 'white', textTransform: 'capitalize', color: '#000',
              }}
            >
              <FormattedMessage id="integrated_school" />
            </button>
          );
        } else {
          let type = 'UNKNOWN';
          switch (school.tags[0]) {
            case 'academy': {
              type = 'academy';
              break;
            }
            case 'music': {
              type = 'music';
              break;
            }
            case 'private-school': {
              type = 'private_school';
              break;
            }
            case 'sport': {
              type = 'sport';
              break;
            }
            case 'summer-camp': {
              type = 'summer_camp';
              break;
            }
            case 'integrated-school': {
              type = 'integrated_school';
              break;
            }
            case 'art': {
              type = 'art';
              break;
            }
            default:
              break;
          }
          tags = (
            <button
              type="button"
              disabled
              style={{
                borderRadius: '10px', border: '0.5px solid #565656', backgroundColor: 'white', textTransform: 'capitalize', color: '#565656',
              }}
            >
              <FormattedMessage id={type} />
            </button>
          );
        }
      } else {
        tags = [];
      }
      // decide whether to show school's info panel
      let shouldShow = false;
      if (showInfoIndex === index) {
        shouldShow = true;
      }
      return (
        <School
          slug={school.slug}
          lat={school.coordinates[1]}
          lng={school.coordinates[0]}
          icon={icon}
          logo={school.logo}
          name={school.name}
          tags={tags}
          rateImgURL={rateImgURL}
          ratingCount={ratingCount}
          key={school.slug}
          shouldShow={shouldShow}
          index={index}
          onSchoolClickedHandler={onSchoolClickedHandler}
        />
      );
    });
    // Add user icon
    let userIcon = null;
    if (userLocation !== null) {
      userIcon = <UserIcon lat={userLocation.lat} lng={userLocation.lng} />;
    }
    // render Google Map
    return (
      <GoogleMapReact
        onChange={onMapChangeHandler}
        bootstrapURLKeys={{ key: googleMapKey }}
        center={
          {
            lat: mapCenter.lat,
            lng: mapCenter.lng,
          }
        }
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {  // eslint-disable-line
          return handleApiLoaded(map, maps, toggleSearchWhenMapMoveHandler);
        }}
        options={createMapOptions}
      >
        {contents}
        {userIcon}
      </GoogleMapReact>
    );
  }
}


Map.propTypes = {
  userLocation: PropTypes.object,
  mapCenter: PropTypes.object.isRequired,
  schools: PropTypes.array.isRequired,
  showInfoIndex: PropTypes.number.isRequired,
  onMapChangeHandler: PropTypes.func.isRequired,
  toggleSearchWhenMapMoveHandler: PropTypes.func.isRequired,
  onSchoolClickedHandler: PropTypes.func.isRequired,
};
Map.defaultProps = {
  userLocation: null,
};
const mapStateToProps = state => ({
  userLocation: state.location,
  mapCenter: state.map.mapCenter,
  schools: state.schools.schools,
});
export default connect(mapStateToProps, null)(Map);
