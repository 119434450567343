import { TOGGLE_LANGUAGE } from '../actions/actionTypes';
import cookies from '../cookie';
import keys from '../config';
// Initial Language Setting
// Order: cookie > browser
let language;
if (cookies.get('django_language') !== undefined) {
  language = cookies.get('django_language');
} else {
  language = 'en';
  cookies.set('django_language', language, keys.cookieSetting);
}
const initialState = {
  language,
};
const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LANGUAGE: {
      if (state.language === 'zh-hans') {
        cookies.set('django_language', 'en', keys.cookieSetting);
        return { ...state, language: 'en' };
      }
      cookies.set('django_language', 'zh-hans', keys.cookieSetting);
      return { ...state, language: 'zh-hans' };
    }
    default:
      return state;
  }
};

export default languageReducer;
