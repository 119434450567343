import React from 'react';
import styled from 'styled-components';
import logoWithText from '../../assets/imgs/mentors-logo-text-white.png';
import FaceBookIcon from '../FaceBookIcon/FaceBookIcon';
import InstagramIcon from '../InstagramIcon/InstagramIcon';
import { FACE_BOOK_URL, INSTAGRAM_URL } from '../../constants/socialLinks';

const socialLinkStyle = {
  fontSize: '20px',
  color: '#fff',
};
function Footer() {
  return (
    <Wrapper>
      <Content>
        <Logo />
        <div>
          <FaceBookIcon url={FACE_BOOK_URL} style={socialLinkStyle} />
          <InstagramIcon url={INSTAGRAM_URL} style={socialLinkStyle} />
        </div>
        <CopyRight>
                    Copyright
          {' '}
          {new Date().getFullYear()}
          {' '}
Mentors Services Inc. All rights reserved.
        </CopyRight>
        <PowerBy>
          Powered by
          {' '}
          {' '}
          <A target="_blank" href="https://acrossor.com/">Acrossor</A>
        </PowerBy>
      </Content>
    </Wrapper>
  );
}
const A = styled.a`
   color: ${props => props.theme.palette.primary.main};
`;
const Wrapper = styled.div`
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.palette.background.primary}   
`;
const Content = styled.div`
    text-align: center;
`;
const Logo = styled.div`
    background-image: url(\"${logoWithText}\");
    background-position: center;
    background-repeat: no-repeat;
    height: 125px;
`;
const CopyRight = styled.div`
    color: ${props => props.theme.palette.primary.main};
`;
const PowerBy = styled.div`
    color: ${props => props.theme.palette.primary.main};
    font-size:0.8rem;
`;
export default Footer;
