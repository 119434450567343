import {
  SIGN_UP_PENDING,
  SIGN_UP_FAIL,
  SIGN_UP_SUCCESS,
  VALIDATE_INPUT_FAIL,
} from '../actions/actionTypes';
import _ from 'lodash';
const initialState = {
  errors: [],
  isLoading: false,
};
const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_SUCCESS: {
      return {
        isLoading: false,
        errors: [],
      };
    }
    case SIGN_UP_PENDING: {
      return {
        isLoading: true,
        errors: [],
      };
    }
    case SIGN_UP_FAIL: {
      if(_.isEmpty(action.payload)){
        return {
          isLoading: false,
          errors: [],
        }
      }else{
        return {
          isLoading: false,
          errors: action.payload,
        };
      }
    }
    case VALIDATE_INPUT_FAIL: {
      if(_.isEmpty(action.payload)){
        return {
          isLoading: false,
          errors: [],
        }
      }else {
        return {
          ...state,
          isLoading: false,
          errors: action.payload,
        };
      }
    }
    default:
      return state;
  }
};

export default registrationReducer;
