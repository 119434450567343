import { useLocation } from 'react-router-dom';
import {
  GET_SCHOOL_LOADING,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,
  GET_SCHOOL_DETAIL_LOADING,
  GET_SCHOOL_DETAIL_SUCCESS,
  GET_SCHOOL_DETAIL_FAIL,
} from './actionTypes';
import axios from '../axios';

export const getSchool = location => (dispatch) => {
  dispatch({
    type: GET_SCHOOL_LOADING,
  });
  axios.get('/schools/', {
    params: {
      center_lat: location.lat,
      center_lng: location.lng,
    },
  })
    .then(response => dispatch({
      type: GET_SCHOOL_SUCCESS,
      payload: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch({
          type: GET_SCHOOL_FAIL,
          payload: error.response.data.errors,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
};

export const getSchoolWithFilter = (location, filter) => (dispatch) => {
  const {
    input, distance, star, category,
  } = filter;
  dispatch({
    type: GET_SCHOOL_LOADING,
  });
  const params = {};
  params.center_lat = location.lat;
  params.center_lng = location.lng;
  params.count = 10000; // A Large Number
  if (input) {
    params.q = input;
  }
  if (distance) {
    params.distance = distance;
  }
  if (star) {
    params.rating = star;
  }
  if (category) {
    params.tag = category;
  }
  axios.get('/schools/', {
    params,
  })
    .then(response => dispatch({
      type: GET_SCHOOL_SUCCESS,
      payload: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch({
          type: GET_SCHOOL_FAIL,
          payload: error.response.data.errors,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
};

export const getSchoolDetail = slug => (dispatch) => {
  dispatch({
    type: GET_SCHOOL_DETAIL_LOADING,
  });
  return axios.get(`/schools/${slug}`)
    .then(response => dispatch({
      type: GET_SCHOOL_DETAIL_SUCCESS,
      payload: response.data,
    }))
    .catch((error) => {
      throw error;
    });
};
