import { SET_USER_LOCATION } from '../actions/actionTypes';

const initialState = null;
const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOCATION: {
      return {
        lat: action.payload.lat,
        lng: action.payload.lng,
      };
    }
    default:
      return state;
  }
};

export default locationReducer;
