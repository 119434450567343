import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuid from 'uuid';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

function Filter(props) {
  const {
    width, height, options, value, onChange, defaultText,
  } = props;
  return (
    <Select width={width} height={height} onChange={onChange} value={value}>
      <FormattedMessage id={defaultText}>
        {message => <Option key={uuid()} value="">{message}</Option> }
      </FormattedMessage>
      {
        options.map(option => (
          <FormattedMessage id={option.text} key={option.text}>
            { message => <Option value={option.value}>{message}</Option> }
          </FormattedMessage>
        ))
      }
    </Select>
  );
}
Filter.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultText: PropTypes.string.isRequired,
};
Filter.defaultProps = {
  width: '112px',
  height: '30px',
  options: [],
};
const Select = styled.select`
  font-family:${props => props.theme.fontFamily.primary};
  font-weight: 100;
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 15px;
  padding: 2px 10px;
  margin-right: 5px;
  background-color: ${props => (_.isEmpty(props.value) ? '#fff' : props.theme.palette.background.blue)}};
  outline: none;
  text-align-last: center;
`;
const Option = styled.option`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
  background-color: #fff;
`;
export default React.memo(Filter);
