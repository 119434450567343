import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Search from './Search/Search';
import Map from '../../components/Map/Map';
import { setUserLocation } from '../../actions/location';
import { setMapCenter, setMapLoading } from '../../actions/map';
import { getSchool } from '../../actions/school';
import { toronto } from '../../components/Map/locations';
import Spinner from '../../components/Spinner/Spinner';
import { jumpTo } from '../../utils/route';

class SearchPage extends React.Component {
    state = {
      showInfoIndex: -1,
      searchWhenMapMove: true,
    };

    componentDidMount() {
      const { setMapLoading } = this.props;
      setMapLoading();
      // GET USER'S GEO LOCATION
    navigator.geolocation.getCurrentPosition(this.getCurrentPositionSuccessCallBack.bind(this), this.getCurrentPositionFailCallBack.bind(this)); //eslint-disable-line
    }

    componentDidUpdate(prevProps) {
      const { schools } = this.props;
      const { showInfoIndex } = this.state;
      if (prevProps.schools !== schools) {
        if (showInfoIndex !== -1) {
        const id = prevProps.schools[showInfoIndex].id; // eslint-disable-line
          let flag = false;
          schools.forEach((school, index) => {
            if (school.id === id) {
              flag = true;
              this.setState({
                showInfoIndex: index,
              });
            }
          });
          if (!flag) {
          this.setState({ // eslint-disable-line
              showInfoIndex: -1,
            });
          }
        }
      }
    }

  onMapChangeHandler = ({ center }) => {
    const { searchWhenMapMove } = this.state;
    const {
      setMapCenter,
      getSchool,
    } = this.props;
    // Change userLocation if this.state.searchWhenMapMove === true

    setMapCenter(center);
    if (searchWhenMapMove === true) {
      getSchool(center);
    }
  };

  onSchoolClickedHandler = (index) => {
    this.setState({
      showInfoIndex: index,
    });
  };

  onSchoolInfoClickedHandler = (index) => {
    const { history, location, schools } = this.props;
    const { id } = schools[index];
    jumpTo(`/detailPage?id=${id}`, history, location);
  };

  getCurrentPositionSuccessCallBack = (location) => {
    // When user agree to offer their location to us
    // Update userLocation in Redux
    const {
      setUserLocation,
      setMapCenter,
      getSchool,
    } = this.props;
    const { coords } = location;
    // Format location info string => number
    const loc = {
      lat: _.toNumber(coords.latitude),
      lng: _.toNumber(coords.longitude),
    };
    // set both userLocation and mapCenter to the location that user give
    setUserLocation(loc);
    setMapCenter(loc);
    getSchool(loc);
  };

  getCurrentPositionFailCallBack() {
    const {
      setMapCenter,
      getSchool,
    } = this.props;
    const center = {
      lat: toronto.lat,
      lng: toronto.lng,
    };
    setMapCenter(center);
    getSchool(center);
  }

  toggleSearchWhenMapMoveHandler = () => {
    const { searchWhenMapMove } = this.state;
    this.setState({
      searchWhenMapMove: !searchWhenMapMove,
    });
  };

  render() {
    const { showInfoIndex } = this.state;
    const { isMapLoading } = this.props;
    return (
      <div>
        <div>
          <Search />
          <MapWrapper>
            {
              isMapLoading
                ? <Spinner />
                : (
                  <Map
                    showInfoIndex={showInfoIndex}
                    onMapChangeHandler={this.onMapChangeHandler}
                    toggleSearchWhenMapMoveHandler={this.toggleSearchWhenMapMoveHandler}
                    onSchoolClickedHandler={this.onSchoolClickedHandler}
                    onSchoolInfoClickedHandler={this.onSchoolInfoClickedHandler}
                  />
                )
            }
          </MapWrapper>
        </div>
      </div>
    );
  }
}
const MapWrapper = styled.div`
  height: 764px;
  width: 100%;
`;
SearchPage.propTypes = {
  setUserLocation: PropTypes.func.isRequired,
  getSchool: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setMapCenter: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  schools: PropTypes.array.isRequired,
  isMapLoading: PropTypes.bool.isRequired,
  setMapLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isMapLoading: state.map.isLoading,
  schools: state.schools.schools,
});

const mapDispatchToProps = {
  setUserLocation,
  setMapCenter,
  getSchool,
  setMapLoading,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchPage));
