import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BottomBanner from './BottomBanner';
import { jumpTo } from '../../../utils/route';

class BottomBannerContainer extends Component {
  onContactUsHandler = () => {
    const { history, location } = this.props;
    jumpTo('/contact', history, location);
  };

  onSignUpHandler = () => {
    const { history, location } = this.props;
    jumpTo('/signUp', history, location);
  };

  render() {
    return (
      <BottomBanner
        onContactUsHandler={this.onContactUsHandler}
        onSignUpHandler={this.onSignUpHandler}
      />
    );
  }
}

BottomBannerContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(BottomBannerContainer);
