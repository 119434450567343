import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function Icon(props) {
  const {
    backgroundImg, height, width, marginBottom,
  } = props;
  return (
    <IconWrapper
      backgroundImg={backgroundImg}
      height={height}
      width={width}
      marginBottom={marginBottom}
    />
  );
}
const IconWrapper = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: ${props => `url("${props.backgroundImg}")`};
    background-size: cover;
    margin-bottom: ${props => props.marginBottom}
`;

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundImg: PropTypes.string.isRequired,
  marginBottom: PropTypes.string,
};
Icon.defaultProps = {
  width: '80px',
  height: '80px',
  marginBottom: '33px',
};
export default Icon;
