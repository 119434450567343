import { GET_SCHOOL_DETAIL_SUCCESS, GET_SCHOOL_DETAIL_LOADING, GET_SCHOOL_DETAIL_FAIL } from '../actions/actionTypes';

const initialState = {
  school: {},
  isLoading: true,
};

const schoolDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_DETAIL_LOADING: {
      return {
        school: {},
        isLoading: true,
      };
    }
    case GET_SCHOOL_DETAIL_SUCCESS: {
      return {
        ...state,
        school: action.payload,
        isLoading: false,
      };
    }
    case GET_SCHOOL_DETAIL_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default schoolDetailReducer;
