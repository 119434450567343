import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import scrollbarWidth from 'scrollbar-width';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import SearchBarContainer from './SearchBarContainer/SearchBarContainer';
import Filter from '../../components/Filter/Filter';
import { changeCategoryFilter, changeDistanceFilter, changeStarFilter } from '../../actions/filter';
import { getSchoolWithFilter } from '../../actions/school';
import { setUserLocation } from '../../actions/location';
import SchoolIconPanel from './SchoolIconPanel/SchoolIconPanel';
import { options1, options2, options3 } from '../../components/Filter/options';
import { setMapCenter, setMapLoading } from '../../actions/map';
import { toronto } from '../../components/Map/locations';
import Spinner from '../../components/Spinner/Spinner';
import MapContainer from './MapContainer/MapContainer';

const scrollWidth = scrollbarWidth(); // Get ScrollBar's width

class ResultListingPage extends React.Component {
    state = {
      showInfoIndex: -1,
    };

    componentDidMount() {
      const { setMapLoad } = this.props;
      setMapLoad();
      // GET USER'S GEO LOCATION
      navigator.geolocation.getCurrentPosition(this.getCurrentPositionSuccessCallBack.bind(this), this.getCurrentPositionFailCallBack.bind(this)); //eslint-disable-line
    }

    componentDidUpdate(prevProps) {
      const { schools } = this.props;
      const { showInfoIndex } = this.state;
      if (prevProps.schools !== schools) {
        if (showInfoIndex !== -1) {
          const { id } = prevProps.schools[showInfoIndex];
          let flag = false;
          schools.forEach((school, index) => {
            if (school.id === id) {
              flag = true;
              this.setState({
                showInfoIndex: index,
              });
            }
          });
          if (!flag) {
            this.setState({ // eslint-disable-line
              showInfoIndex: -1,
            });
          }
        }
      }
    }

  onSchoolClickedHandler = (index) => {
    this.setState({
      showInfoIndex: index,
    });
  };


  getCurrentPositionSuccessCallBack(location) {
    // When user agree to offer their location to us
    // Update userLocation in Redux
    const {
      setUserLocation,
      distance,
      star,
      category,
      value,
      setCenter,
      getSchoolsWithFilter,
    } = this.props;
    const { coords } = location;
    // Format location info string => number
    const loc = {
      lat: _.toNumber(coords.latitude),
      lng: _.toNumber(coords.longitude),
    };
    const filter = {
      distance,
      star,
      category,
      input: value,
    };
    // set both userLocation and mapCenter to the location that user give
    setUserLocation(loc);
    setCenter(loc);
    getSchoolsWithFilter(loc, filter);
  }

  getCurrentPositionFailCallBack() {
    const {
      distance,
      star,
      category,
      value,
      setCenter,
      getSchoolsWithFilter,
    } = this.props;
    const filter = {
      distance,
      star,
      category,
      input: value,
    };
    const center = {
      lat: toronto.lat,
      lng: toronto.lng,
    };
    setCenter(center);
    getSchoolsWithFilter(center, filter);
  }

  render() {
    const {
      distance,
      star,
      category,
      changeDistance,
      changeStar,
      changeCategoryFilter,
      schools,
      isLoading,
      isMapLoading,
    } = this.props;
    const {
      showInfoIndex,
    } = this.state;
    let schoolList;
    if (schools.length > 0) {
      schoolList = schools.map((school, index) => {
        const {
          name,
          average_rating,
          student_rating,
          profession_rating,
          service_rating,
          env_rating,
          five_star_count,
          four_star_count,
          three_star_count,
          two_star_count,
          one_star_count,
          logo,
          phone_number,
          address,
          slug,
        } = school;
        return (
          <SchoolIconPanel
            slug={slug}
            key={slug}
            name={name}
            average_rating={average_rating}
            student_rating={student_rating}
            profession_rating={profession_rating}
            service_rating={service_rating}
            env_rating={env_rating}
            reviewCount={one_star_count + two_star_count + three_star_count + four_star_count + five_star_count}
            phone_number={phone_number}
            address={address}
            logo={logo}
            onMouseEnterSchoolCard={this.onMouseEnterSchoolCard}
            index={index}
          />
        );
      });
    } else {
      schoolList = <FormattedMessage id="resultListing_notSchoolFound">{msg => <div style={{ textAlign: 'center' }}>{msg}</div>}</FormattedMessage>;
    }

    return (
      <div>
        <Div1 />
        <Div2>
          <SearchBarContainer
            onSearchHandler={this.onSearchHandler}
            inputBackground="#FFF"
            inputPlaceHolderColor="#DCDCDC"
            onInputChange={this.onInputChange}
          />
        </Div2>
        <Filters>
          <Filter options={options1} onChange={changeDistance} value={distance} defaultText="distance" />
          <Filter options={options2} onChange={changeStar} value={star} defaultText="rating" />
          <Filter options={options3} onChange={changeCategoryFilter} width="175px" value={category} defaultText="category" />
        </Filters>
        <Wrapper>
          <ListWrapper paddingRight={scrollWidth}>
            {isLoading ? <Spinner /> : schoolList}
          </ListWrapper>
          <MapWrapper>
            {
              isMapLoading ? <Spinner />
                : (
                  <MapContainer
                    showInfoIndex={showInfoIndex}
                    onSchoolClickedHandler={this.onSchoolClickedHandler}
                  />
                )
            }
          </MapWrapper>
        </Wrapper>
      </div>
    );
  }
}
const Div1 = styled.div`
  height: 71px;
  background: ${props => props.theme.palette.background.primary}
`;
const Div2 = styled.div`
  height: 100px;
  background: ${props => props.theme.palette.background.blue}
`;
const Filters = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  margin-left: 60px;
  background-color: white;
`;
const Wrapper = styled.div`
  display: flex;
  height: 764px;
`;
const ListWrapper = styled.div`
  min-width: 600px;
  height: 764px;
  overflow-x: auto;
  box-sizing: border-box;
  padding-right: ${props => props.paddingRight};
`;
const MapWrapper = styled.div`
  height: 764px;
  flex-grow: 1;
`;
ResultListingPage.propTypes = {
  value: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  star: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  changeDistance: PropTypes.func.isRequired,
  changeStar: PropTypes.func.isRequired,
  changeCategoryFilter: PropTypes.func.isRequired,
  schools: PropTypes.array.isRequired,
  setUserLocation: PropTypes.func.isRequired,
  getSchoolsWithFilter: PropTypes.func.isRequired,
  setCenter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMapLoading: PropTypes.bool.isRequired,
  setMapLoad: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  schools: state.schools.schools,
  value: state.searchBar.input,
  distance: state.filter.distance,
  star: state.filter.star,
  category: state.filter.category,
  isLoading: state.schools.isLoading,
  isMapLoading: state.map.isLoading,
});

const mapDispatchToProps = dispatch => ({
  changeDistance: event => dispatch(changeDistanceFilter(event.target.value)),
  changeStar: event => dispatch(changeStarFilter(event.target.value)),
  changeCategoryFilter: event => dispatch(changeCategoryFilter(event.target.value)),
  setUserLocation: location => dispatch(setUserLocation(location)),
  getSchoolsWithFilter: (location, filter) => dispatch(getSchoolWithFilter(location, filter)),
  setCenter: (location, filter) => dispatch(setMapCenter(location, filter)),
  setMapLoad: () => dispatch(setMapLoading()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultListingPage));
