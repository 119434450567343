import React from 'react';
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';

function withNavAndFooter(Component) {
  return props => (
    <React.Fragment>
      <Nav />
      <Component {...props} />
      <Footer />
    </React.Fragment>
  );
}
export default withNavAndFooter;
