import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import uuid from 'uuid';
import defaultLogo from '../../../assets/imgs/defaultLogo.png';
import dolphinBlue from '../../../assets/icons/dolphinBlue.png';
import dolphinGrey from '../../../assets/icons/dolphinGrey.png';
import { postReview } from '../../../actions/review';
import { openDialog } from '../../../actions/ui/dialog';

class WriteReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: 1,
      professional: 1,
      ambienceAndEquipments: 1,
      service: 1,
      review: '',
    };

    this.onReviewChange = this.onReviewChange.bind(this);
    this.onStudentRatingChange = this.onStudentRatingChange.bind(this);
    this.onProfessionalRatingChange = this.onProfessionalRatingChange.bind(this);
    this.onAmbienceRatingChange = this.onAmbienceRatingChange.bind(this);
    this.onServiceChange = this.onServiceChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onReviewChange(e) {
    this.setState({
      review: e.target.value,
    });
  }

  onProfessionalRatingChange(newRate) {
    this.setState({
      professional: newRate,
    });
  }

  onStudentRatingChange(newRate) {
    this.setState({
      student: newRate,
    });
  }

  onAmbienceRatingChange(newRate) {
    this.setState({
      ambienceAndEquipments: newRate,
    });
  }

  onServiceChange(newRate) {
    this.setState({
      service: newRate,
    });
  }

  onSubmit() {
    const {
      slug,
      history,
      intl: { formatMessage },
      openDialog,
    } = this.props;
    const {
      student,
      professional,
      ambienceAndEquipments,
      service,
      review,
    } = this.state;
    const onSuccess = () => {
      openDialog(formatMessage({ id: 'dialog_success_title' }, formatMessage({ id: 'writeReview_success' })));
      window.location.reload();
    };
    const onFail = () => {
      openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'writeReview_fail' }));
      window.location.reload();
    };
    postReview(slug, student, professional, service, ambienceAndEquipments, review, history, onSuccess, onFail); //eslint-disable-line
  }
  /* eslint-disable */
  render() {
    const { logo, address, name, onCloseWriteReviewModal } = this.props;
    const { student, professional, ambienceAndEquipments, service} = this.state;
    let studentRating, professionalRating, ambienceRating, serviceRating;
    studentRating = [];
    professionalRating = [];
    ambienceRating = [];
    serviceRating = [];
    for(let i = 0; i < 5; i++){
      if(i<student){
        studentRating.push(<DolphinBlue key={uuid()} onClick={() => this.onStudentRatingChange(i+1)}/>);
      }else{
        studentRating.push(<DolphinGrey key={uuid()} onClick={() => this.onStudentRatingChange(i+1)}/>);
      }
    }
    for(let i = 0; i < 5; i++){
      if(i<professional){
        professionalRating.push(<DolphinBlue key={uuid()} onClick={() => this.onProfessionalRatingChange(i+1)}/>);
      }else{
        professionalRating.push(<DolphinGrey key={uuid()} onClick={() => this.onProfessionalRatingChange(i+1)}/>);
      }
    }
    for(let i = 0; i < 5; i++){
      if(i<ambienceAndEquipments){
        ambienceRating.push(<DolphinBlue key={uuid()} onClick={() => this.onAmbienceRatingChange(i+1)}/>);
      }else{
        ambienceRating.push(<DolphinGrey key={uuid()} onClick={() => this.onAmbienceRatingChange(i+1)}/>);
      }
    }
    for(let i = 0; i < 5; i++){
      if(i<service){
        serviceRating.push(<DolphinBlue key={uuid()} onClick={() => this.onServiceChange(i+1)}/>);
      }else{
        serviceRating.push(<DolphinGrey key={uuid()} onClick={() => this.onServiceChange(i+1)}/>);
      }
    }
    return (
      <Wrapper onClick={onCloseWriteReviewModal}>
        <Content onClick={(e)=> e.stopPropagation()}>
          <Title><FormattedMessage id="writeReview_title" /></Title>
          <Wrapper2>
            <Left>
              <Logo background={logo} />
              <Name>{name}</Name>
              <Address>{address}</Address>
            </Left>
            <Right>
              <Rate>
                <Column1>
                  <Rating>
                    <RatingTitle><FormattedMessage id="rating_studentRating" /></RatingTitle>
                    {
                      studentRating
                    }
                  </Rating>
                  <Rating>
                    <RatingTitle><FormattedMessage id="rating_environmentAndEquipment" /></RatingTitle>
                    {
                      ambienceRating
                    }
                  </Rating>
                </Column1>
                <Column2>
                  <Rating>
                    <RatingTitle><FormattedMessage id="rating_professionalism" /></RatingTitle>
                    {
                      professionalRating
                    }
                  </Rating>
                  <Rating>
                    <RatingTitle>Service</RatingTitle>
                    {
                      serviceRating
                    }
                  </Rating>
                </Column2>
              </Rate>
              <Row3>
                <RatingTitle>Review</RatingTitle>
                <FormattedMessage id="writeReview_textarea_placeHolder">
                  {
                    msg => <TextArea placeholder={msg} onChange={this.onReviewChange}/>
                  }

                </FormattedMessage>
                <div>
                  <FormattedMessage id="writeReview_submit">
                    {
                      msg => <Button onClick={this.onSubmit}>{msg}</Button>
                    }
                  </FormattedMessage>
                </div>
              </Row3>
            </Right>
          </Wrapper2>
        </Content>
      </Wrapper>
    );
  }
}
const Rate = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Rating = styled.div`
  margin-bottom: 30px;
`;
const RatingTitle = styled.div`
  margin-bottom: 11px;
`;
const Wrapper = styled.div`
  transform: scale(1);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgb(0,0,0);
  background-color: rgb(0,0,0,0.4);
`;
const Content = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 950px;
  border: 1px solid #888;
  padding: 25px;
`;
const TextArea = styled.textarea`
  font-family:${props => props.theme.fontFamily.primary};
  font-weight: 100;
  height: 150px;
  width: 365px;
  padding: 6px 10px;
`;
const Button = styled.div`
  background-color: ${props => props.theme.palette.background.secondary};
  color: ${props => props.theme.palette.text.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 3.2px;
`;
const DolphinBlue = styled.div`
  display: inline-box;
  width: 18px;
  height: 18px;
  background-image: url(\"${dolphinBlue}\");
  margin-right: 4px;
`;
const DolphinGrey = styled.div`
  display: inline-box;
  width: 18px;
  height: 18px;
  background-image: url(\"${dolphinGrey}\");
  margin-right: 4px;
`;
const Column1 = styled.div`
  display: flex;
  flex-direction: column;
`;
const Column2 = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row3 = styled.div`
`;
const Name = styled.div`
  font-size: 1.5rem;
  padding: 13px 23px;
  font-family：${props => props.theme.fontFamily.primary};
  overflow-wrap: break-word;
`;
const Address = styled.div`
  padding: 0 23px 13px 23px;
  overflow-wrap: break-word;
`;
const Left = styled.div`
  border: 1px solid #979797;
  width: 282px;
`;
const Right = styled.div`
  margin-left: 30px;
`;
const Logo = styled.div`
  width: 280px;
  height: 280px;
  background-image: ${props => `url("${props.background}")`};
  background-size: cover;
  background-position: center;
`;
const Title = styled.div`
  text-align: center;
  font-size: 2rem;
`;
const Wrapper2 = styled.div`
  display: flex;
  margin: 30px 100px;
`;
WriteReview.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  logo: PropTypes.string,
  postAReview: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired,
};
WriteReview.defaultProps = {
  logo: defaultLogo,
};

const mapDispatchToProps = dispatch => ({
  openDialog: (title, message) => dispatch(openDialog(title, message))
});
export default connect(null, mapDispatchToProps)(withTheme(withRouter(injectIntl(WriteReview))));
