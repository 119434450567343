import React from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CouponCard from '../../../components/CouponCard/CouponCard';
import coupon1Primary from '../../../assets/imgs/coupons/7.jpg';
import coupon1PrimaryCN from '../../../assets/imgs/coupons/7CN.jpg';
// import coupon1Secondary from '../../assets/imgs/coupons/8.jpg';
import coupon2Primary from '../../../assets/imgs/coupons/8.jpg';
// import coupon2Secondary from '../../assets/imgs/coupons/6.1.jpeg';
import coupon3Primary from '../../../assets/imgs/coupons/9.png';
import coupon3PrimaryCN from '../../../assets/imgs/coupons/9CN.png';
import coupon4Primary from '../../../assets/imgs/coupons/10.jpg';
import coupon4PrimaryCN from '../../../assets/imgs/coupons/10CN.jpg';
import coupon3Covered from "../../../assets/imgs/coupons/summerCamps/gobeyond-cover.jpg";
import coupon3Exposed from "../../../assets/imgs/coupons/summerCamps/gobeyond-ex.jpg";
import coupon24Covered from "../../../assets/imgs/coupons/Sports/Barca_coupon.jpg";
import coupon24Exposed from "../../../assets/imgs/coupons/Sports/https_fcbcanada.com.jpg";
import coupon17Covered from "../../../assets/imgs/coupons/Academy/Mathnasium_Academy_coupon.jpg";
import coupon17Exposed from "../../../assets/imgs/coupons/Academy/https_www.mathnasium.ca_markham_.jpg";
import coupon14Covered from "../../../assets/imgs/coupons/Academy/Athena_Academy_coupon.jpg";
import coupon14Exposed from "../../../assets/imgs/coupons/Academy/https_www.athenaeducation.ca_.jpg";

const styles = theme => ({
  title: {
    fontSize: '28px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '400',
  },
  more: {
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
});
function Coupons(props) {
  const { classes, language } = props;
  const couponData = [
    {
      url: "http://www.gobeyondacademy.com",
      covered: coupon3Covered,
      exposed: coupon3Exposed
    },
    {
      url: "https://fcbcanada.com",
      covered: coupon24Covered,
      exposed: coupon24Exposed
    },
    {
      url: "https://www.mathnasium.ca/markham/",
      covered: coupon17Covered,
      exposed: coupon17Exposed
    },
    {
      url: "https://www.athenaeducation.ca/",
      covered: coupon14Covered,
      exposed: coupon14Exposed
    },
  ];
  return (
    <Wrapper>
      <Wrapper1>
        <Typography align="center" className={classes.title} color="textSecondary">
          <FormattedMessage id="coupons_title" />
        </Typography>
        <CouponWrapper>
          { couponData.map(item=><CouponCard
            primary={item.covered}
            url={item.url} type="small"
          />)}
        </CouponWrapper>
        <Wrapper2>
          <Link to="/coupons">
            <Typography className={classes.more} color="textPrimary">
              <FormattedMessage id="coupons_more" />
            </Typography>
          </Link>
        </Wrapper2>
      </Wrapper1>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    background-color: ${props => props.theme.palette.background.secondary};
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper1 = styled.div`
    margin: 0 auto;
`;
const Wrapper2 = styled.div`
    text-align: center;
`;
const CouponWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 26px 0px;
`;
Coupons.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default withStyles(styles)(Coupons);
