import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SearchBar from '../../../components/SearchBar/SearchBar';
import {getSchoolWithFilter} from "../../../actions/school";
import {changeSearchFieldValue} from "../../../actions/searchBar";

function SearchBarContainer(props) {
  const {
    value,
    inputBackground,
    inputPlaceHolderColor,
    changeSearchFieldValue,
    distance,
    star,
    category,
    getSchoolWithFilter,
    mapCenter,
  } = props;

  const onInputChange = (e) => {
    changeSearchFieldValue(e.target.value);
  };

  const onSearchHandler = () => {
    const filter = {
      distance,
      star,
      input: value,
      category,
    };
    getSchoolWithFilter(mapCenter, filter);
  };
  return (
    <SearchBar
      inputBackground={inputBackground}
      inputPlaceHolderColor={inputPlaceHolderColor}
      onInputChange={onInputChange}
      onSearchHandler={onSearchHandler}
      value={value}
    />
  );
}
const mapStateToProps = state => ({
  value: state.searchBar.input,
  distance: state.filter.distance,
  star: state.filter.star,
  category: state.filter.category,
  mapCenter: state.map.mapCenter,
});

const mapDispatchToProps = {
  getSchoolWithFilter,
  changeSearchFieldValue,
};
SearchBarContainer.propTypes = {
  value: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  star: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  mapCenter: PropTypes.object.isRequired,
  inputBackground: PropTypes.string.isRequired,
  inputPlaceHolderColor: PropTypes.string.isRequired,
  getSchoolWithFilter: PropTypes.func.isRequired,
  changeSearchFieldValue: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchBarContainer);
