import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import star1 from '../../assets/imgs/rating/1stars.png';
import star2 from '../../assets/imgs/rating/2stars.png';
import star3 from '../../assets/imgs/rating/3stars.png';
import star4 from '../../assets/imgs/rating/4stars.png';
import star5 from '../../assets/imgs/rating/5stars.png';

function Rating(props) {
  const {
    reviewCount,
    average_rating,
    student_rating,
    profession_rating,
    service_rating,
    env_rating,
    language,
  } = props;
  let rateImgURL;
  switch (average_rating) {
    case 1: {
      rateImgURL = star1;
      break;
    }
    case 2: {
      rateImgURL = star2;
      break;
    }
    case 3: {
      rateImgURL = star3;
      break;
    }
    case 4: {
      rateImgURL = star4;
      break;
    }
    case 5: {
      rateImgURL = star5;
      break;
    }
    default: {
      rateImgURL = star1;
    }
  }
  return (
    <div>
      <Wrapper2>
        <RatingImg src={rateImgURL} />
        {
          reviewCount === null ? null : (
            <ReviewCount>
              {reviewCount}
              {' '}
              <FormattedMessage id="reviews" />
            </ReviewCount>
          )
        }
      </Wrapper2>
      <div>
        <Row>
          <FormattedMessage id="rating_overall">
            {
              msg => (
                <Type1>
                  {msg}
                  {' '}
                  <RateNumber>{average_rating}</RateNumber>
                </Type1>
              )
            }
          </FormattedMessage>
          <FormattedMessage id="rating_studentRating">
            {
              msg => (
                <Type2>
                  {msg}
                  {' '}
                  <RateNumber>{student_rating}</RateNumber>
                </Type2>
              )
            }
          </FormattedMessage>
          <FormattedMessage id="rating_professionalism">
            {
              msg => (
                <Type3>
                  {msg}
                  {' '}
                  <RateNumber>{profession_rating}</RateNumber>
                </Type3>
              )
            }
          </FormattedMessage>
        </Row>
        <Row>
          <FormattedMessage id="rating_customerService">
            {
              msg => (
                <Type4>
                  {msg}
                  {' '}
                  <RateNumber>{service_rating}</RateNumber>
                </Type4>
              )
            }
          </FormattedMessage>
          <FormattedMessage id="rating_environmentAndEquipment">
            {
              msg => (
                <Type5 left={language === 'zh-hans' ? '100px' : '150px'}>
                  {msg}
                  {' '}
                  <RateNumber>{env_rating}</RateNumber>
                </Type5>
              )
            }
          </FormattedMessage>
        </Row>
      </div>
    </div>
  );
}
/* eslint-enable */
const Wrapper2 = styled.div`
  margin-bottom: 10px;
  height: 24px;
  display: flex;
`;
const Type1 = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: bold;
`;
const Type2 = styled.div`
  margin-left: 30px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
`;
const Type3 = styled.div`
  margin-left: 30px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
`;
const Type4 = styled.div`
  left: 5px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
`;
const Type5 = styled.div`
  margin-left: 30px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
`;
const Row = styled.div`
  display: flex;
  margin-bottom: 7px;
  line-height: 16px;
`;
const RateNumber = styled.span`
  color: ${props => props.theme.palette.background.primary}
`;
const RatingImg = styled.div`
  width: 100px;
  height: 24px;
  background-image: ${props => `url("${props.src}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
const ReviewCount = styled.div`
  margin-left: 50px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
`;

Rating.propTypes = {
  reviewCount: PropTypes.number,
  average_rating: PropTypes.number.isRequired,
  student_rating: PropTypes.number.isRequired,
  profession_rating: PropTypes.number.isRequired,
  service_rating: PropTypes.number.isRequired,
  env_rating: PropTypes.number.isRequired,
};
Rating.defaultProps = {
  reviewCount: null,
};
const mapStateToProps = state => ({
  language: state.language.language,
});

export default connect(mapStateToProps, null)(Rating);
