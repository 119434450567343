import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import artImg from './imgs/art.png';
import academicImg from './imgs/academic.png';
import moreImg from './imgs/more.png';
import sportsImg from './imgs/sports.png';
import privateSchoolImg from './imgs/school.png';
import academicIcon from './imgs/icons/academic.png';
import artIcon from './imgs/icons/art.png';
import sportsIcon from './imgs/icons/sports.png';
import privateSchoolIcon from './imgs/icons/privateSchool.png';
import CategoryCard from '../../../components/CategoryCard';
import Icon from '../../../components/Icon';
import { changeCategoryFilter } from '../../../actions/filter';
import { jumpTo } from '../../../utils/route';

const styles = theme => ({
  typography: {
    color: theme.palette.primary.main,
    fontFamily: theme.fontFamily.primary,
    fontWeight: 400,
  },
});
class Category extends React.Component {

  onCategoryClicked = (e) => {
    const { changeCategoryFilter, history, location } = this.props;
    changeCategoryFilter(e.currentTarget.dataset.name);
    jumpTo('/result-list', history, location);
  };

  onMoreClickedHandler = () => {
    const { history, location } = this.props;
    jumpTo('/search', history, location);
  };

  render() {
    const { classes } = this.props;
    return (
      <Wrapper>
        <CategoryCard backgroundImg={academicImg} onClick={this.onCategoryClicked} name="Academy">
          <Wrapper1>
            <Icon backgroundImg={academicIcon} />
            <Typography variant="h6" align="center" className={classes.typography}>
              <FormattedMessage id="academy" />
            </Typography>
          </Wrapper1>
        </CategoryCard>
        <CategoryCard backgroundImg={artImg} onClick={this.onCategoryClicked} name="Art">
          <Wrapper1>
            <Icon backgroundImg={artIcon} />
            <Typography variant="h6" align="center" className={classes.typography}>
              <FormattedMessage id="art" />
            </Typography>
          </Wrapper1>
        </CategoryCard>
        <CategoryCard backgroundImg={sportsImg} onClick={this.onCategoryClicked} name="Sport">
          <Wrapper1>
            <Icon backgroundImg={sportsIcon} />
            <Typography variant="h6" align="center" className={classes.typography}>
              <FormattedMessage id="sport" />
            </Typography>
          </Wrapper1>
        </CategoryCard>
        <CategoryCard backgroundImg={privateSchoolImg} onClick={this.onCategoryClicked} name="Private School">
          <Wrapper1>
            <Icon backgroundImg={privateSchoolIcon} />
            <Typography variant="h6" align="center" className={classes.typography}>
              <FormattedMessage id="private_school" />
            </Typography>
          </Wrapper1>
        </CategoryCard>
        <More onClick={this.onMoreClickedHandler}>
          <Wrapper1>
            <MoreIcon>
              <p style={{ textAlign: 'center' }}>。。。</p>
            </MoreIcon>
            <Typography variant="h6" align="center" className={classes.typography}>
              <FormattedMessage id="more" />
            </Typography>
          </Wrapper1>
        </More>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
    width: 100%;
    height: 283px;
    display: flex;
`;
const Wrapper1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const More = styled.div`
    flex-grow: 1;
    background-image: url(\"${moreImg}\");
    background-position: center center;
    background-size: cover
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
      cursor: pointer;
    }
`;
const MoreIcon = styled.div`
    width: 80px;
    height: 80px;
    margin-bottom: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.palette.primary.main};
    font-size: 20px;
`;
Category.propTypes = {
  classes: PropTypes.object.isRequired,
  changeCategoryFilter: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
const mapDispatchToProps = {
  changeCategoryFilter,
};
export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(Category)));
