import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

function VipPromotion() {
  return (
    <Wrapper>
      <Tag1>
        <Wrapper1>
          <Title1><FormattedMessage id="vip_promotion_free_member_benefits" /></Title1>
          <List>
            <Text><FormattedMessage id="vip_promotion_free_member_benefits_line1" /></Text>
            <Text><FormattedMessage id="vip_promotion_free_member_benefits_line2" /></Text>
            <Text><FormattedMessage id="vip_promotion_free_member_benefits_line3" /></Text>
            <Text><FormattedMessage id="vip_promotion_free_member_benefits_line4" /></Text>
            <Text><FormattedMessage id="vip_promotion_free_member_benefits_line5" /></Text>
          </List>
        </Wrapper1>
      </Tag1>
      <Tag2>
        <Wrapper1>
          <Title2><FormattedMessage id="vip_promotion_vip_benefits" /></Title2>
          <List>
            <Text><FormattedMessage id="vip_promotion_vip_benefits_line1" /></Text>
            <Text><FormattedMessage id="vip_promotion_vip_benefits_line2" /></Text>
            <Text><FormattedMessage id="vip_promotion_vip_benefits_line3" /></Text>
            <Text><FormattedMessage id="vip_promotion_vip_benefits_line4" /></Text>
          </List>
          <HighLightText><FormattedMessage id="vip_promotion_contact_us_1" /></HighLightText>
          <HighLightText><FormattedMessage id="vip_promotion_contact_us_2" /></HighLightText>
        </Wrapper1>
      </Tag2>
    </Wrapper>
  );
}
const Title1 = styled.div`
  line-height: 35px;
  margin-bottom: 15px;
`;
const Title2 = styled.div`
  line-height: 35px;
  margin-bottom: 15px;
`;
const Wrapper = styled.div`
     width: 379px;
     background-color: ${props => props.theme.palette.background.blue};
`;
const Wrapper1 = styled.div`
    margin-top: 76px;
    width: 294px;
    position: relative;
    margin-left: 67px;
    z-index: 2;
`;
const Tag1 = styled.div`
    background : ${props => props.theme.palette.background.secondary};
    width: 300px;
    height: 35px;
    z-index:1;
    position: relative;

`;
const Tag2 = styled.div`
    background : ${props => props.theme.palette.background.secondary};
    width: 300px;
    height: 35px;
    z-index: 1;
    position: relative;
    top: 230px;
`;
const Text = styled.li`
    font-size: 14px;
    width: 242px;
    margin-bottom: 11px;
`;
const HighLightText = styled.div`
    font-size: 16px;
    font-family: ${props => props.theme.fontFamily.primary};
    font-weight: 400;
    margin-bottom: 11px;
    color: #00A3E4;
    width: 242px;
`;
const List = styled.ul`
    padding: 0px;
    list-style-position: outside;
`;
export default VipPromotion;
