import { SET_MAP_CENTER, SET_MAP_LOADING } from '../actions/actionTypes';
import {toronto} from "../components/Map/locations";

const initialState = {
  mapCenter: toronto,
  isLoading: false,
};
const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_CENTER: {
      return {
        mapCenter: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
        isLoading: false,
      };
    }
    case SET_MAP_LOADING: {
      return {...state, isLoading: true};
    }
    default:
      return state;
  }
};

export default mapReducer;
