import React from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import event1 from './imgs/event1.png';
import event2 from './imgs/event2.png';
import { jumpTo } from '../../../utils/route';


const styles = theme => ({
  title: {
    fontSize: '1.75rem',
    marginBottom: '20px',
    marginTop: '50px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '400',
  },
});
function Events(props) {
  const { classes, history, location } = props;
  return (
    <Wrapper>
      <Wrapper1>
        <Typography align="center" className={classes.title} color="textSecondary">
          <FormattedMessage id="events_title" />
        </Typography>
        <div id="events" className="carousel slide" data-ride="carousel" data-interval="false">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <Event1 onClick={() => jumpTo('/event', history, location)}>
                <Img src={event1} alt="event1" />
              </Event1>
            </div>
            <div className="carousel-item">
              <Event1 onClick={() => jumpTo('/event', history, location)}>
                <Img src={event2} alt="event2" />
              </Event1>
            </div>
          </div>
          <a className="carousel-control-prev" href="#events" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#events" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>
      </Wrapper1>
    </Wrapper>
  );
}
const Img = styled.img`
  width: 100%;
`;
const Wrapper = styled.div`
    background-color: ${props => props.theme.palette.background.secondary};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper1 = styled.div`
    text-align: center;
    color: ${props => props.theme.palette.primary.main};
    width: 1202px;
`;

const Event1 = styled.div`
    width: 100%;
`;
const Event2 = styled.div`
    width: 100%;
`;

Events.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
export default withStyles(styles)(Events);
