import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CategoryCard from '../../../components/CategoryCard';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Filter from '../../../components/Filter/Filter';
import background from './imgs/background.png';
import academic from './imgs/academic.png';
import art from './imgs/art.png';
import sports from './imgs/sports.png';
import privateSchool from './imgs/privateSchool.png';
import music from './imgs/music.png';
import general from './imgs/general.png';
import summerCamp from './imgs/summerCamp.png';
import academicIcon from '../../../assets/icons/academic.png';
import artIcon from '../../../assets/icons/art.png';
import sportsIcon from '../../../assets/icons/sports.png';
import privateSchoolIcon from '../../../assets/icons/privateSchool.png';
import musicIcon from '../../../assets/icons/music.png';
import generalIcon from '../../../assets/icons/general.png';
import summerCampIcon from '../../../assets/icons/summerCamp.png';
import Icon from '../../../components/Icon';
import { changeDistanceFilter, changeCategoryFilter, changeStarFilter } from '../../../actions/filter';
import { options1, options2, options3 } from '../../../components/Filter/options';
import { jumpTo } from '../../../utils/route';
import {changeSearchFieldValue} from "../../../actions/searchBar";

// MUI styles
const styles = theme => ({
  typography: {
    color: theme.palette.primary.main,
  },
});
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.onSearchHandler = this.onSearchHandler.bind(this);
    this.onCategoryClicked = this.onCategoryClicked.bind(this);
  }

  onInputChange = (e) => {
    const { changeSearchFieldValue } = this.props;
    changeSearchFieldValue(e.target.value);
  };

  onCategoryClicked(e) {
    const { onCategoryClicked, history, location } = this.props;
    onCategoryClicked(e.currentTarget.dataset.name);
    jumpTo('/result-list', history, location);
  }

  onSearchHandler() {
    const {
      history,
      location,
    } = this.props;
    jumpTo('/result-list', history, location);
  }

  render() {
    const {
      classes,
      distance,
      category,
      star,
      changeDistance,
      changeStar,
      changeCategoryFilter,
      value,
    } = this.props;
    return (
      <Wrapper>
        <Wrapper1 backgroundImg={background}>
          <Wrapper3>
            <SearchBar
              onInputChange={this.onInputChange}
              onSearchHandler={this.onSearchHandler}
              inputBackground="#FFF"
              inputPlaceHolderColor="#DCDCDC"
              value={value}
            />
            <Filters>
              <Filter options={options1} onChange={changeDistance} value={distance} defaultText="distance" />
              <Filter options={options2} onChange={changeStar} value={star} defaultText="rating" />
              <Filter options={options3} onChange={changeCategoryFilter} value={category} width="175px" defaultText="category" />
            </Filters>
          </Wrapper3>
        </Wrapper1>
        <Wrapper2 />
        <CategoryWrapper>
          <CategoryCard backgroundImg={academic} onClick={this.onCategoryClicked} name="Academy">
            <Wrapper4>
              <Icon backgroundImg={academicIcon} width="40px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="academy" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
          <CategoryCard backgroundImg={art} onClick={this.onCategoryClicked} name="Art">
            <Wrapper4>
              <Icon backgroundImg={artIcon} width="40px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="art" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
          <CategoryCard backgroundImg={sports} onClick={this.onCategoryClicked} name="Sport">
            <Wrapper4>
              <Icon backgroundImg={sportsIcon} width="40px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="sport" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
          <CategoryCard backgroundImg={privateSchool} onClick={this.onCategoryClicked} name="Private School">
            <Wrapper4>
              <Icon backgroundImg={privateSchoolIcon} width="40px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="private_school" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
          <CategoryCard backgroundImg={music} onClick={this.onCategoryClicked} name="Music">
            <Wrapper4>
              <Icon backgroundImg={musicIcon} width="60px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="music" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
          <CategoryCard backgroundImg={general} onClick={this.onCategoryClicked} name="Integrated School">
            <Wrapper4>
              <Icon backgroundImg={generalIcon} width="40px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="integrated_school" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
          <CategoryCard backgroundImg={summerCamp} onClick={this.onCategoryClicked} name="Summer Camp">
            <Wrapper4>
              <Icon backgroundImg={summerCampIcon} width="40px" height="40px" marginBottom="15px" />
              <Typography variant="h6" align="center" className={classes.typography}>
                <FormattedMessage id="summer_camp" />
              </Typography>
            </Wrapper4>
          </CategoryCard>
        </CategoryWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
const Wrapper1 = styled.div`
  width: 100%;
  height: 450px;
  background-image: ${props => `url("${props.backgroundImg}")`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;
const Wrapper2 = styled.div`
  width: 100%
  height: 104px;
`;
const Wrapper3 = styled.div`
  width: 90%;
  top: 200px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;
const Filters = styled.div`
  width: 50%;
  margin: 0 auto;
`;
const CategoryWrapper = styled.div`
  position: absolute;
  width: 95%;
  height: 150px;
  top: 372px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper4 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
Search.propTypes = {
  classes: PropTypes.object.isRequired,
  distance: PropTypes.string.isRequired,
  star: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  changeDistance: PropTypes.func.isRequired,
  changeStar: PropTypes.func.isRequired,
  changeCategoryFilter: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onCategoryClicked: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  distance: state.filter.distance,
  star: state.filter.star,
  category: state.filter.category,
  value: state.searchBar.input,
});
const mapDispatchToProps = dispatch => ({
  changeDistance: e => dispatch(changeDistanceFilter(e.target.value)),
  changeStar: e => dispatch(changeStarFilter(e.target.value)),
  changeCategoryFilter: e => dispatch(changeCategoryFilter(e.target.value)),
  onCategoryClicked: value => dispatch(changeCategoryFilter(value)),
  changeSearchFieldValue: value => dispatch(changeSearchFieldValue(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Search))); //eslint-disable-line
