import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ResetPasswordComponent from './ResetPassword';
import axios from '../../axios';
import { openDialog } from '../../actions/ui/dialog';
import { jumpTo } from '../../utils/route';

class ResetPasswordPagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      isLoading: false,
    };
    this.onPasswordChangeHandler = this.onPasswordChangeHandler.bind(this);
    this.onResetPasswordHandler = this.onResetPasswordHandler.bind(this);
  }

  onPasswordChangeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onResetPasswordHandler() {
    const { password1, password2 } = this.state;
    const {
      history,
      location,
      match,
      openDialog,
      intl: { formatMessage },
    } = this.props;
    const { key } = match.params;
    this.setState({
      isLoading: true,
    });
    // send email to server
    axios.post(`/account/password/reset/key/${key}/`, {
      password1,
      password2,
    })
      .then(() => {
        jumpTo('/', history, location);
        openDialog(formatMessage({ id: 'dialog_success_title' }), formatMessage({ id: 'reset_password_success' }));
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'reset_password_fail' }));
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        <div>
          <ResetPasswordComponent
            isLoading={isLoading}
            onEmailChangeHandler={this.onEmailChangeHandler}
            onPasswordChangeHandler={this.onPasswordChangeHandler}
            onResetPasswordHandler={this.onResetPasswordHandler}
          />
        </div>
      </React.Fragment>
    );
  }
}
ResetPasswordPagePage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  openDialog: (title, message) => dispatch(openDialog(title, message)),
});
export default connect(null, mapDispatchToProps)(withRouter(injectIntl(ResetPasswordPagePage)));
