import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Map from '../../../components/Map/Map';
import { getSchoolWithFilter } from '../../../actions/school';
import { setMapCenter } from '../../../actions/map';

class MapContainer extends Component {
  state = {
    searchWhenMapMove: true,
  };

  toggleSearchWhenMapMoveHandler = () => {
    const { searchWhenMapMove } = this.state;
    this.setState({
      searchWhenMapMove: !searchWhenMapMove,
    });
  };

   onMapChangeHandler = ({ center }) => {
     const { searchWhenMapMove } = this.state;
     const {
       setMapCenter,
       distance,
       star,
       category,
       value,
       getSchoolWithFilter,
     } = this.props;
     const filter = {
       distance,
       star,
       category,
       input: value,
     };
     // Change userLocation if this.state.searchWhenMapMove === true
     setMapCenter(center);
     if (searchWhenMapMove === true) {
       getSchoolWithFilter(center, filter);
     }
   };

   render() {
     const { showInfoIndex, onSchoolClickedHandler } = this.props;
     return (
       <Map
         showInfoIndex={showInfoIndex}
         toggleSearchWhenMapMoveHandler={this.toggleSearchWhenMapMoveHandler}
         onMapChangeHandler={this.onMapChangeHandler}
         onSchoolClickedHandler={onSchoolClickedHandler}
       />
     );
   }
}

const mapStateToProps = state => ({
  value: state.searchBar.input,
  distance: state.filter.distance,
  star: state.filter.star,
  category: state.filter.category,
});
const mapDispatchToProps = {
  getSchoolWithFilter,
  setMapCenter,
};
MapContainer.propTypes = {
  value: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  star: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  showInfoIndex: PropTypes.number.isRequired,
  getSchoolWithFilter: PropTypes.func.isRequired,
  setMapCenter: PropTypes.func.isRequired,
  onSchoolClickedHandler: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
