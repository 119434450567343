import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { jumpTo } from '../../../utils/route';

class School extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSchoolInfoClickedHandler = this.onSchoolInfoClickedHandler.bind(this);
  }

  onSchoolInfoClickedHandler(slug) {
    const { history, location } = this.props;
    jumpTo(`/school-detail/${slug}`, history, location);
  }

  render() {
    const {
      name,
      ratingCount,
      icon,
      rateImgURL,
      tags,
      shouldShow,
      onSchoolClickedHandler,
      index,
      slug,
      logo,
    } = this.props;
    /*eslint-disable */
    return (
      <section>
        <Icon icon={icon} onClick={() => onSchoolClickedHandler(index)} />
        {
          shouldShow
            ? (
              <SchoolInfo onClick={()=> this.onSchoolInfoClickedHandler(slug)}>
                <img height="65px" width="65px" src={logo} alt="schoolImg" />
                <div style={{ marginLeft: '5px' }}>
                  <SchoolName>{name}</SchoolName>
                  <div style={{ marginBottom: '8px' }}>
                    <img height="11px" width="64px" src={rateImgURL} alt="schoolImg" />
                    <span style={{marginLeft: '5px'}}>({ratingCount})</span>
                  </div>
                  <Tags>{tags}</Tags>
                </div>
              </SchoolInfo>
            ) : null
        }
      </section>
    );
    /* eslint-enable */
  }
}
const Tags = styled.div`
  font-family:  ${props => props.theme.fontFamily.primary};
  font-weight: 100;
`;
const SchoolName = styled.div`
  font-weight: 400;
  margin-bottom: 3px;
  font-family: ${props => props.theme.fontFamily.primary};
  fontWeight: 200;
`;
const Icon = styled.div`
  background-image: ${props => `url("${props.icon}")`};
  background-size: cover;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  :hover{
    cursor: pointer 
  }
`;
const SchoolInfo = styled.div`
  cursor: default;
  display: flex;
  background-color: white;
  width: 258px;
  padding: 8px;
  z-index: 1000;
  position: relative;
`;
School.propTypes = {
  name: PropTypes.string.isRequired,
  ratingCount: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  rateImgURL: PropTypes.string.isRequired,
  tags: PropTypes.any.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  onSchoolClickedHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  logo: PropTypes.string,
};
School.defaultProps = {
  logo: null,
};
export default withRouter(withTheme(School));
