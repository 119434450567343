import React, {useState} from 'react';
import styled, { withTheme } from 'styled-components';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VipPromotion from '../VipPromotion/VipPromotion';
import title from './imgs/freeMemberSignUp.png';
import background from './imgs/background.png';
import Spinner from '../../../components/Spinner/Spinner';
import signUpTranslation from './signUpTranslation';

const styles = theme => ({
  button: {
    width: '100%',
    height: '62px',
    backgroundColor: theme.palette.background.secondary,
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: '20px 0',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
    },
  },
  languagePrefer: {
    color: theme.palette.text.secondary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
  moreChildren: {
    color: '#3AC7FF',
    textTransform: 'none',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
  childrenInfo: {
    display: 'flex',
    color: theme.palette.text.secondary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '200',
    marginBottom: '14px',
  },
  familyIncome: {
    color: theme.palette.text.secondary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
  childrenInformation: {
    color: theme.palette.text.secondary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
});
function SignUp(props) {
  const {
    classes,
    onInputChangeHandler,
    onAddChildrenHandler,
    onFamilyIncomeChangeHandler,
    onLanguageChangeHandler,
    onSignUpHandler,
    onRemoveChildrenHandler,
    childrenInfos,
    isLoading,
    errors,
    intl,
    prefLanguage,
  } = props;
  const [isShowMore, setShowMore] = useState(false)
  let emailError = '';
  let password1Error = '';
  let password2Error = '';
  let userNameError = '';
  let relationshipError = '';
  let residentialError = '';
  let postalCodeError = '';
  errors.forEach((error) => {
    switch (error.field) {
      case 'email':
      {
        emailError = error.message;
        return;
      }
      case 'password1':
      {
        password1Error = error.message;
        return;
      }
      case 'password2':
      {
        password2Error = error.message;
        return;
      }
      case 'residential_city':
      {
        residentialError = error.message;
        break;
      }
      case 'first_name':
      {
        userNameError = error.message;
        break;
      }
      case 'postal_code':
      {
        postalCodeError = error.message;
        break;
      }
      case 'relationship':
      {
        relationshipError = error.message;
        break;
      }
      default:
    }
  });
  const spinner = isLoading ? <Spinner /> : null;
  const _renderAdditionalFields = () => {
    return(
      <>
        <FormattedMessage id="signUp_residence">
          {
            placeholder => (
              <Input
                placeholder={placeholder}
                onChange={onInputChangeHandler}
                name="residence"
              />
            )
          }
        </FormattedMessage>
        <ErrorMessage>{residentialError}</ErrorMessage>
        <FormattedMessage id="signUp_relation">
          {
            placeholder => (
              <Input
                placeholder={placeholder}
                onChange={onInputChangeHandler}
                name="relation"
              />
            )
          }
        </FormattedMessage>
        <ErrorMessage>{relationshipError}</ErrorMessage>
        <FormattedMessage id="signUp_zipCode">
          {
            placeholder => (
              <Input
                placeholder={placeholder}
                onChange={onInputChangeHandler}
                name="zipCode"
              />
            )
          }
        </FormattedMessage>
        <ErrorMessage>{postalCodeError}</ErrorMessage>
        <InfoWrapper>
          <Wrapper3>
            <Wrapper5>
              <Typography className={classes.childrenInformation}>
                <FormattedMessage id="signUp_childrenInformation" />
              </Typography>
            </Wrapper5>
            <Wrapper4>
              {
                childrenInfos
              }
            </Wrapper4>
          </Wrapper3>
          <ChildrenButton>
            {
              childrenInfos.length < 4
                ? (
                  <Button className={classes.moreChildren} onClick={onAddChildrenHandler}>
                    <FormattedMessage id="signUp_moreChildren" />
                  </Button>
                ) : null
            }
            {
              childrenInfos.length > 1
                ? (
                  <Button className={classes.moreChildren} onClick={onRemoveChildrenHandler}>
                    <FormattedMessage id="signUp_removeChildren" />
                  </Button>
                ) : null
            }
          </ChildrenButton>
          <div style={{ display: 'inline-block' }}>
            <Typography className={classes.familyIncome}>
              <FormattedMessage id="signUp_familyIncome" />
              <Select defaultValue="" onChange={onFamilyIncomeChangeHandler}>
                <FormattedMessage id="option_please_select">
                  {msg => <option value="" disabled hidden>{msg}</option>}
                </FormattedMessage>
                <option value="0k_5k">0k~5k</option>
                <option value="5k_10k">5k~10k</option>
                <option value="10k+">10k+</option>
              </Select>
            </Typography>
          </div>
          <div style={{ display: 'inline-block', marginLeft: '5px' }}>
            <Typography className={classes.languagePrefer}>
              <FormattedMessage id="signUp_languagePrefer" />
              <Select value={prefLanguage} onChange={onLanguageChangeHandler}>
                <FormattedMessage id="option_please_select">
                  {msg => <option value="" disabled hidden>{msg}</option>}
                </FormattedMessage>
                <option value="CHN">{intl.formatMessage(signUpTranslation.chinese)}</option>
                <option value="ENG">{intl.formatMessage(signUpTranslation.english)}</option>
              </Select>
            </Typography>
          </div>
        </InfoWrapper>
        </>
    )
  }
  return (
    <Wrapper>
      {
        spinner
      }
      <Wrapper1>
        <VipPromotion />
        <WrapperRight>
          <Wrapper2>
            <TitleWrapper>
              <Title />
            </TitleWrapper>
            <InputGroup>
              <FormattedMessage id="signUp_userName">
                {
                  placeholder => (
                    <Input
                      placeholder={placeholder}
                      onChange={onInputChangeHandler}
                      name="userName"
                    />
                  )
                }
              </FormattedMessage>
              <ErrorMessage>{userNameError}</ErrorMessage>
              <FormattedMessage id="signUp_email">
                {
                  placeholder => <Input placeholder={placeholder} onChange={onInputChangeHandler} name="email" />
                }
              </FormattedMessage>
              <ErrorMessage>{emailError}</ErrorMessage>
              <FormattedMessage id="signUp_password">
                {
                  placeholder => (
                    <Input
                      type="password"
                      placeholder={placeholder}
                      onChange={onInputChangeHandler}
                      name="password"
                    />
                  )
                }
              </FormattedMessage>
              <ErrorMessage>{password1Error}</ErrorMessage>
              {
                isShowMore?
                  _renderAdditionalFields(): null
              }
              <div>
              <Button className={classes.moreChildren} onClick={()=>setShowMore(!isShowMore)}>
                <FormattedMessage id={`${isShowMore? 'signUp_lessInfoBtn' : 'signUp_moreInfoBtn'}`} />
              </Button>
              </div>
            </InputGroup>

            <Button onClick={onSignUpHandler} className={classes.button}>
              <FormattedMessage id="signUp_signInNow" />
            </Button>
          </Wrapper2>
        </WrapperRight>
      </Wrapper1>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    width: 100%;
    padding: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
`;

const Wrapper1 = styled.div`
    width: 999px;
    display: flex;
    min-height: 800px;
`;

const WrapperRight = styled.div`
    width: 620px;
    background-color: ${props => props.theme.palette.background.white};
`;

const Wrapper2 = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-top : 65px;
`;

const Wrapper3 = styled.div`
    display: flex;

`;
const Wrapper4 = styled.div`
    
`;
const Wrapper5 = styled.div`
    display: flex;
    align-items: center;
`;
const TitleWrapper = styled.div`
    height: 69px;
    width: 209px;
    margin: 0 auto;
`;
const Title = styled.div`
    width: 209px;
    height: 69px;
    background-image: url(\'${title}\');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;
const InputGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 27px 0 23px 0;
`;
const Input = styled.input`
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px
    font-family: ${props => props.theme.fontFamily.primary}
    font-weight: 100;
`;
const InfoWrapper = styled.div`
    width: 100%;
`;
const Select = styled.select`
    height: 48px;
    padding: 5px;
    background-color: white;
`;
const ErrorMessage = styled.span`
    color: ${props => props.theme.palette.text.error}
`;
const ChildrenButton = styled.div`
   margin: 10px 0;
`;
SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  onInputChangeHandler: PropTypes.func.isRequired,
  onAddChildrenHandler: PropTypes.func.isRequired,
  onRemoveChildrenHandler: PropTypes.func.isRequired,
  onFamilyIncomeChangeHandler: PropTypes.func.isRequired,
  onSignUpHandler: PropTypes.func.isRequired,
  onLanguageChangeHandler: PropTypes.func.isRequired,
  childrenInfos: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  prefLanguage: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  language: state.language.language,
});
export default connect(mapStateToProps, null)(withTheme(withStyles(styles)(injectIntl(SignUp))));
