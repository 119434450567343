import { CHANGE_DISTANCE_FILTER, CHANGE_STAR_FILTER, CHANGE_CATEGORY_FILTER } from '../actions/actionTypes';

const initialState = {
  star: '',
  distance: '',
  category: '',
};
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DISTANCE_FILTER: {
      return {
        ...state,
        distance: action.payload,
      };
    }
    case CHANGE_STAR_FILTER: {
      return {
        ...state,
        star: action.payload,
      };
    }
    case CHANGE_CATEGORY_FILTER: {
      return {
        ...state,
        category: action.payload,
      };
    }

    default:
      return state;
  }
};

export default filterReducer;
