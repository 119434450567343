// GoogleMap Controller Setting
function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    zoomControl: true,
    scaleControl: true,
    zoomControlOptions: {
      // position zoom controller to top left corner of the map
      position: maps.ControlPosition.TOP_LEFT,
    },
    clickableIcons: false, // disable all labels icons except your custom infowindow or Infobox
  };
}

export default createMapOptions;
