import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import background from './imgs/kids-3448011_1920.png';
import CouponCard from '../../components/CouponCard/CouponCard';
import coupon1Primary from '../../assets/imgs/coupons/1.png';
import coupon2Primary from '../../assets/imgs/coupons/2.jpg';
import coupon3Primary from '../../assets/imgs/coupons/5.PNG';
import coupon3Secondary from '../../assets/imgs/coupons/5.1.png';
import coupon4Primary from '../../assets/imgs/coupons/6.jpg';
import coupon4Secondary from '../../assets/imgs/coupons/6.1.jpeg';
import coupon5Primary from '../../assets/imgs/coupons/3.png';
import coupon6Primary from '../../assets/imgs/coupons/4.jpeg';
import coupon7Primary from '../../assets/imgs/coupons/7.jpg';
import coupon7PrimaryCN from '../../assets/imgs/coupons/7CN.jpg';
import coupon8Primary from '../../assets/imgs/coupons/8.jpg';
import coupon9Primary from '../../assets/imgs/coupons/9.png';
import coupon9PrimaryCN from '../../assets/imgs/coupons/9CN.png';
import coupon10Primary from '../../assets/imgs/coupons/10.jpg';
import coupon10PrimaryCN from '../../assets/imgs/coupons/10CN.jpg';
import defaultBg from './imgs/bigCoupon.png';
import coupon1Exposed from '../../assets/imgs/coupons/summerCamps/artnection-ex.jpg';
import coupon1Covered from '../../assets/imgs/coupons/summerCamps/artnection-cover.jpg';
import coupon2Exposed from '../../assets/imgs/coupons/summerCamps/dantitude-ex.jpg';
import coupon2Covered from '../../assets/imgs/coupons/summerCamps/dantitude-cover.jpg';
import coupon3Exposed from '../../assets/imgs/coupons/summerCamps/gobeyond-ex.jpg';
import coupon3Covered from '../../assets/imgs/coupons/summerCamps/gobeyond-cover.jpg';
import coupon4Exposed from '../../assets/imgs/coupons/summerCamps/infinitistring-ex.jpg';
import coupon4Covered from '../../assets/imgs/coupons/summerCamps/infinitistring-cover.jpg';
import coupon5Exposed from '../../assets/imgs/coupons/summerCamps/novelpanda-ex.jpg';
import coupon5Covered from '../../assets/imgs/coupons/summerCamps/novelpanda-cover.jpg';
import coupon6Exposed from '../../assets/imgs/coupons/summerCamps/focus-learning-ex.jpg';
import coupon6Covered from '../../assets/imgs/coupons/summerCamps/focus-learning-cover.jpg';

import coupon7Exposed from '../../assets/imgs/coupons/Academy/http_www.focus-learning.ca-ex.jpg';
import coupon7Covered from '../../assets/imgs/coupons/Academy/Focus learning_Academy_coupon.jpg';
import coupon8Exposed from '../../assets/imgs/coupons/Academy/http_www.toppath.ca_.jpg';
import coupon8Covered from '../../assets/imgs/coupons/Academy/Toppath_Academy_coupon.jpg';
import coupon9Exposed from '../../assets/imgs/coupons/Academy/http_www.zone01.ca_index.php_en-ca_.jpg';
import coupon9Covered from '../../assets/imgs/coupons/Academy/RobertZone01_Academy_coupon.jpg';
import coupon10Exposed from '../../assets/imgs/coupons/Academy/https_ucmas.ca_.jpg';
import coupon10Covered from '../../assets/imgs/coupons/Academy/UCMAS_Academy_coupon.jpg';
import coupon11Exposed from '../../assets/imgs/coupons/Academy/https_e3toronto.com_.jpg';
import coupon11Covered from '../../assets/imgs/coupons/Academy/E3 Public Speaking_Academy_coupon.jpg';
import coupon12Exposed from '../../assets/imgs/coupons/Academy/https_ileapclub.org_.jpg';
import coupon12Covered from '../../assets/imgs/coupons/Academy/iLEAP Club_Academy_coupon.jpg';
import coupon13Exposed from '../../assets/imgs/coupons/Academy/https_locations.sylvanlearning.com_ca_thornhill-on_.jpg';
import coupon13Covered from '../../assets/imgs/coupons/Academy/Sylvan_Academy_coupon.jpg';
import coupon14Exposed from '../../assets/imgs/coupons/Academy/https_www.athenaeducation.ca_.jpg';
import coupon14Covered from '../../assets/imgs/coupons/Academy/Athena_Academy_coupon.jpg';
import coupon15Exposed from '../../assets/imgs/coupons/Academy/https_www.canadachess.ca_.jpg';
import coupon15Covered from '../../assets/imgs/coupons/Academy/CCYC_Academy_coupon.jpg';
import coupon16Exposed from '../../assets/imgs/coupons/Academy/https_www.inventivekidz.com_.jpg';
import coupon16Covered from '../../assets/imgs/coupons/Academy/Inventive Kids_Academy_coupon.jpg';
import coupon17Exposed from '../../assets/imgs/coupons/Academy/https_www.mathnasium.ca_markham_.jpg';
import coupon17Covered from '../../assets/imgs/coupons/Academy/Mathnasium_Academy_coupon.jpg';
import coupon18Exposed from '../../assets/imgs/coupons/Academy/https_www.wellsacademy.ca_.jpg';
import coupon18Covered from '../../assets/imgs/coupons/Academy/Wells_Academy_coupon.jpg';

import coupon19Exposed from '../../assets/imgs/coupons/artsMusic/http_jinart.ca_.jpg';
import coupon19Covered from '../../assets/imgs/coupons/artsMusic/JIN ART_coupon.jpg';
import coupon20Exposed from '../../assets/imgs/coupons/artsMusic/http_www.artnection.com_.jpg';
import coupon20Covered from '../../assets/imgs/coupons/artsMusic/Artnection_coupon.jpg';
import coupon21Exposed from '../../assets/imgs/coupons/artsMusic/https_www.thesingingschool.net_.jpg';
import coupon21Covered from '../../assets/imgs/coupons/artsMusic/The Singing School_coupon.jpg';
import coupon22Exposed from '../../assets/imgs/coupons/artsMusic/https_www.tonelabsmusic.com_.jpg';
import coupon22Covered from '../../assets/imgs/coupons/artsMusic/Tone Labs Music_coupon.jpg';

import coupon23Exposed from '../../assets/imgs/coupons/Sports/https_championtaekwondomarkham.ca_.jpg';
import coupon23Covered from '../../assets/imgs/coupons/Sports/Champion Taekwondo_coupon.jpg';
import coupon24Exposed from '../../assets/imgs/coupons/Sports/https_fcbcanada.com.jpg';
import coupon24Covered from '../../assets/imgs/coupons/Sports/Barca_coupon.jpg';
import coupon25Exposed from '../../assets/imgs/coupons/Sports/https_www.ignitetaekwondo.ca_.jpg';
import coupon25Covered from '../../assets/imgs/coupons/Sports/Ignite Taekwondo_coupon.jpg';
import coupon26Exposed from '../../assets/imgs/coupons/Sports/https_www.sogolfacademy.ca_.jpg';
import coupon26Covered from '../../assets/imgs/coupons/Sports/So Golf_coupon.jpg';

import './index.css';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';

const SectionHeader = styled.h2`
  color: #05B8FF;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 40px 0;
`;
const CouponWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 45px;
`;
function CouponPage(props) {
  const { language } = props;
  const couponsData = [
    {
      index: 1,
      title: 'Summer Camps',
      data: [
        {
          url: 'http://www.artnection.com',
          covered: coupon1Covered,
          exposed: coupon1Exposed,
        },
        {
          url: 'https://www.dantitude.com',
          covered: coupon2Covered,
          exposed: coupon2Exposed,
        },
        {
          url: 'http://www.gobeyondacademy.com',
          covered: coupon3Covered,
          exposed: coupon3Exposed,
        },
        {
          url: 'https://infinitistrings.com',
          covered: coupon4Covered,
          exposed: coupon4Exposed,
        },
        {
          url: 'https://www.novelpanda.com',
          covered: coupon5Covered,
          exposed: coupon5Exposed,
        },
        {
          url: 'http://www.focus-learning.ca',
          covered: coupon6Covered,
          exposed: coupon6Exposed,
        },
      ],
    },
    {
      index: 2,
      title: 'Academics',
      data: [
        {
          url: 'http://www.focus-learning.ca',
          covered: coupon7Covered,
          exposed: coupon7Exposed,
        },
        {
          url: 'http://www.toppath.ca',
          covered: coupon8Covered,
          exposed: coupon8Exposed,
        },
        {
          url: 'http://www.zone01.ca/index.php/en-ca',
          covered: coupon9Covered,
          exposed: coupon9Exposed,
        },
        {
          url: 'https://ucmas.ca',
          covered: coupon10Covered,
          exposed: coupon10Exposed,
        },
        {
          url: 'https://e3toronto.com',
          covered: coupon11Covered,
          exposed: coupon11Exposed,
        },
        {
          url: 'https://ileapclub.org',
          covered: coupon12Covered,
          exposed: coupon12Exposed,
        },
        {
          url: 'https://locations.sylvanlearning.com/ca/thornhill-on',
          covered: coupon13Covered,
          exposed: coupon13Exposed,
        },
        {
          url: 'https://www.athenaeducation.ca/',
          covered: coupon14Covered,
          exposed: coupon14Exposed,
        },
        {
          url: 'https://www.canadachess.ca/',
          covered: coupon15Covered,
          exposed: coupon15Exposed,
        },
        {
          url: 'https://www.inventivekidz.com/',
          covered: coupon16Covered,
          exposed: coupon16Exposed,
        },
        {
          url: 'https://www.mathnasium.ca/markham/',
          covered: coupon17Covered,
          exposed: coupon17Exposed,
        },
        {
          url: 'http://www.wellsacademy.ca/',
          covered: coupon18Covered,
          exposed: coupon18Exposed,
        },
      ],
    },
    {
      index: 3,
      title: 'Arts&Music',
      data: [
        {
          url: 'http://jinart.ca/',
          covered: coupon19Covered,
          exposed: coupon19Exposed,
        },
        {
          url: 'http://www.artnection.com',
          covered: coupon20Covered,
          exposed: coupon20Exposed,
        },
        {
          url: 'https://www.thesingingschool.net/',
          covered: coupon21Covered,
          exposed: coupon21Exposed,
        },
        {
          url: 'https://www.tonelabsmusic.com',
          covered: coupon22Covered,
          exposed: coupon22Exposed,
        },
      ],
    },
    {
      index: 4,
      title: 'Sports',
      data: [
        {
          url: 'https://championtaekwondomarkham.ca/',
          covered: coupon23Covered,
          exposed: coupon23Exposed,
        },
        {
          url: 'https://fcbcanada.com',
          covered: coupon24Covered,
          exposed: coupon24Exposed,
        },
        {
          url: 'https://www.ignitetaekwondo.ca/',
          covered: coupon25Covered,
          exposed: coupon25Exposed,
        },
        {
          url: 'https://www.sogolfacademy.ca/',
          covered: coupon26Covered,
          exposed: coupon26Exposed,
        },
      ],
    },
    {
      index: 5,
      title: 'Others',
      data: [
        {
          url: 'https://infinitistrings.com/',
          covered: coupon5Primary,
          exposed: coupon5Primary,
        },
        {
          url: 'https://www.scholarscanada.com',
          covered: coupon8Primary,
          exposed: coupon8Primary,
        },
        {
          url: 'https://trochi.com/',
          covered: coupon4Primary,
          exposed: coupon4Secondary,
        },
        {
          url: 'https://ucmas.ca',
          covered: coupon7Primary,
          exposed: coupon7Primary,
        },
        {
          url: 'https://www.herskickboxing.com',
          covered: coupon9Primary,
          exposed: coupon9Primary,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Banner>
        <Title><FormattedMessage id="couponPage_coupon" /></Title>
      </Banner>
      <div style={{ marginBottom: '80px' }}>
        {couponsData.map(item => (
          <Wrapper>
            <SectionHeader>{item.title}</SectionHeader>
            <CouponWrapper>
              {item.data.map(item => (
                <CouponCard
                  primary={item.covered}
                  secondary={item.exposed}
                  url={item.url}
                />
              ))}
            </CouponWrapper>
          </Wrapper>
        ))}
      </div>
    </React.Fragment>
  );
}
const Banner = styled.div`
  height: 431px;
  background-image: url("${background}");
  background-size: cover;
  background-position: center;
  position: relative;
`;
const Title = styled.div`
  position: absolute;
  bottom: 148px;
  text-align: center;
  width: 100%;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
  color: ${props => props.theme.palette.textPrimary.main};
  font-size: 50px;
  line-height: 67px;
  height: 67px;
`;
const Wrapper = styled.div`
  width: 1147px;
  margin: 0 auto;
`;
CouponPage.propTypes = {
  language: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  language: state.language.language,
});
export default connect(mapStateToProps, null)(withTheme(CouponPage));
