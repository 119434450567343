import store from "../../store";

function createSearchController(controlDiv, map, toggleSearchWhenMapMoveHandler) {
  // Set CSS for the control border.
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginBottom = '22px';
  controlUI.style.textAlign = 'center';
  controlUI.title = 'Click to recenter the map';
  controlUI.style.display = 'flex';
  controlUI.style.justifyContent = 'center';
  controlUI.style.alignItems = 'center';
  controlUI.style.padding = '0 15px';
  controlDiv.appendChild(controlUI);
  // Set check box
  const checkBox = document.createElement('input');
  checkBox.type = 'checkbox';
  checkBox.defaultChecked = true;
  checkBox.addEventListener('change', toggleSearchWhenMapMoveHandler);
  controlUI.appendChild(checkBox);
  // Set CSS for the control interior.
  const controlText = document.createElement('div');
  controlText.style.color = 'rgb(25,25,25)';
  controlText.style.fontSize = '16px';
  controlText.style.lineHeight = '38px';
  controlText.style.paddingLeft = '5px';
  controlText.style.paddingRight = '5px';
  const language = store.getState().language.language;
  controlText.innerHTML = language === 'zh-hans'?'移动地图时搜索':'Search When I move the map';
  controlText.style.display = 'inline';
  controlText.style.fontFamily = 'PingFang-SC-Medium';
  controlUI.appendChild(controlText);
}

export default createSearchController;
