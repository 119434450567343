import { CHANGE_SEARCH_FIELD_VALUE } from '../actions/actionTypes';

const initialState = {
  input: '',
};
const searchBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SEARCH_FIELD_VALUE: {
      return {
        input: action.payload,
      };
    }
    default:
      return state;
  }
};

export default searchBarReducer;
