import Loader from 'react-loader-spinner';
import React from 'react';
import styled from 'styled-components';

function Spinner() {
  return (
    <Wrapper>
      <Loader type="ThreeDots" color="#fff" height={80} width={80} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
 
`;
export default Spinner;
