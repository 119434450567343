import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import img1 from './imgs/1.JPG';
import img2 from './imgs/2.jpg';
import img3 from './imgs/3.png';
import img4 from './imgs/4.jpg';
import img5 from './imgs/5.jpg';

// if testimony's length is greater than this value, readmore button will show
const MAX_TEXT_LENGTH = 100;
const styles = theme => ({
  title: {
    fontSize: '1.75rem',
    marginBottom: '81px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '400',
  },
  subtitle: {
    fontSize: '14px',
    marginTop: '56px',
    fontFamily: theme.fontFamily.primary,
  },
  description: {
    fontSize: '14px',
    padding: '11px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
});
class Testimony extends React.Component {
  constructor(props) {
    super(props);
    const { language } = props;
    this.testimonyOneCtn = language === 'zh-hans'
      ? '在跟Mentors合作的过程中，首先第一次见面是给我很大震撼的，在我认为中应该只是业务人员跟我沟通，但是没想到，你们的董事和业务人员一起来的，从而让我觉得你们很重视每一个客户，在交谈过程中很真诚，并且团队精神是以教育为主。在后期接触中跟我对接的工作人员很认真负责，给我最深的感触是，当我有困难的时候，你们会真心的帮助我，无论是否关于我们之间的合作，都会给我真心的指导和帮助。在合作过程中，给我推荐过来的学生素质都很高，是一个高质量的合作伙伴。接下来很期待和你们的继续合作，希望你们可以越办越好，同时我们的合作也越来越顺利。'
      : 'Summer fun fair is a great opportunity for us to reach out and connect with the community, which is the core value of our studio. It has been great experience working with a team of expert in marketing who knows about the community. Thank you Mentors for all the great advise and providing a great platform for the parents and our studio';
    this.testimonyTwoCtn = language === 'zh-hans'
      ? '作为三个孩子的妈妈，我带孩子们参加了Mentors\n'
      + '从今年创始之初至今所有的很有意义的活动，包括\n'
      + 'Parenting Seminar, UCC Open House 等。从中我们收获颇丰，结识了很多家长朋友和很不错的教育机构\n'
      + '，并且深刻感受到了Mentors团队对我们家长及孩子们需求的关心，对做青少年教育服务平台的认真负\n'
      + '责态度与激情！ 希望Mentors 可以越走越远，活动越办越好！'
      : 'As a mom of three kids,we had attended every single meaningful event organized by Mentors including Parenting Seminar,UCC Open House, etc. It was a perfect experience and opportunity for us to learn and get to know more parents and great educational providers. We deeply feel the passion from Mentors team that they are dedicated to offer a trusted and reliable youth education platform to parents and, how Mentors care about parents and children’s needs. We wish Mentors all the best!';
    this.testimonyThreeCtn = language === 'zh-hans'
      ? 'Mentors 很重视每一个客户，工作人员很认真负责，很真诚。推荐过来的学生和家长素质都很高，是\n'
      + '一个值得信赖的合作伙伴。很高兴和你们的继续合作，希望你们可以越办越好。'
      : 'As a professional travel goods supplier, working with Mentors is very pleasant, even just for a short period of time. We strongly feel their  passion on education and, they truly care about their customers\' benefits !\n'
      + '\n' + 'Look forward to working with Mentors team closely for many years to come.\n'
      + 'Terry Jiang, Sales Director, Trochi Luggage';
    this.state = {
      testimonyOneReadMoreState: {
        shouldShow: this.testimonyOneCtn.length > MAX_TEXT_LENGTH,
        isOpen: false,
      },
      testimonyTwoReadMoreState: {
        shouldShow: this.testimonyTwoCtn.length > MAX_TEXT_LENGTH,
        isOpen: false,
      },
      testimonyThreeReadMoreState: {
        shouldShow: this.testimonyThreeCtn.length > MAX_TEXT_LENGTH,
        isOpen: false,
      },
    };
    this.onReadMoreClicked = this.onReadMoreClicked.bind(this);
  }

  onReadMoreClicked(e) {
    const { [`${e.target.name}ReadMoreState`]: target } = this.state;
    e.persist();
    this.setState(() => ({
      [`${e.target.name}ReadMoreState`]: {
        shouldShow: true,
        isOpen: !target.isOpen,
      },
    }
    ));
  }

  _renderReadMoreState(name, shouldShow, isOpen) {
    if (shouldShow) {
      if (isOpen) {
        return (
          <FormattedMessage id="read_more">
            { msg => (
              <Button name={name} onClick={this.onReadMoreClicked}>
                {msg}
                -
              </Button>
            )
            }
          </FormattedMessage>
        );
      }
      return (
        <FormattedMessage id="read_more">
          { msg => (
            <Button name={name} onClick={this.onReadMoreClicked}>
              {msg}
                +
            </Button>
          )
            }
        </FormattedMessage>
      );
    }
    return null;
  }

  render() {
    const { classes, language } = this.props;
    const {
      testimonyOneReadMoreState,
      testimonyTwoReadMoreState,
      testimonyThreeReadMoreState,
    } = this.state;
    if (language === 'zh-hans') {
      return (
        <Wrapper>
          <div style={{ width: '66%' }}>
            <Typography align="center" className={classes.title} color="textSecondary">
              <FormattedMessage id="testimony_title" />
            </Typography>
            <Wrapper1>
              <Wrapper2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Img src={img1} />
                </div>
                <Typography align="center" className={classes.subtitle} color="textSecondary">澜音古筝学校</Typography>
                <Typography align="center" className={classes.description} color="textSecondary">
                  {
                    testimonyOneReadMoreState.isOpen
                      ? this.testimonyOneCtn : this.testimonyOneCtn.substring(0, MAX_TEXT_LENGTH)
                  }
                </Typography>
                <TextAlignCenterDiv>
                  {
                    this._renderReadMoreState('testimonyOne', testimonyOneReadMoreState.shouldShow, testimonyOneReadMoreState.isOpen)
                  }
                </TextAlignCenterDiv>
              </Wrapper2>
              <Wrapper2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Img src={img2} />
                </div>
                <Typography align="center" className={classes.subtitle} color="textSecondary">Becky Wen</Typography>
                <Typography align="center" className={classes.description} color="textSecondary">
                  {
                    testimonyTwoReadMoreState.isOpen
                      ? this.testimonyTwoCtn : this.testimonyTwoCtn.substring(0, MAX_TEXT_LENGTH)
                  }
                </Typography>
                <TextAlignCenterDiv>
                  {
                    this._renderReadMoreState('testimonyTwo', testimonyTwoReadMoreState.shouldShow, testimonyTwoReadMoreState.isOpen)
                  }
                </TextAlignCenterDiv>
              </Wrapper2>
              <Wrapper2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Img src={img3} />
                </div>
                <Typography align="center" className={classes.subtitle} color="textSecondary">Sylvan Learning</Typography>
                <Typography align="center" className={classes.description} color="textSecondary">
                  {
                    testimonyThreeReadMoreState.isOpen
                      ? this.testimonyThreeCtn : this.testimonyThreeCtn.substring(0, MAX_TEXT_LENGTH)
                  }
                </Typography>
                <TextAlignCenterDiv>
                  {
                    this._renderReadMoreState('testimonyThree', testimonyThreeReadMoreState.shouldShow, testimonyThreeReadMoreState.isOpen)
                  }
                </TextAlignCenterDiv>
              </Wrapper2>
            </Wrapper1>
          </div>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <div style={{ width: '66%' }}>
          <Typography align="center" className={classes.title} color="textSecondary">
            <FormattedMessage id="testimony_title" />
          </Typography>
          <Wrapper1>
            <Wrapper2>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Img src={img4} />
              </div>
              <Typography align="center" className={classes.subtitle} color="textSecondary">Artnection</Typography>
              <Typography align="center" className={classes.description} color="textSecondary">
                {
                    testimonyOneReadMoreState.isOpen
                      ? this.testimonyOneCtn : this.testimonyOneCtn.substring(0, MAX_TEXT_LENGTH)
                  }
              </Typography>
              <TextAlignCenterDiv>
                {
                    this._renderReadMoreState('testimonyOne', testimonyOneReadMoreState.shouldShow, testimonyOneReadMoreState.isOpen)
                  }
              </TextAlignCenterDiv>
            </Wrapper2>
            <Wrapper2>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Img src={img5} />
              </div>
              <Typography align="center" className={classes.subtitle} color="textSecondary">Trochi</Typography>
              <Typography align="center" className={classes.description} color="textSecondary">
                {
                    testimonyThreeReadMoreState.isOpen
                      ? this.testimonyThreeCtn : this.testimonyThreeCtn.substring(0, MAX_TEXT_LENGTH)
                  }
              </Typography>
              <TextAlignCenterDiv>
                {
                    this._renderReadMoreState('testimonyThree', testimonyThreeReadMoreState.shouldShow, testimonyThreeReadMoreState.isOpen)
                  }
              </TextAlignCenterDiv>
            </Wrapper2>
            <Wrapper2>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Img src={img2} />
              </div>
              <Typography align="center" className={classes.subtitle} color="textSecondary">Becky Wen</Typography>
              <Typography align="center" className={classes.description} color="textSecondary">
                {
                  testimonyTwoReadMoreState.isOpen
                    ? this.testimonyTwoCtn : this.testimonyTwoCtn.substring(0, MAX_TEXT_LENGTH)
                }
              </Typography>
              <TextAlignCenterDiv>
                {
                  this._renderReadMoreState('testimonyTwo', testimonyTwoReadMoreState.shouldShow, testimonyTwoReadMoreState.isOpen)
                }
              </TextAlignCenterDiv>
            </Wrapper2>
          </Wrapper1>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
    width: 100%;
    padding: 50px;
    background-color: ${props => props.theme.palette.background.blue}
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper1 = styled.div`
    display: flex;
`;
const Wrapper2 = styled.div`
    flex-grow: 1;
    width: 33%;
`;
const Img = styled.div`
    height: 150px;
    width: 150px;
    background: ${props => `url("${props.src}")`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
`;
const Button = styled.button`
  margin: 12px 0;
  padding: 0;
  background: transparent;
  border: none;
  outline:none;
  :focus{
    outline: none;
  }
  color: ${props => props.theme.palette.background.orange};
`;
const TextAlignCenterDiv = styled.div`
  text-align: center;
`;
Testimony.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  language: state.language.language,
});
export default connect(mapStateToProps, null)(withStyles(styles)(Testimony));
