export const options1 = [
  {
    text: 'five_km',
    value: 5,
  },
  {
    text: 'ten_km',
    value: 10,
  },
  {
    text: 'twenty_km',
    value: 20,
  },
];
export const options2 = [
  {
    text: 'five_plus_stars',
    value: 5,
  },
  {
    text: 'four_plus_stars',
    value: 4,
  },
  {
    text: 'three_plus_stars',
    value: 3,
  },
  {
    text: 'two_plus_stars',
    value: 2,
  },
  {
    text: 'one_plus_star',
    value: 1,
  },
];
export const options3 = [
  {
    text: 'academy',
    value: 'Academy',
  },
  {
    text: 'art',
    value: 'Art',
  },
  {
    text: 'sport',
    value: 'Sport',
  },
  {
    text: 'private_school',
    value: 'Private School',
  },
  {
    text: 'music',
    value: 'Music',
  },
  {
    text: 'integrated_school',
    value: 'Integrated School',
  },
  {
    text: 'summer_camp',
    value: 'Summer Camp',
  },
];
