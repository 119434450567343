const zhCN = {
  eventbrite_register: '注册',
  nav_search: '找学校/老师',
  nav_event: '精彩活动',
  about_us: '我们是谁',
  nav_contact: '联系我们',
  nav_coupon: '特价优惠',
  nav_login: '登录',
  nav_logout: '登出',
  nav_signUp: '成为会员',
  nav_welcome: '欢迎!',
  banner_title: 'Mentors，为家长们和下一代提供一站式教育解决方案的平台，主要服务包括各类网课、教育机构信息与评分，线下活动，以及亲子教育咨询等。 我们致力于协助家长们省时、省力、省钱的解决孩子们在教育方面的各种需求。',
  banner_subTitle: '深度搜索   教育机构信息   真实家长评分反馈   精彩线下活动   特价优惠信息',
  banner_learnMore: '了解更多',
  banner_placeHolder: '钢琴/数学/篮球',
  searchBar_search: '搜索',
  academy: '学术类',
  art: '艺术类',
  sport: '体育类',
  private_school: '私校',
  more: '更多',
  music: '音乐类',
  integrated_school: '综合',
  summer_camp: '夏令营',
  events_title: 'Mentors 精彩活动',
  coupons_title: '特价优惠',
  coupons_more: '+ 查看更多优惠',
  testimony_title: '大家眼中的MENTORS',
  partners_title: '我们的合作商家',
  bottomBanner_contactUs: '联系我们',
  bottomBanner_signUp: '成为会员',
  signIn_signIn: '登录进行评分和留言',
  signIn_formFooter_1: '没有账户？',
  signIn_formFooter_2: '马上注册',
  signIn_formFooter_3: '立即享受会员的独享福利',
  signIn_login: '登录',
  signIn_email: '邮箱',
  signIn_password: '密码',
  signIn_forgetPassword: '忘记密码',
  signIn_sendEmail: '发送邮件,找回密码',
  signIn_success_title: '登录成功',
  signIn_fail_message: '邮箱或者密码错误',
  signIn_password_email_must_not_empty: '邮箱和密码不能为空',
  signUp_signInNow: '注册',
  signUp_email: '邮箱*',
  signUp_password: '密码*',
  signUp_repeatPassword: '重复输入密码*',
  signUp_userName: '用户名*',
  signUp_zipCode: '邮编',
  signUp_residence: '居住城市',
  signUp_relation: '与孩子的关系',
  signUp_letUsLearnMoreAboutYouAndYourChildren: '让我们了解您和您的小孩更多*',
  signUp_childrenInformation: '孩子信息: ',
  signUp_moreChildren: '+ 添加其他孩子信息',
  signUp_removeChildren: '- 移除孩子信息',

  signUp_moreInfoBtn: '+ 添加更多信息',
  signUp_lessInfoBtn: '- 移除更多信息',

  signUp_familyIncome: '家庭收入 (可选) ',
  signUp_gender: '性别 ',
  signUp_birthyear: ' 出生年份',
  signUp_please_select_child_info_message: '请选择孩子的出生年份和性别',
  signUp_success_title: '恭喜您成功注册，成为Mentors的会员!',
  signUp_success_message: '现在您可以看到所有的家长对教育机构的评论，并且可以在Mentors网站上对所有的教育机构及学校进行评分与评论，同时享受各种会员优惠。祝您在Mentors上有愉快的体验！',
  signUp_fail_message: '本次注册失败,请重试',
  signUp_languagePrefer: '语言偏好',
  chinese: '中文',
  english: '英语',
  dialog_fail_title: '失败',
  dialog_success_title: '成功',
  forget_password_fail: '修改密码失败!',
  forget_password_success: '已成功发送一封邮件到您的邮箱!',
  reset_password_success: '修改密码成功!',
  reset_password_fail: '出问题了,请重试',
  login_to_see_more: '登录查看更多',
  distance: '距离',
  rating: '评分',
  category: '类别',
  one_plus_star: '1+ 星',
  two_plus_stars: '2+ 星',
  three_plus_stars: '3+ 星',
  four_plus_stars: '4+ 星',
  five_plus_stars: '5 星',
  five_km: '5 公里',
  ten_km: '10 公里',
  twenty_km: '20 公里',
  schoolDetail_AboutUs: '学校介绍',
  schoolDetail_OurTeacher: '老师介绍',
  schoolDetail_OurClass: '课程介绍',
  schoolDetail_Price: '价格',
  rating_overall: '综合',
  rating_studentRating: '学生反馈',
  rating_professionalism: '专业程度',
  rating_environmentAndEquipment: '环境和硬件',
  rating_customerService: '服务',
  resultListing_notSchoolFound: '找不到学校',
  reset_password: '修改密码',
  the_best_choice_for_your_kids: 'The Best Choice For Your Kids',
  about_us_description: 'Mentors，为家长们和下一代提供一站式教育解决方案的平台，主要服务包括各类网课、教育机构信息与评分，线下活动，以及亲子教育咨询等。 我们立足于北美，致力于协助家长们省时、省力、省钱的解决孩子们在教育方面的各种需求！',
  about_us_sub_description: 'Mentors 提供以下会员服务：',
  about_us_sub_description_bottom: '分享您的教育理念，让我们共同成长…',
  about_us_point_1: '丰富多彩的高质量网课，公益讲座，及夏令营',
  about_us_point_2: '全面的教育机构信息',
  about_us_point_3: '在线评分与评论',
  about_us_point_4: '专业客服',
  about_us_point_5: '线下活动：教育展，嘉年华，亲子活动',
  couponPage_coupon: '特价优惠',
  couponPage_title1: '课外辅导班优惠',
  couponPage_title2: '户外活动优惠',
  couponPage_title3: '器材商家优惠',
  eventPage_events: '精彩活动',
  eventPage_subtitle: '精彩活动等着您…',
  eventPage_previous_events: '精彩活动回顾',
  option_please_select: '请选择',
  gender_male: '男',
  gender_female: '女',
  writeReview_title: '评分和留言',
  writeReview_textarea_placeHolder: '写下您的看法和体验',
  writeReview_submit: '提交',
  writeReview_success: '评论成功',
  writeReview_fail: '上传评论失败',
  link_to_website: '网站链接',
  reviews: '评分和留言',
  write_review: '写评论',
  login_to_write_review: '登录写评论',
  read_more: '阅读更多',
  filter: '筛选',
  all: '全部',
  one_star: '1星',
  two_stars: '2星',
  three_stars: '3星',
  four_stars: '4星',
  five_stars: '5星',
  review_and_rating: '评论与评分',
  photos: '照片',
  vip_promotion_free_member_benefits: '注册会员福利：',
  vip_promotion_free_member_benefits_line1: '全面的教育机构信息',
  vip_promotion_free_member_benefits_line2: '在线评分与评论',
  vip_promotion_free_member_benefits_line3: '多功能深度搜索',
  vip_promotion_free_member_benefits_line4: '线下活动：教育展，嘉年华，以及专题讲座等',
  vip_promotion_free_member_benefits_line5: '教育机构课程优惠以及合作商家特价',
  vip_promotion_vip_benefits: 'VIP 会员独享：',
  vip_promotion_vip_benefits_line1: '注册会员的全部福利',
  vip_promotion_vip_benefits_line2: '免费参加线下活动',
  vip_promotion_vip_benefits_line3: '限量课程优惠以及额外商家特价（VIP 独享）',
  vip_promotion_vip_benefits_line4: '免费前60分钟一对一咨询',
  vip_promotion_contact_us_1: '联系我们立刻成为VIP会员',
  vip_promotion_contact_us_2: '电话号码：647.968.8618 邮箱：mentors@mentorserv.com',

  page_not_found: '页面未找到',
  server_error: '服务器出错',
  back_to_home_page: '返回主页',
};
export default zhCN;
