import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import icon from './mentors-logo-icon-white.png';
import styled from 'styled-components';
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.primary,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.primary.main,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Icon />
      <Typography variant="h6" color="textPrimary" align="center">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const dialogContentStyle = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    minWidth: '500px',
    textAlign: 'left',
    backgroundColor: theme.palette.background.primary,
  },
});
const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);

function Dialogs(props) {
  const {
    onCloseHandler, isOpen, message, title, classes,
  } = props;
  return (
    <div>
      <Dialog
        onClose={onCloseHandler}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={onCloseHandler}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography color="textPrimary" gutterBottom className={classes.dialogContent}>
            {message}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}

Dialogs.propTypes = {
  onCloseHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
};
Dialogs.defaultProps = {
  title: null,
};
const dialogsStyle = theme => ({
  dialogContent: {
    fontFamily: theme.fontFamily.primary,
  },
});


const Icon = styled.div`
  background-image: url('${icon}');
  background-size: contain;
  width: 25px;
  height: 25px;
  position: absolute;
`;
export default withStyles(dialogsStyle)(Dialogs);
