import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import uuid from 'uuid';
import { FormattedMessage } from 'react-intl';
import Rating from '../../components/Rating';
import Spinner from '../../components/Spinner/Spinner';
import RatingBar from './RatingBar/RatingBar';
import { getSchoolDetail } from '../../actions/school';
import star1 from '../../assets/imgs/rating/1stars.png';
import star2 from '../../assets/imgs/rating/2stars.png';
import star3 from '../../assets/imgs/rating/3stars.png';
import star4 from '../../assets/imgs/rating/4stars.png';
import star5 from '../../assets/imgs/rating/5stars.png';
import Reviews from './Reviews/Reviews';
import schoolMarker from '../../assets/imgs/schoolMarker.png';
import keys from '../../config';
import WriteReview from './WriteReview/WriteReview';
import { jumpTo } from '../../utils/route';
import { GET_SCHOOL_DETAIL_FAIL } from '../../actions/actionTypes';

const { googleMapKey } = keys;
class SchoolDetailPage extends React.Component {
  state = {
    aboutUsReadMoreState: {
      shouldShow: false,
      isOpen: false,
    },
    ourTeacherReadMoreState: {
      shouldShow: false,
      isOpen: false,
    },
    ourClassReadMoreState: {
      shouldShow: false,
      isOpen: false,
    },
    showWriteReview: false,
  };

  componentDidMount() {
    // Fetch SchoolDetail
    const {
      match, getSchoolDetail, history, location,
    } = this.props;
    const { slug } = match.params;
    getSchoolDetail(slug).catch((error) => {
      switch (error.response.status) {
        case 404:
          jumpTo('/error/page-not-found', history, location);
          break;
        default:
          jumpTo('/error/server-error', history, location);
      }
    });
  }

  componentWillReceiveProps(props) {
    // Decide whether to show ReadMore button
    if (!_.isEmpty(props.school)) {
      if (props.school.school_description.length > 300) {
        this.setState({
          aboutUsReadMoreState: {
            shouldShow: true,
            isOpen: false,
          },
        });
      }
      if (props.school.teacher_description.length > 300) {
        this.setState({
          ourTeacherReadMoreState: {
            shouldShow: true,
            isOpen: false,
          },
        });
      }
      if (props.school.course_description.length > 300) {
        this.setState({
          ourClassReadMoreState: {
            shouldShow: true,
            isOpen: false,
          },
        });
      }
    }
    // Close WriteReview Modal
    this.setState({
      showWriteReview: false,
    });
  }

  onAboutUsReadMoreClicked = () => {
    const { aboutUsReadMoreState } = this.state;
    this.setState({
      aboutUsReadMoreState: {
        shouldShow: true,
        isOpen: !aboutUsReadMoreState.isOpen,
      },
    });
  };

  onOurTeacherReadMoreClicked = () => {
    const { ourTeacherReadMoreState } = this.state;
    this.setState({
      ourTeacherReadMoreState: {
        shouldShow: true,
        isOpen: !ourTeacherReadMoreState.isOpen,
      },
    });
  };

  onOurClassReadMoreClicked = () => {
    const { ourClassReadMoreState } = this.state;
    this.setState({
      ourClassReadMoreState: {
        shouldShow: true,
        isOpen: !ourClassReadMoreState.isOpen,
      },
    });
  };

  onWriteReviewClickedHandler = () => {
    const { user, history, location } = this.props;
    if (_.isEmpty(user)) {
      jumpTo('/signIn', history, location);
    } else {
      this.setState({
        showWriteReview: true,
      });
    }
  };

  onCloseWriteReviewModal = (e) => {
    e.stopPropagation();
    this.setState({
      showWriteReview: false,
    });
  };

  /* eslint-disable */
  render(){
    console.log("schoolDetail Render");
    const {
      aboutUsReadMoreState,
      ourClassReadMoreState,
      ourTeacherReadMoreState,
    } = this.state;
    const { school, isLoading, match, user } = this.props;
    const {
      id,
      background,
      name,
      price,
      average_rating,
      student_rating,
      profession_rating,
      service_rating,
      env_rating,
      five_star_count,
      four_star_count,
      three_star_count,
      two_star_count,
      one_star_count,
      coordinates,
      url,
      email,
      phone_number,
      address,
      media,
      logo,
      slug,
    } = school;
    let {
      school_description,
      teacher_description,
      course_description,
    } = school;
    const reviewCount = one_star_count + two_star_count + three_star_count + four_star_count + five_star_count;
    let aboutUsReadMore, ourTeacherReadMore, ourClassReadMore;
    if(aboutUsReadMoreState.shouldShow){
      if(!aboutUsReadMoreState.isOpen){
        aboutUsReadMore = <Button onClick={this.onAboutUsReadMoreClicked}><FormattedMessage id="read_more"/> +</Button>
      }else{
        aboutUsReadMore = <Button onClick={this.onAboutUsReadMoreClicked}><FormattedMessage id="read_more"/> -</Button>
      }
    }else{
      aboutUsReadMore = null;
    }
    if(ourTeacherReadMoreState.shouldShow){
      if(!ourTeacherReadMoreState.isOpen){
        ourTeacherReadMore = <Button onClick={this.onOurTeacherReadMoreClicked}><FormattedMessage id="read_more"/> +</Button>
      }else{
        ourTeacherReadMore = <Button onClick={this.onOurTeacherReadMoreClicked}><FormattedMessage id="read_more"/> -</Button>
      }
    }else{
      ourTeacherReadMore = null;
    }
    if(ourClassReadMoreState.shouldShow){
      if(!ourClassReadMoreState.isOpen){
        ourClassReadMore = <Button onClick={this.onOurClassReadMoreClicked}><FormattedMessage id="read_more"/> +</Button>
      }else{
        ourClassReadMore = <Button onClick={this.onOurClassReadMoreClicked}><FormattedMessage id="read_more"/> -</Button>
      }
    }else{
      ourClassReadMore = null;
    }
    if(isLoading){
      return <Spinner />;
    }else{
      school_description = aboutUsReadMoreState.isOpen?  school_description : school_description.substring(0,299);
      teacher_description = ourTeacherReadMoreState.isOpen? teacher_description : teacher_description.substring(0,299);
      course_description = ourClassReadMoreState.isOpen? course_description : course_description.substring(0,299);
      let rateImgURL;
      switch (average_rating) {
        case 1: {
          rateImgURL = star1;
          break;
        }
        case 2: {
          rateImgURL = star2;
          break;
        }
        case 3: {
          rateImgURL = star3;
          break;
        }
        case 4: {
          rateImgURL = star4;
          break;
        }
        case 5: {
          rateImgURL = star5;
          break;
        }
        default: {
          rateImgURL = star1;
        }
      }
      return (
        <Fragment>
          {this.state.showWriteReview?<WriteReview onCloseWriteReviewModal={this.onCloseWriteReviewModal} slug={slug} name={name} address={address} logo={logo}/>:null}
          <PlaceHolder />
          <SchoolBackground>
            <Img src={background} alt="background" />
          </SchoolBackground>
          <SchoolDetailWrapper>
            <SchoolDetailLeftWrapper>
              <Title>{name}</Title>
              <RatingWrapper>
                <Rating
                  reviewCount={reviewCount}
                  average_rating={average_rating}
                  student_rating={student_rating}
                  profession_rating={profession_rating}
                  service_rating={service_rating}
                  env_rating={env_rating}
                />
              </RatingWrapper>
              <div>
                <FormattedMessage id="schoolDetail_AboutUs">
                  {
                    message => <SubTitle>{message}</SubTitle>
                  }
                </FormattedMessage>
                <Description>{school_description}</Description>
                <div>{aboutUsReadMore}</div>
              </div>
              <hr/>
              {
                price === null? null: <React.Fragment><div>
                    <FormattedMessage id="schoolDetail_Price">
                      {
                        message => <SubTitle>{message}</SubTitle>
                      }
                    </FormattedMessage>
                    <Description>{price}</Description>
                  </div>
                  <hr/>
                </React.Fragment>
              }
              <div>
                <FormattedMessage id="schoolDetail_OurTeacher">
                  {message => <SubTitle>{message}</SubTitle>}
                </FormattedMessage>
                <Description>{teacher_description}</Description>
                <div>{ourTeacherReadMore}</div>
              </div>
              <hr/>
              <div>
                <FormattedMessage id="schoolDetail_OurClass">
                  {
                    message => <SubTitle>{message}</SubTitle>
                  }
                </FormattedMessage>
                <Description>{course_description}</Description>
                <div>{ourClassReadMore}</div>
              </div>
              <hr/>
              <div style={{marginTop: '50px', display:"relative"}}>
                <div style={{display:"flex",justifyContent:"flex-end"}}>
                  <WriteReviewButton onClick={this.onWriteReviewClickedHandler}>
                    {_.isEmpty(user) ? <FormattedMessage id="login_to_write_review"/> : <FormattedMessage id="write_review"/>}
                  </WriteReviewButton>
                </div>
                <SubTitle><FormattedMessage id="review_and_rating"/>({reviewCount})</SubTitle>
                <div style={{display:"flex"}}>
                  <div style={{width:"70%"}}>
                    <RatingWrapper>
                      <Rating
                        reviewCount={reviewCount}
                        average_rating={average_rating}
                        student_rating={student_rating}
                        profession_rating={profession_rating}
                        service_rating={service_rating}
                        env_rating={env_rating}
                      />
                    </RatingWrapper>
                  </div>
                  <RatingBarWrapper>
                    <RatingBar
                      fiveStarCount={five_star_count}
                      fourStarCount={four_star_count}
                      threeStarCount={three_star_count}
                      twoStarCount={two_star_count}
                      oneStarCount={one_star_count}
                    />
                  </RatingBarWrapper>
                </div>
              </div>
              <Reviews slug={slug} />
            </SchoolDetailLeftWrapper>
            <SchoolDetailRightWrapper>
              {phone_number === null?null:<Info style={{margin:"15px 0"}}>{phone_number}</Info>}
              {email === null?null:<Info>{email}</Info>}
              {address === null?null:<Info>{address}</Info>}
              {url === null?null:
                <Info>
                    <a href={url} target="_blank" style={{color:"#05B8FF"}}><FormattedMessage id="link_to_website" /></a>
                </Info>
              }
              <MapWrapper>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapKey }}
                  center={
                    {
                      lat: coordinates[1],
                      lng: coordinates[0],
                    }
                  }
                  defaultZoom={11}
                >
                  <SchoolMark
                    lat={coordinates[1]}
                    lng={coordinates[0]}
                  />
                </GoogleMapReact>
              </MapWrapper>
              <Photos><FormattedMessage id="photos"/>({media.length})
              {
                media.map(mediaImg => <MediaImg src={mediaImg.image} key={uuid()}/>)
              }
              </Photos>
            </SchoolDetailRightWrapper>
          </SchoolDetailWrapper>
        </Fragment>
      );
    }
  }
}
const Info = styled.div`
  margin: 15px 0;
  white-space:pre-wrap;
`;
const Img = styled.img`
  width: 100%;
`;
const Description = styled.div`
  white-space: pre-wrap;
`;
const MediaImg = styled.div`
  background-image: ${props => `url("${props.src}")`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 214px;
  margin: 12px 0;
`;
const Photos = styled.div`
  color: ${props => props.theme.palette.background.primary};
  margin: 12px 0;
`;
const MapWrapper = styled.div`
  width: 100%;
  height: 300px;
`;
const SchoolMark = styled.div`
  background-image: url("${schoolMarker}");
  width: 30px;
  height: 30px;
`;
const PlaceHolder = styled.div`
  background-color: ${props => props.theme.palette.background.primary};
  width: 100%;
  height: 71px;
`;
const Title = styled.div`
  font-size: 2.5rem;
  margin-bottom: 18px;
  font-family: ${props => props.theme.fontFamily.primary};
`;
const SchoolBackground = styled.div`
  background-image: ${props => `url("${props.src}")`};
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const SchoolDetailWrapper = styled.div`
  margin: 0 auto;
  width: 85%;
  background: white;
  display: flex;
  padding: 30px;
`;
const SchoolDetailLeftWrapper = styled.div`
  flex-grow: 1;
  margin-right: 44px;
`;
const SchoolDetailRightWrapper = styled.div`
  width: 320px;
  min-width: 320px;
  padding: 0 15px;
`;
const RatingWrapper = styled.div`
  margin-bottom: 67px;
`;
const SubTitle = styled.div`
  color: ${props => props.theme.palette.background.primary};
  margin: 30px 0;
  font-size: 1.125rem;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
`;
const Button = styled.button`
  margin: 12px 0;
  padding: 0;
  background: transparent;
  border: none;
  outline:none;
  :focus{
    outline: none;
  }
  color: ${props => props.theme.palette.background.orange};
`;
const WriteReviewButton = styled.button`
  background: transparent;
  border: 1px solid #ff9700;
  color: #ff9700;
  border-radius: 4px;
  padding: 10px 20px;
`;
const RatingBarWrapper = styled.div`
  width: 30%;
`;

SchoolDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  getSchoolDetail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  school: state.schoolDetail.school,
  isLoading:state.schoolDetail.isLoading,
  user: state.authentication.user,
});

const mapDispatchToProps = {
  getSchoolDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withRouter(SchoolDetailPage))); // eslint-disable-line
