import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import photo from './imgs/kids-3448028_1920.png';
import FaceBookIcon from "../../components/FaceBookIcon/FaceBookIcon";
import InstagramIcon from "../../components/InstagramIcon/InstagramIcon";
import {FACE_BOOK_URL, INSTAGRAM_URL} from "../../constants/socialLinks";
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';

const socialLinksStyle = {
  fontSize: '30px',
};

function ContactPage(props) {
  const { language } = props;
  let contents;
  if (language !== 'zh-hans') {
    contents = (
      <React.Fragment>
        <Filler />
        <DIV>
          <div><Img src={photo} alt="photo" /></div>
          <Wrapper2>
            <Wrapper>
              <Title>Contact Us</Title>
              <Line1>
                <Left>
                  <div>VIP Member & Partnership</div>
                  <div>647.968.8618</div>
                </Left>
              </Line1>
              <Line2>
                <div>Email</div>
                <div>mentors@mentorserv.com</div>
              </Line2>
              <Line3>
                <div>Address</div>
                <div>8500 Leslie St, Suite 110 </div>
                <div>Richmond Hill ON L3T 7M8 Canada</div>
              </Line3>
              <Line4>
                <FaceBookIcon
                  url={FACE_BOOK_URL}
                  style={socialLinksStyle}
                />
                <InstagramIcon
                  url={INSTAGRAM_URL}
                  style={socialLinksStyle}
                />
              </Line4>
            </Wrapper>
          </Wrapper2>
        </DIV>
      </React.Fragment>
    );
  } else {
    contents = (
      <React.Fragment>
        <Filler />
        <DIV>
          <div><Img src={photo} alt="photo" /></div>
          <Wrapper2>
            <Wrapper>
              <Title>如想了解我们更多，欢迎联系我们！</Title>
              <Line1>
                <Left>
                  <div>会员服务与商家合作</div>
                  <div>647.968.8618</div>
                </Left>
              </Line1>
              <Line2>
                <div>邮箱</div>
                <div>mentors@mentorserv.com</div>
              </Line2>
              <Line3>
                <div>地址</div>
                <div>8500 Leslie St, Suite 110 </div>
                <div>Richmond Hill ON L3T 7M8 Canada</div>
              </Line3>
              <Line4>
                <FaceBookIcon
                  url={FACE_BOOK_URL}
                  style={socialLinksStyle}
                />
                <InstagramIcon
                  url={INSTAGRAM_URL}
                  style={socialLinksStyle}
                />
              </Line4>
            </Wrapper>
          </Wrapper2>
        </DIV>
      </React.Fragment>
    );
  }
  return (
    <div>
      {contents}
    </div>
  );
}
const Title = styled.div`
  margin-bottom: 44px;
  font-size: 28px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
  color: black;
`;
const Left = styled.div`
`;
const Line1 = styled.div`
  margin-bottom: 26px;
  display: flex;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
  font-size: 18px;
  color: black;
`;
const Line2 = styled.div`
  margin-bottom: 26px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
  font-size: 18px;
  color: black;
`;
const Line3 = styled.div`
  margin-bottom: 26px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
  font-size: 18px;
  color: black;
`;

const Line4 = styled.div`
`;

const Wrapper = styled.div`
`;
const Wrapper2 = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content:center;
`;
const Filler = styled.div`
  background-color: ${props => props.theme.palette.background.primary};
  height: 71px;
`;
const DIV = styled.div`
  display:flex;
`;
const Img = styled.img`
  height: calc(100vh - 392px);
  min-height: 450px;
`;

ContactPage.propTypes = {
  language: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  language: state.language.language,
});
export default connect(mapStateToProps, null)(withTheme(ContactPage));
