import { GET_REVIEW_FAIL, GET_REVIEW_LOADING, GET_REVIEW_SUCCESS } from './actionTypes';
import axios from '../axios';

export const getReviews = (slug, pageID, pageSize, rating) => (dispatch) => {
  dispatch({
    type: GET_REVIEW_LOADING,
  });
  const params = {};
  if (pageID) {
    params.page_id = pageID;
  }
  if (rating !== '0') {
    // rating = "0" means select all
    params.rating = rating;
  }
  if (pageSize) {
    params.page_size = pageSize;
  }
  axios.get(`/schools/${slug}/reviews`, {
    params,
  })
    .then(response => dispatch({
      type: GET_REVIEW_SUCCESS,
      payload: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: GET_REVIEW_FAIL,
          payload: error,
        });
      }
    });
};

export const postReview = (slug, studentRating, professionalRating, serviceRating, envRating, message, onSuccessHandler, onFailHandler) => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  formData.append('student_rating', studentRating);
  formData.append('profession_rating', professionalRating);
  formData.append('service_rating', serviceRating);
  formData.append('env_rating', envRating);
  formData.append('message', message);

  axios.post(`/schools/${slug}/reviews/`, formData)
    .then(() => {
      onSuccessHandler();
    })
    .catch(() => {
      onFailHandler();
    });
};
