import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Banner from './Banner';
import { jumpTo } from '../../../utils/route';

class BannerContainer extends Component {
  onLearnMoreClickedHandler = () => {
    const { history, location } = this.props;
    jumpTo('/about-us', history, location);
  };

  render() {
    return (
      <Banner
        onLearnMoreClickedHandler={this.onLearnMoreClickedHandler}
      />
    );
  }
}

BannerContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(BannerContainer);
