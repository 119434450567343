// import PingFangSCSemibold from '../assets/font/PingFang-SC-Semibold.otf';
// import PingFangSCRegular from '../assets/font/PingFang-SC-Regular.ttf';
// import PingFangSCLight from '../assets/font/PingFang-SC-Light.ttf';
// import PingFangSCMedium from '../assets/font/PingFang-SC-Medium.otf';
//
// // const font = {
// //   font_1: {
// //     fontName: 'PingFangSC-Semibold',
// //     fontUrl: PingFangSCSemibold,
// //   },
// //   font_2: {
// //     fontName: 'PingFangSC-Regular',
// //     fontUrl: PingFangSCRegular,
// //   },
// //   font_3: {
// //     fontName: 'PingFangSC-Light',
// //     fontUrl: PingFangSCLight,
// //   },
// //   font_4: {
// //     fontName: 'PingFangSC-Medium',
// //     fontUrl: PingFangSCMedium,
// //   },
// // };
// //
// // export default font;
// // font_1: medium
// // font_2: light
// // font_3: extralight
// // font_4: regular
// const font = {
//   font_1: {
//     fontName: 'source-han-sans-simplified-c',
//     fontWeight: 500,
//   },
//   font_2: {
//     fontName: 'source-han-sans-simplified-c',
//     fontWeight: 200,
//   },
//   font_3: {
//     fontName: 'source-han-sans-simplified-c',
//     fontWeight: 100,
//   },
//   font_4: {
//     fontName: 'source-han-sans-simplified-c',
//     fontWeight: 400,
//   },
// };
//
// export default font;

const font = {
  fontName: 'source-han-sans-simplified-c',
};
export default font;