import {defineMessages} from "react-intl";

export default defineMessages({
  chinese: {
    id: 'chinese',
    defaultMessage: 'chinese',
    description: 'language chinese',
  },
  english: {
    id: 'english',
    defaultMessage: 'english',
    description: 'language english',
  },
});