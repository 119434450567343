import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function BootstrapCarousel(props) {
  const { data, activeIndex } = props;
  return (
    <Wrapper>
      <div id="ads" className="carousel slide" data-ride="carousel" data-interval="false" style={{ width: '100%', height: '100%' }}>
        <div className="carousel-inner" style={{ width: '100%', height: '100%' }}>
          {
            data.map((item, index) => {
              const { imgUrl, onClick } = item;
              const classNameString = item.index === parseInt(activeIndex, 10) ? 'carousel-item active' : 'carousel-item';
              return (
                <div className={classNameString} style={{ width: '100%', height: '100%' }}>
                  <div style={{
                    width: '100%', height: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex',
                  }}
                  >
                    <Img src={imgUrl} onClick={onClick} />
                  </div>
                </div>
              );
            })
          }
        </div>
        <a className="carousel-control-prev" href="#ads" role="button" data-slide="prev" data-stopPropagation="true">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#ads" role="button" data-slide="next" data-stopPropagation="true">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Img = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;
BootstrapCarousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  })).isRequired,
  activeIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
