import { OPEN_DIALOG, CLOSE_DIALOG } from '../actions/actionTypes';

const initialState = {
  isOpen: false,
  message: '',
  title: '',
};
const filterReducer = (state = initialState, { message = '', title = '', type }) => {
  switch (type) {
    case OPEN_DIALOG: {
      return {
        ...state,
        isOpen: true,
        message,
        title,
      };
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        isOpen: false,
        message,
        title,
      };
    }
    default:
      return state;
  }
};

export default filterReducer;
