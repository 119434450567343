import {
  GET_REVIEW_SUCCESS,
  GET_REVIEW_LOADING,
  GET_REVIEW_FAIL,
} from '../actions/actionTypes';

const initialState = {
  review: {},
  isLoading: true,
  errors: [],
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEW_LOADING: {
      return {
        review: {},
        isLoading: true,
        errors: [],
      };
    }
    case GET_REVIEW_SUCCESS: {
      return {
        ...state,
        review: action.payload,
        isLoading: false,
      };
    }
    case GET_REVIEW_FAIL: {
      return {
        review: {},
        isLoading: false,
        errors: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reviewReducer;
