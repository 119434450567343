import { OPEN_DIALOG, CLOSE_DIALOG } from '../actionTypes';

export const openDialog = (title, message) => ({
  type: OPEN_DIALOG,
  message,
  title,
});
export const getCloseDialogAction = (title, message) => ({
  type: CLOSE_DIALOG,
  message,
  title,
});
