import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';
import { login } from '../../actions/user';
import axios from '../../axios';
import Spinner from '../../components/Spinner/Spinner';
import { openDialog } from '../../actions/ui/dialog';
import { goPrevPage, jumpTo } from '../../utils/route';
import background from './imgs/background.png';

const styles = theme => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '35px',
    marginBottom: '40px',
    fontFamily: theme.fontFamily.primary,
  },
  formFooter: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    textAlign: 'center',
    fontFamily: theme.fontFamily.primary,
  },
  button: {
    textTransform: 'none',
    fontFamily: theme.fontFamily.primary,
    height: '61px',
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    width: '82%',
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
    },
  },
});
class SignInPage extends React.Component {
  state = {
    email: '',
    password: '',
    showForgetPassword: false,
  };

  onEmailChangeHandler = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  onPasswordChangeHandler = (e) => {
    this.setState({
      password: e.target.value,
    });
  };


  onLoginHandler = () => {
    const { email, password } = this.state;
    const {
      login,
      history,
      location,
      openDialog,
      intl: { formatMessage },
    } = this.props;
    const onSuccess = () => {
      openDialog(formatMessage({ id: 'dialog_success_title' }), formatMessage({ id: 'signIn_success_title' }));
      goPrevPage(history, location);
    };
    const onFail = () => {
      openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'signIn_fail_message' }));
    };
    const user = { email, password };
    if (email !== '' && password !== '') {
      login(user).then(onSuccess, onFail);
    } else {
      openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'signIn_password_email_must_not_empty' }));
    }
  };

  onResetPassword= () => {
    const { email } = this.state;
    const {
      history,
      location,
      openDialog,
      intl: { formatMessage },
    } = this.props;
    // send email to server
    axios.post('/account/password/reset/', {
      email,
    })
      .then(() => {
        openDialog(formatMessage({ id: 'dialog_success_title' }), formatMessage({ id: 'forget_password_success' }));
        jumpTo('/', history, location);
      })
      .catch(() => {
        // Popup dialog
        openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'forget_password_fail' }));
      });
  };

  onForgetPasswordHandler = () => {
    this.setState({
      showForgetPassword: true,
    });
  };

  render() {
    const {
      showForgetPassword,
    } = this.state;
    const { isLoading, classes } = this.props;
    const spinner = isLoading ? <Spinner /> : null;
    const formContent = showForgetPassword
      ? (
        <FormBody>
          <FormattedMessage id="signIn_email">
            {
              placeholder => <Input onChange={this.onEmailChangeHandler} placeholder={placeholder} />
            }
          </FormattedMessage>
          <Button onClick={this.onResetPassword} className={classes.button}>
            <FormattedMessage id="signIn_sendEmail" />
          </Button>
        </FormBody>
      )
      : (
        <FormBody>
          <FormattedMessage id="signIn_email">
            {
              placeholder => <Input onChange={this.onEmailChangeHandler} placeholder={placeholder} />
            }
          </FormattedMessage>
          <FormattedMessage id="signIn_password">
            {
              placeholder => <Input type="password" onChange={this.onPasswordChangeHandler} placeholder={placeholder} />
            }
          </FormattedMessage>
          <Wrapper3>
            <ForgetPassword onClick={this.onForgetPasswordHandler}>
              <FormattedMessage id="signIn_forgetPassword" />
            </ForgetPassword>
          </Wrapper3>
          <Button onClick={this.onLoginHandler} className={classes.button}>
            <FormattedMessage id="signIn_login" />
          </Button>
        </FormBody>
      );
    return (
      <React.Fragment>
        <div>
          {spinner}
          <Wrapper>
            <Wrapper1>
              <Typography align="center" className={classes.title}>
                <FormattedMessage id="signIn_signIn" />
              </Typography>
              <Wrapper2>
                {formContent}
                <FormFooter>
                  <Typography className={classes.formFooter}>
                    <FormattedMessage id="signIn_formFooter_1" />
                    <Link style={{ fontWeight: 'bold' }} to="/signUp">
                      <FormattedMessage id="signIn_formFooter_2" />
                    </Link>
                    <FormattedMessage id="signIn_formFooter_3" />
                  </Typography>
                </FormFooter>
              </Wrapper2>
            </Wrapper1>
          </Wrapper>
        </div>
      </React.Fragment>
    );
  }
}
const Wrapper = styled.div`
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 884px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper1 = styled.div`
    background: transparent;
    width: 43%
`;

const Wrapper2 = styled.div`
    background: transparent;
`;
const Wrapper3 = styled.div`
    width: 82%;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  
`;
const FormBody = styled.div`
    width: 100%;
    height: 412px;
    background-color: ${props => props.theme.palette.background.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const FormFooter = styled.div`
    width: 100%;
    height: 92.8px;
    background-color: ${props => props.theme.palette.background.navy}
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Input = styled.input`
    width: 82%;
    height: 60.8px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px
    font-family: ${props => props.theme.fontFamily.primary};
    font-weight: 100;
`;

const ForgetPassword = styled.div`
    cursor: pointer;
    text-decoration: underline;
    background: transparent;
    color: blue;
    border: none;
    font-family: ${props => props.theme.fontFamily.primary}
    color: #797979;
    font-size: 0.7rem;
`;
const mapStateToProps = state => ({
  isLoading: state.authentication.isLoading,
  language: state.language.language,
});
const mapDispatchToProps = {
  login,
  openDialog,
};
SignInPage.propTypes = {
  login: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(withStyles(styles)(SignInPage))));
