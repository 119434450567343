import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import background from './imgs/background.png';
import Spinner from '../../../components/Spinner/Spinner';


const styles = theme => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '35px',
    marginBottom: '40px',
    fontFamily: theme.fontFamily.primary,
  },
  formFooter: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    textAlign: 'center',
    fontFamily: theme.fontFamily.primary,
  },
  button: {
    textTransform: 'none',
    fontFamily: theme.fontFamily.primary,
    height: '61px',
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    width: '82%',
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
    },
  },
});
const ResetPassword = (props) => {
  const {
    classes,
    onPasswordChangeHandler,
    isLoading,
    onResetPasswordHandler,
  } = props;
  const spinner = isLoading ? <Spinner /> : null;
  const formContent = (
    <FormBody>
      <FormattedMessage id="signUp_password">
        {
            placeholder => <Input type="password" onChange={onPasswordChangeHandler} placeholder={placeholder} name="password1" />
          }
      </FormattedMessage>
      <FormattedMessage id="signUp_repeatPassword">
        {
            placeholder => <Input type="password" onChange={onPasswordChangeHandler} placeholder={placeholder} name="password2" />
          }
      </FormattedMessage>
      <Button onClick={onResetPasswordHandler} className={classes.button}>
        <FormattedMessage id="reset_password" />
      </Button>
    </FormBody>
  );
  return (
    <Wrapper>
      {
        spinner
      }
      <Wrapper1>
        <Typography align="center" className={classes.title}>
          <FormattedMessage id="reset_password" />
        </Typography>
        <Wrapper2>
          {
            formContent
          }
          <FormFooter>
            <Typography className={classes.formFooter}>
              <FormattedMessage id="signIn_formFooter_1" />
              <Link style={{ fontWeight: 'bold' }} to="/signUp">
                <FormattedMessage id="signIn_formFooter_2" />
              </Link>
              <FormattedMessage id="signIn_formFooter_3" />
            </Typography>
          </FormFooter>
        </Wrapper2>
      </Wrapper1>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 884px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper1 = styled.div`
    background: transparent;
    width: 43%
`;

const Wrapper2 = styled.div`
    background: transparent;
`;
const FormBody = styled.div`
    width: 100%;
    height: 412px;
    background-color: ${props => props.theme.palette.background.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const FormFooter = styled.div`
    width: 100%;
    height: 92.8px;
    background-color: ${props => props.theme.palette.background.navy}
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Input = styled.input`
    width: 82%;
    height: 60.8px;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px
    font-family: ${props => props.theme.fontFamily.primary};
    font-weight: 100;
`;

const mapStateToProps = state => ({
  language: state.language.language,
});
ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  onPasswordChangeHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onResetPasswordHandler: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(withStyles(styles)(ResetPassword));
