const enUS = {
  eventbrite_register: 'Register',
  nav_search: 'SEARCH',
  nav_event: 'EVENTS',
  about_us: 'ABOUT US',
  nav_contact: 'CONTACT',
  nav_coupon: 'COUPONS',
  nav_login: 'LOGIN',
  nav_logout: 'LOGOUT',
  nav_signUp: 'SIGN UP',
  nav_welcome: 'Welcome!',
  banner_title: 'Mentors, One Stop Youth Education Solution Provider with Online Courses, Schools Information with Rating, Professional Services and Offline Events, to Meet All the Education Needs of Parents and Students in an Easier and Most Affordable Way !',
  banner_subTitle: 'Multi-Functional Searching    Educational Providers\' Information    Review & Rating   Offline Events   Exclusive Coupons',
  banner_learnMore: 'Learn More',
  banner_placeHolder: 'piano/math/basketball',
  searchBar_search: 'Search',
  academy: 'Academy',
  art: 'Art',
  sport: 'Sport',
  private_school: 'Private School',
  more: 'More',
  music: 'Music',
  integrated_school: 'Integrated School',
  summer_camp: 'Summer Camp',
  events_title: 'Mentors Events',
  coupons_title: 'Coupons For Members',
  coupons_more: '+ More',
  testimony_title: 'Testimony',
  partners_title: 'Our Partners',
  bottomBanner_contactUs: 'CONTACT',
  bottomBanner_signUp: 'SIGN UP',
  signIn_signIn: 'Sign In',
  signIn_formFooter_1: 'Don’t have an account yet? ',
  signIn_formFooter_2: 'Sign up ',
  signIn_formFooter_3: 'now !',
  signIn_login: 'Login',
  signIn_email: 'Email',
  signIn_password: 'Password',
  signIn_forgetPassword: 'Forget Password',
  signIn_sendEmail: 'Send Email To Reset Password',
  signIn_success_title: 'Login Success',
  signIn_fail_message: 'Email or password error',
  signIn_password_email_must_not_empty: 'Email or password must not be empty',
  signUp_signInNow: 'Sign Up Now',
  signUp_email: 'Email*',
  signUp_password: 'Password*',
  signUp_repeatPassword: 'Repeat Password*',
  signUp_userName: 'User Name*',
  signUp_zipCode: 'Zip Code',
  signUp_residence: 'Residence City',
  signUp_relation: 'Relation between you and your children',
  signUp_letUsLearnMoreAboutYouAndYourChildren: 'Let us learn more about you and your children*',
  signUp_childrenInformation: 'Children Information： ',
  signUp_moreChildren: '+ more children',
  signUp_removeChildren: '- remove children',

  signUp_moreInfoBtn: '+ show optional info',
  signUp_lessInfoBtn: '- skip optional info',

  signUp_familyIncome: 'Family Income (optional) ',
  signUp_gender: 'gender ',
  signUp_birthyear: 'birthyear ',
  signUp_please_select_child_info_message: 'Please select children\'s birth year and gender',
  signUp_please_select_child_info_title: 'Sign Up Fail',
  signUp_success_title: 'Congratulations on your successful registration',
  signUp_success_message: ' After becoming a member of Mentors, you can check all the parents\' reviews on the educational institutions, start your own rating and reviewing to them, as well as enjoy all kinds of coupons on Mentors website. I wish you a pleasant experience on Mentors!',
  signUp_fail_message: 'Sign up failed, please try again',
  signUp_languagePrefer: 'Language',
  chinese: 'Chinese',
  english: 'English',
  forget_password_fail: 'Reset Password Fail',
  forget_password_success: 'Successfully sent an email to you',
  reset_password_success: 'Successfully reset password',
  reset_password_fail: 'Something goes wrong, please try again',
  dialog_fail_title: 'Fail',
  dialog_success_title: 'Success',
  login_to_see_more: 'Login To See More',
  distance: 'Distance',
  rating: 'Rating',
  category: 'Category',
  one_plus_star: '1+ star',
  two_plus_stars: '2+ stars',
  three_plus_stars: '3+ stars',
  four_plus_stars: '4+ stars',
  five_plus_stars: '5 stars',
  five_km: '5 km',
  ten_km: '10 km',
  twenty_km: '20 km',
  schoolDetail_AboutUs: 'About Us',
  schoolDetail_OurTeacher: 'Our Teachers',
  schoolDetail_OurClass: 'Our Class/Program',
  schoolDetail_Price: 'Price',
  rating_overall: 'Overall',
  rating_studentRating: 'Student Rating',
  rating_professionalism: 'Professionalism',
  rating_environmentAndEquipment: 'Environment & Equipment',
  rating_customerService: 'Customer Service',
  resultListing_notSchoolFound: 'No School Found',
  reset_password: 'Reset Password',
  the_best_choice_for_your_kids: 'The Best Choice For Your Kids',
  about_us_description: 'MENTORS, A Complete Online Searching, Reviewing and Rating Platform Combined with Exciting Offline Events to Make All the Parents\' Lives of Finding Suitable and Trusted Educational and Extracurricular Program Providers for Their Children Much Easier and More Cost-Effective! ',
  about_us_sub_description: 'We offer:',
  about_us_sub_description_bottom: 'Share Your Education Philosophy and Let Us Grow Together…',
  about_us_point_1: 'The most comprehensive educational Providers\' information;',
  about_us_point_2: 'The most authentic parents\' feedback;',
  about_us_point_3: 'The most diversified offline events;',
  about_us_point_4: 'The most valuable coupons;',
  about_us_point_5: 'The most professional customer services!',
  couponPage_coupon: 'COUPONS',
  couponPage_title1: 'After School Educational Program',
  couponPage_title2: 'Outdoor Activities',
  couponPage_title3: 'Equipments',
  eventPage_events: 'EVENTS',
  eventPage_subtitle: 'Upcoming Events',
  eventPage_previous_events: 'Previous Events',
  option_please_select: 'Choose Here',
  gender_male: 'M',
  gender_female: 'F',
  writeReview_title: 'School Rating & Review',
  writeReview_textarea_placeHolder: 'Let us know your experience',
  writeReview_submit: 'Done',
  writeReview_success: 'Submit review success',
  writeReview_fail: 'Submit review failed',
  link_to_website: 'Link to website',
  reviews: 'Mentors Reviews',
  write_review: 'Write a Review',
  login_to_write_review: 'Log in to Review',
  read_more: 'Read More',
  filter: 'filter',
  all: 'All',
  one_star: '1 Stars',
  two_stars: '2 Stars',
  three_stars: '3 Stars',
  four_stars: '4 Stars',
  five_stars: '5 Stars',
  review_and_rating: 'School Rating & Review',
  photos: 'Photos',
  vip_promotion_free_member_benefits: 'Free Member\'s Benefits:',
  vip_promotion_free_member_benefits_line1: 'Comprehensive Education Providers\' Information;',
  vip_promotion_free_member_benefits_line2: 'Online Review and Rating;',
  vip_promotion_free_member_benefits_line3: 'Multi-Functional Searching;',
  vip_promotion_free_member_benefits_line4: 'Offline Events: Education Exhibitions, Seminars, Carnivals and More;',
  vip_promotion_free_member_benefits_line5: 'Exclusive Promotions and Coupons!',
  vip_promotion_free_member_benefits_line6: 'Special Offer and Free Trial',
  vip_promotion_free_member_benefits_line7: 'Merchants, and Amusement Parks Discount Information',
  vip_promotion_vip_benefits: 'VIP Member\'s Benefits:',
  vip_promotion_vip_benefits_line1: 'All benefits of Registered Members;',
  vip_promotion_vip_benefits_line2: 'Free Participation in All Offline Events;',
  vip_promotion_vip_benefits_line3: 'Limited Special Offers and Extra Discounted Coupons (VIP Exclusive);',
  vip_promotion_vip_benefits_line4: 'Free One-to-One Consultation Service in the First 60 Minutes!',
  vip_promotion_vip_benefits_line5: 'Free One-to-One Consultation in the First 30 Minutes',
  vip_promotion_contact_us_1: 'Contact us and become our VIP member today!',
  vip_promotion_contact_us_2: 'phone: 647.968.8618 email: mentors@mentorserv.com',
  page_not_found: 'Page Not Found',
  server_error: 'Oops, it is a server error',
  back_to_home_page: 'Go back to homepage',
};
export default enUS;
