import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import background from './imgs/background.png';

const styles = theme => ({
  button: {
    borderRadius: '50px',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
});


function BottomBanner(props) {
  const { classes, onContactUsHandler, onSignUpHandler } = props;
  return (
    <Wrapper>
      <Wrapper1>
        <Button variant="outlined" size="large" className={classes.button} onClick={onContactUsHandler}>
          <FormattedMessage id="bottomBanner_contactUs" />
        </Button>
        <Button variant="outlined" size="large" className={classes.button} onClick={onSignUpHandler}>
          <FormattedMessage id="bottomBanner_signUp" />
        </Button>
      </Wrapper1>
    </Wrapper>
  );
}
BottomBanner.propTypes = {
  onContactUsHandler: PropTypes.func.isRequired,
  onSignUpHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
const Wrapper = styled.div`
    height: 226px;
    width: 100%;
    background-image: url(\"${background}\");
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Wrapper1 = styled.div`
    display: flex;
    justify-content: space-around;
    width:30%;
`;

export default withStyles(styles)(BottomBanner);
