import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { injectIntl } from 'react-intl';
import SignUpComponent from './SignUp/SignUp';
import ChildInfo from './ChildInfo/ChildInfo';
import { login, setError, signUp } from '../../actions/user';
import { openDialog } from '../../actions/ui/dialog';
import { jumpTo } from '../../utils/route';

class SignUpContainer extends React.Component {
  constructor(props) {
    super(props);
    const { language } = this.props;
    this.state = {
      email: '',
      password: '',
      repeatPassword: '',
      userName: '',
      children: [
        {
          gender: '',
          birthyear: '',
        },
      ],
      familyIncome: '',
      zipCode: '',
      residence: '',
      relation: '',
      prefLanguage: language === 'zh-hans' ? 'CHN' : 'ENG',
    };
  }

  onInputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onAddChildrenHandler = () => {
    const { children } = this.state;
    const childrenCopy = [...children];
    if (childrenCopy.length < 4) {
      childrenCopy.push({
        birthyear: '',
        gender: '',
      });
      this.setState({
        children: childrenCopy,
      });
    }
  };

  onRemoveChildrenHandler = () => {
    const { children } = this.state;
    const childrenCopy = [...children];
    childrenCopy.pop();
    this.setState({
      children: childrenCopy,
    });
  };

  onBirthyearChangeHandler = (index, e) => {
    const { children } = this.state;
    const childrenCopy = [...children];
    childrenCopy[index].birthyear = e.target.value;
    this.setState({
      children: childrenCopy,
    });
  };

  onGenderChangeHandler = (index, e) => {
    const { children } = this.state;
    const childrenCopy = [...children];
    childrenCopy[index].gender = e.target.value;
    this.setState({
      children: childrenCopy,
    });
  };

  onFamilyIncomeChangeHandler = (e) => {
    this.setState({
      familyIncome: e.target.value,
    });
  };

  onLanguageChangeHandler = (e) => {
    this.setState({
      prefLanguage: e.target.value,
    });
  };

  onSignUpHandler = () => {
    const {
      children,
      familyIncome,
      email,
      userName,
      password,
      repeatPassword,
      residence,
      zipCode,
      relation,
      prefLanguage,
    } = this.state;
    const {
      register,
      history,
      location,
      language,
      openDialog,
      setErrors,
      intl: { formatMessage },
      signIn,
    } = this.props;
    // Form validation
    let isValid = true; // a flag indicate whether form input is valid or not
    let message = 'This field is required';
    if (language === 'zh-hans') {
      message = '此项不能为空';
    }
    const constraints = {
      email: {
        presence: {
          allowEmpty: false,
          message,
        },
      },
      password1: {
        presence: {
          allowEmpty: false,
          message,
        },
      },
      first_name: {
        presence: {
          allowEmpty: false,
          message,
        },
      },
      residential_city: {
        presence: {
          allowEmpty: true,
          message,
        },
      },
      relationship: {
        presence: {
          allowEmpty: true,
          message,
        },
      },
    };
    validate.formatters.custom = errors => errors.map(error => ({
      field: error.attribute,
      message: error.error,
    }));
    const errorObj = validate({
      email,
      password1: password,
      first_name: userName,
      residential_city: residence,
      relationship: relation,
    }, constraints, { format: 'custom', fullMessages: false });
    setErrors(errorObj);
    if (errorObj !== undefined) {
      isValid = false;
    }
    // Check if there is at least only child's info
    // for (let i = 0; i < children.length; i++) {
    //   if (children[i].birthyear === '' || children[i].gender === '') {
    //     openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'signUp_please_select_child_info_message' }));
    //     isValid = false;
    //     break;
    //   }
    // }
    // If passed the form validation, make an ajax call
    if (isValid === true) {
      //  Prepare userBio
      let userBio = '';
      const childrenCopy = [...children];
      childrenCopy.forEach((child, index) => {
        userBio += `Child#${index + 1}: birthyear ${childrenCopy[index].birthyear} gender ${childrenCopy[index].gender};\n`;
      });
      userBio = `${userBio}familyIncome: ${familyIncome}`;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('first_name', userName);
      formData.append('password1', password);
      formData.append('user_bio', userBio);
      formData.append('residential_city', residence);
      formData.append('postal_code', zipCode);
      formData.append('relationship', relation);
      formData.append('language', prefLanguage);
      const onSuccess = () => {
        openDialog(formatMessage({ id: 'signUp_success_title' }), formatMessage({ id: 'signUp_success_message' }));
        signIn({ email, password });
        jumpTo('/', history, location);
      };
      const onFail = () => {
        openDialog(formatMessage({ id: 'dialog_fail_title' }), formatMessage({ id: 'signUp_fail_message' }));
      };
      register(formData).then(onSuccess, onFail);
    }
  };


  render() {
    const { children, prefLanguage } = this.state;
    const { isLoading, errors } = this.props;
    const childrenInfos = children.map((child, index) => (
      <ChildInfo
        onGenderChangeHandler={this.onGenderChangeHandler}
        onBirthyearChangeHandler={this.onBirthyearChangeHandler}
        key={uuidv1()}
        index={index}
        genderValue={child.gender}
        birthyearValue={child.birthyear}
      />
    ));


    return (
      <div>
        <SignUpComponent
          onInputChangeHandler={this.onInputChangeHandler}
          onAddChildrenHandler={this.onAddChildrenHandler}
          onRemoveChildrenHandler={this.onRemoveChildrenHandler}
          onFamilyIncomeChangeHandler={this.onFamilyIncomeChangeHandler}
          onSignUpHandler={this.onSignUpHandler}
          onLanguageChangeHandler={this.onLanguageChangeHandler}
          prefLanguage={prefLanguage}
          childrenInfos={childrenInfos}
          isLoading={isLoading}
          errors={errors}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.registration.isLoading,
  errors: state.registration.errors,
  language: state.language.language,
});
const mapDispatchToProps = dispatch => ({
  register: (userInfo, onSuccess, onFail) => dispatch(signUp(userInfo, onSuccess, onFail)),
  setErrors: error => dispatch(setError(error)),
  openDialog: (title, message) => dispatch(openDialog(title, message)),
  signIn: (user, onSuccessCallBack, onFailCallBack) => dispatch(login(user, onSuccessCallBack, onFailCallBack)),
});

SignUpContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  setErrors: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(SignUpContainer)));
