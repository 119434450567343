import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.background.secondary,
    flexGrow: '1',
    padding: 0,
    borderRadius: '0px 33.5px 33.5px 0px',
    color: theme.palette.textPrimary.main,
    textTransform: 'none',
    fontFamily: theme.fontFamily.primary,
    fontSize: '1.25rem',
    fontWeight: 400,
    border: '1px #646464 solid',
    borderLeft: 'none',
  },
});

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount(): void {
    document.addEventListener('keyup', this.keyUpListener);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keyup', this.keyUpListener);
  }

  keyUpListener = (e) => {
    const { onSearchHandler } = this.props;
    if (e.keyCode === 13 && this.inputRef.current.isSameNode(document.activeElement)) {
      onSearchHandler();
    }
  };

  render() {
    const {
      classes,
      backgroundColor,
      onInputChange,
      value,
      onSearchHandler,
      inputBackground,
      inputPlaceHolderColor,
    } = this.props;
    return (
      <Wrapper backgroundColor={backgroundColor}>
        <BarWrapper>
          <FormattedMessage id="banner_placeHolder">
            {
              placeholder => (
                <InputBase
                  ref={this.inputRef}
                  placeholder={placeholder}
                  onChange={onInputChange}
                  value={value}
                  backgroundColor={inputBackground}
                  placeHolderColor={inputPlaceHolderColor}
                />
              )
            }
          </FormattedMessage>
          <Button className={classes.button} onClick={onSearchHandler}><FormattedMessage id="searchBar_search" /></Button>
        </BarWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
    width: 100%;
    height: 100px;
    background-color: ${props => props.backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center
`;
const BarWrapper = styled.div`
    width: 50%;
    display: flex;
    height: 56px;
`;
const InputBase = styled.input`
    background-color: ${props => props.backgroundColor};
    border-radius: 33.5px 0px 0px 33.5px;
    border: 1px #646464 solid;
    border-right: none;
    padding: 5px 20px;
    flex-grow: 7;
    font-family: ${props => props.theme.fontFamily.primary};
    font-weight: 100;
    box-shadow: none;
     ::placeholder{
    color:  ${props => props.placeHolderColor};
    }
    :focus{
    outline: none;
  }
`;
SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  value: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSearchHandler: PropTypes.func.isRequired,
  inputBackground: PropTypes.string.isRequired,
  inputPlaceHolderColor: PropTypes.string.isRequired,
};
SearchBar.defaultProps = {
  backgroundColor: 'transparent',
};

export default withStyles(styles)(SearchBar);
