import React from 'react';
import uuid from 'uuid';

export default function UserIcon() {
  return (
    <i
      key={uuid()}
      className="fas fa-map-marker-alt fa-3x"
      style={{ color: 'red' }}
    />
  );
}
