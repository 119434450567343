import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

function RatingBar(props){
  const {
    fiveStarCount,
    fourStarCount,
    threeStarCount,
    twoStarCount,
    oneStarCount,
  } = props;
  const totalCount = fiveStarCount + fourStarCount + threeStarCount + twoStarCount + oneStarCount;
  return (
    <Wrapper>
      <Row>
        <FormattedMessage id="five_stars" />
        <StarBar>
          <BarFiller percentage={totalCount !== 0?((fiveStarCount / totalCount).toFixed(2) * 100): 0} />
        </StarBar>
        <span>{fiveStarCount}</span>
      </Row>
      <Row>
        <FormattedMessage id="four_stars" />
        <StarBar>
          <BarFiller percentage={totalCount !== 0?((fourStarCount / totalCount).toFixed(2) * 100): 0} />
        </StarBar>
        <span>{fourStarCount}</span>
      </Row>
      <Row>
        <FormattedMessage id="three_stars" />
        <StarBar>
          <BarFiller percentage={totalCount !==0?((threeStarCount / totalCount).toFixed(2) * 100): 0} />
        </StarBar>
        <span>{threeStarCount}</span>
      </Row>
      <Row>
        <FormattedMessage id="two_stars" />
        <StarBar>
          <BarFiller percentage={totalCount !==0?((twoStarCount / totalCount).toFixed(2) * 100):0} />
        </StarBar>
        <span>{twoStarCount}</span>
      </Row>
      <Row>
        <FormattedMessage id="one_star" />
        <StarBar>
          <BarFiller percentage={totalCount !==0?((oneStarCount / totalCount).toFixed(2) * 100):0} />
        </StarBar>
        <span>{oneStarCount}</span>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 120px;
`;
const StarBar = styled.div`
  height: 20px;
  background-color: #f1f1f1;
  flex-grow: 1;
  margin: 0 10px;
`;
const Row = styled.div`
  display: flex;
`;
const BarFiller = styled.div`
  width: ${props => `${props.percentage}%`};
  background: ${props => props.theme.palette.background.primary};
  height: 100%;
`;
RatingBar.propTypes = {
  fiveStarCount: PropTypes.number.isRequired,
  fourStarCount: PropTypes.number.isRequired,
  threeStarCount: PropTypes.number.isRequired,
  twoStarCount: PropTypes.number.isRequired,
  oneStarCount: PropTypes.number.isRequired,
};
export default RatingBar;