import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Coupons from './Coupons';

function CouponsContainer(props) {
  const { language } = props;
  return (
    <Coupons
      language={language}
    />
  );
}

const mapStateToProps = state => ({
  language: state.language.language,
});
CouponsContainer.propTypes = {
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(CouponsContainer);
