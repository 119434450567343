import React from 'react';
import styled from 'styled-components';
import img1 from './imgs/1.png';
import img2 from './imgs/2.png';
import img3 from './imgs/3.png';
import img4 from './imgs/4.png';

function PhotoShow() {
  return (
    <Wrapper>
      <Img src={img1} alt="img1" />
      <Img src={img2} alt="img2" />
      <Img src={img3} alt="img3" />
      <Img src={img4} alt="img4" />
    </Wrapper>
  );
}
const Img = styled.img`
  width: 25%;
`;
const Wrapper = styled.div`
   width: 100%;
`;
export default PhotoShow;
