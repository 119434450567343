import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import uuid from 'uuid';
const styles = theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '100',
  },
});
function ChildInfo(props) {
  const {
    classes,
    onBirthyearChangeHandler,
    onGenderChangeHandler,
    index,
    birthyearValue,
    genderValue,
  } = props;
  const year = new Date().getFullYear();
  const options = [];
  for (let i = 0; i < 26; i++) {
    options.push(<option value={year - i} key={uuid()}>{year - i}</option>);
  }
  return (
    <Typography className={classes.text}>
      <FormattedMessage id="signUp_birthyear" />
      <Select onChange={e => onBirthyearChangeHandler(index, e)} value={birthyearValue}>
        <FormattedMessage id="option_please_select">
          { msg => <option value="" disabled hidden>{msg}</option>}
        </FormattedMessage>
        {
          options
        }
      </Select>
      <FormattedMessage id="signUp_gender" />
      <Select onChange={e => onGenderChangeHandler(index, e)} value={genderValue}>
        <FormattedMessage id="option_please_select">
          { msg => <option value="" disabled hidden>{msg}</option>}
        </FormattedMessage>
        <FormattedMessage id="gender_male">
          { msg => <option value="M">{msg}</option> }
        </FormattedMessage>
        <FormattedMessage id="gender_female">
          { msg => <option value="F">{msg}</option>}
        </FormattedMessage>
      </Select>
    </Typography>
  );
}
const Select = styled.select`
    height: 48px;
    padding: 5px;
    background-color: white;
`;
ChildInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  onBirthyearChangeHandler: PropTypes.func.isRequired,
  onGenderChangeHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  genderValue: PropTypes.string.isRequired,
  birthyearValue: PropTypes.string.isRequired,
};
export default withStyles(styles)(ChildInfo);
