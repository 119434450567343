import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import star1 from '../../../../assets/imgs/rating/1stars.png';
import star2 from '../../../../assets/imgs/rating/2stars.png';
import star3 from '../../../../assets/imgs/rating/3stars.png';
import star4 from '../../../../assets/imgs/rating/4stars.png';
import star5 from '../../../../assets/imgs/rating/5stars.png';
import Rating from '../../../../components/Rating';

class ReviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readMoreState: {
        shouldShow: false,
        isOpen: false,
      },
    };
    this.onReadMoreClicked = this.onReadMoreClicked.bind(this);
  }

  componentDidMount() {
    const { review } = this.props;
    if (review.message.length > 300) {
      this.setState({
        readMoreState: {
          shouldShow: true,
          isOpen: false,
        },
      });
    }
  }

  onReadMoreClicked() {
    const { readMoreState } = this.state;
    this.setState({
      readMoreState: {
        shouldShow: true,
        isOpen: !readMoreState.isOpen,
      },
    });
  }
/* eslint-disable */
  render() {
    const { review } = this.props;
    const { readMoreState } = this.state;
    const {
      average_rating,
      date_posted,
      student_rating,
      profession_rating,
      service_rating,
      env_rating,
    } = review;
    let { message } = review;
    let readMore;
    if(readMoreState.shouldShow) {
      if(!readMoreState.isOpen) {
        readMore = <Button onClick={this.onReadMoreClicked}>Read More +</Button>
      }else{
        readMore = <Button onClick={this.onReadMoreClicked}>Read More -</Button>
      }
    }else{
      readMore = null;
    }
    let rateImgURL;
    switch (average_rating) {
      case 1: {
        rateImgURL = star1;
        break;
      }
      case 2: {
        rateImgURL = star2;
        break;
      }
      case 3: {
        rateImgURL = star3;
        break;
      }
      case 4: {
        rateImgURL = star4;
        break;
      }
      case 5: {
        rateImgURL = star5;
        break;
      }
      default: {
        rateImgURL = star1;
      }
    }
    message = !readMoreState.isOpen? message.substring(0,299): message;

    // Parse Date
    const dateParsed = Date.parse(date_posted);
    const date = new Date(dateParsed);
    const dateString = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    let authorName = review.author !== null ? review.author.first_name : "ANONYMOUS";

    return (
      <Wrapper>
        <WrapperLeft>
          {/*<Avatar src={defaultUserAvatar} />*/}
          <Avatar>{authorName[0]}</Avatar>
          <Name>{ authorName }</Name>
        </WrapperLeft>
        <WrapperRight>
          <Row1>
            <Rating
              average_rating={average_rating}
              student_rating={student_rating}
              profession_rating={profession_rating}
              service_rating={service_rating}
              env_rating={env_rating}
            >
            </Rating>
            <Time>{dateString}</Time>
          </Row1>
          <ReviewText>
            {message}
          </ReviewText>
          <div>
            {readMore}
          </div>
        </WrapperRight>
      </Wrapper>
    );
  }
}

const Name = styled.div`
  word-break: break-word;
`;
const ReviewText = styled.div`
  white-space: pre-wrap;
`;
const Rate = styled.div`
  width: 100%;
  position: relative;
  height: 63px;
  margin: 10px 0;
`;
const Row1 = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Time = styled.div`
  display: inline-box;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  padding: 25px 0;
`;
const WrapperLeft = styled.div`
  text-align: center;
  padding: 15px;
  width: 120px;
`;
const WrapperRight = styled.div`
  flex-grow: 1;
  padding: 15px;
`;
// background-image: ${props => `url("${props.src}")`};
// background-size: cover;
// background-position: center;
const Avatar = styled.div`
  color: ${props => props.theme.palette.textPrimary.main};
  background-color: ${props => props.theme.palette.background.secondary};
  width:90px;
  height:90px;
  border-radius:50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const Button = styled.button`
  margin: 12px 0;
  padding: 0;
  background: transparent;
  border: none;
  outline:none;
  :focus{
    outline: none;
  }
  color: ${props => props.theme.palette.background.orange};
`;
ReviewCard.propTypes = {
  review: PropTypes.object.isRequired,
};
export default withTheme(ReviewCard);
