import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid';
import Pagination from 'react-js-pagination';
import './index.css';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { getReviews } from '../../../actions/review';
import ReviewCard from './ReviewCard/ReviewCard';
import { jumpTo } from '../../../utils/route';
import Spinner from '../../../components/Spinner/Spinner';

const PAGE_SIZE = 6;
class Reviews extends React.PureComponent {
    state = {
      currentPage: 1,
      filter: '0',
    };

    componentDidMount() {
      const { slug, getReviews } = this.props;
      const { filter } = this.state;
      getReviews(slug, 1, PAGE_SIZE, filter); // eslint-disable-line
    }

  onPageChangeHandler = (selected) => {
    const { slug, getReviews } = this.props;
    const { filter } = this.state;
    this.setState({
      currentPage: selected,
    }, () => getReviews(slug, this.state.currentPage, PAGE_SIZE, filter)); // eslint-disable-line
  }

  onFilterButtonClicked = (e) => {
    const { slug, getReviews } = this.props;
    const { target } = e;
    this.setState({
      currentPage: 1,
      filter: target.value,
    }, () => getReviews(slug, this.state.currentPage, PAGE_SIZE, this.state.filter)); // eslint-disable-line
  };

  signInHandler = () => {
    const { history, location } = this.props;
    jumpTo('/signIn', history, location);
  };

  render() {
    const {
      review, isLoading, user,
    } = this.props;
    const { currentPage, filter } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    const pagination = review.count === 0 ? null : (
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={PAGE_SIZE}
        totalItemsCount={review.count}
        pageRangeDisplayed={3}
        onChange={this.onPageChangeHandler}
        itemClass="paginationList"
        activeLinkClass="activeLink"
      />
    );
    return (
      <div>
        <div>
          <FilterButton style={{ cursor: 'auto', border: '0.5px solid #1B1B1B' }}><FormattedMessage id="filter" /></FilterButton>
          {
            filter === '0'
              ? (
                <FormattedMessage id="all">
                  {msg => <FilterButtonClicked value={0} onClick={this.onFilterButtonClicked}>{msg}</FilterButtonClicked>}
                </FormattedMessage>
              )
              : (
                <FormattedMessage id="all">
                  {msg => <FilterButton value={0} onClick={this.onFilterButtonClicked}>{msg}</FilterButton>}
                </FormattedMessage>
              )
          }
          {
            filter === '5'
              ? (
                <FormattedMessage id="five_stars">
                  {msg => <FilterButtonClicked value={5} onClick={this.onFilterButtonClicked}>{msg}</FilterButtonClicked>}
                </FormattedMessage>
              )
              : (
                <FormattedMessage id="five_stars">
                  {msg => <FilterButton value={5} onClick={this.onFilterButtonClicked}>{msg}</FilterButton>}
                </FormattedMessage>
              )
          }
          {
            filter === '4'
              ? (
                <FormattedMessage id="four_stars">
                  {msg => <FilterButtonClicked value={4} onClick={this.onFilterButtonClicked}>{msg}</FilterButtonClicked>}
                </FormattedMessage>
              )
              : (
                <FormattedMessage id="four_stars">
                  {msg => <FilterButton value={4} onClick={this.onFilterButtonClicked}>{msg}</FilterButton>}
                </FormattedMessage>
              )
          }
          {
            filter === '3'
              ? (
                <FormattedMessage id="three_stars">
                  {msg => <FilterButtonClicked value={3} onClick={this.onFilterButtonClicked}>{msg}</FilterButtonClicked>}
                </FormattedMessage>
              )
              : (
                <FormattedMessage id="three_stars">
                  {msg => <FilterButton value={3} onClick={this.onFilterButtonClicked}>{msg}</FilterButton>}
                </FormattedMessage>
              )
          }
          {
            filter === '2'
              ? (
                <FormattedMessage id="two_stars">
                  {msg => <FilterButtonClicked value={2} onClick={this.onFilterButtonClicked}>{msg}</FilterButtonClicked>}
                </FormattedMessage>
              )
              : (
                <FormattedMessage id="two_stars">
                  {msg => <FilterButton value={2} onClick={this.onFilterButtonClicked}>{msg}</FilterButton>}
                </FormattedMessage>
              )
          }
          {
            filter === '1'
              ? (
                <FormattedMessage id="one_star">
                  {msg => <FilterButtonClicked value={1} onClick={this.onFilterButtonClicked}>{msg}</FilterButtonClicked>}
                </FormattedMessage>
              )
              : (
                <FormattedMessage id="one_star">
                  {msg => <FilterButton value={1} onClick={this.onFilterButtonClicked}>{msg}</FilterButton>}
                </FormattedMessage>
              )
          }
        </div>
        <div style={{ minHeight: '229px' }}>
          {
              !_.isEmpty(review.results) && review.results.map(rev => (
                <div key={uuid()}>
                  <ReviewCard review={rev} />
                </div>
              ))
          }
          <PaginateWrapper>
            {
              _.isEmpty(user)
                ? (
                  <Button onClick={this.signInHandler}>
                    <FormattedMessage id="login_to_see_more" />
                  </Button>
                )
                : pagination
            }
          </PaginateWrapper>
        </div>
      </div>
    );
  }
}
const PaginateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const FilterButton = styled.button`
  font-size: 0.75rem;
  border-radius: 13.5px;;
  background: transparent;
  border: 0.5px solid #797979;
  margin: 5px;
  padding: 4.5px 14px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
  :hover{
    cursor: pointer;
  }
  :focus{
    outline: none;
  }
`;
const FilterButtonClicked = styled.button`
  font-size: 0.75rem;
  border-radius: 13.5px;
  border: 0.5px solid #05B8FF;
  margin: 5px;
  padding: 4.5px 14px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 100;
  :hover{
    cursor: pointer;
  }
  :focus{
    outline: none;
  }
  outline: none;
  color: #05B8FF;
  background: ${props => props.theme.palette.background.blue};
`;
const Button = styled.button`
    background-color: ${props => props.theme.palette.background.secondary};
    color: ${props => props.theme.palette.textPrimary.main};
    padding: 12px;
    margin: 12px 0;
    border: none;
    border-radius: 4px;
`;
Reviews.propTypes = {
  slug: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  review: PropTypes.object.isRequired,
  getReviews: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  isLoading: state.review.isLoading,
  review: state.review.review,
  user: state.authentication.user,
});
const mapDispatchToProps = {
  getReviews,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(Reviews)));
