import { CHANGE_DISTANCE_FILTER, CHANGE_STAR_FILTER, CHANGE_CATEGORY_FILTER } from './actionTypes';

export const changeDistanceFilter = distance => ({
  type: CHANGE_DISTANCE_FILTER,
  payload: distance,
});
export const changeStarFilter = starCount => ({
  type: CHANGE_STAR_FILTER,
  payload: starCount,
});
export const changeCategoryFilter = category => ({
  type: CHANGE_CATEGORY_FILTER,
  payload: category,
});
