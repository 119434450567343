import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import errorImg from '../imgs/mentors-icon-whtie.png';

function ServerError() {
  return (
    <Wrapper>
      <Content>
        <Img><img src={errorImg} alt="" /></Img>
        <div><FormattedMessage id="server_error" /></div>
        <div>
          <Link to={{
            pathname: '/',
            replace: true,
          }}
          >
            <TextWrapper><FormattedMessage id="back_to_home_page" /></TextWrapper>
          </Link>
        </div>
      </Content>
    </Wrapper>
  );
}
const Img = styled.div`
  margin-bottom: 20px;
`;
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.palette.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  text-align: center;
  color: ${props => props.theme.palette.text.primary};
  font-size: 30px;
  line-height: 45px;
`;
const TextWrapper = styled.div`
  font-size: 16px;
  color: ${props => props.theme.palette.text.primary};
`;
export default ServerError;
