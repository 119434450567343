import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Rating from '../../../components/Rating';
import { jumpTo } from '../../../utils/route';

function SchoolIconPanel(props) {
  /* eslint-disable */
  const onSchoolListClickedHandler = (slug) =>{
    const { history } = props;
    jumpTo(`/school-detail/${slug}`, history, location);
  };
  const {
    slug,
    name,
    average_rating,
    student_rating,
    profession_rating,
    service_rating,
    env_rating,
    reviewCount, // eslint-disable-line
    logo,
  } = props;
  let { phone_number, address } = props;
  return (
    <Wrapper onClick={() => onSchoolListClickedHandler(slug)}>
      <CenterWrapper>
        <SchoolImg src={logo} />
        <SchoolDetail>
          <SchoolName>{name}</SchoolName>
          <Rating
            reviewCount={reviewCount}
            average_rating={average_rating}
            student_rating={student_rating}
            profession_rating={profession_rating}
            service_rating={service_rating}
            env_rating={env_rating}
          />
          <Phone>{phone_number}</Phone>
          <Address>{address}</Address>
        </SchoolDetail>
      </CenterWrapper>
    </Wrapper>
  );
}
const Phone = styled.div`
  font-size: 0.8125rem;
  font-family: ${props => props.theme.fontFamily.primary}
  font-weight: 100;
`;
const Address = styled.div`
  font-size: 0.8125rem;
  font-family: ${props => props.theme.fontFamily.primary}
  font-weight: 100;
`;
const SchoolName = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
  margin-bottom: 8px;
  font-size: 1.25rem;
`;
const Wrapper = styled.div`
  width: 630px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 10px;
`;
const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
`;
const SchoolImg = styled.div`
  background-image: ${props => `url("${props.src}")`}, ${props => `url("${props.fallbackSrc}")`};
  background-size: cover;
  background-position: center;
  height: 189px;
  width: 189px;
`;
const SchoolDetail = styled.div`
  margin-left: 20px;
  width: 400px;
`;
SchoolIconPanel.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  average_rating: PropTypes.number.isRequired,
  student_rating: PropTypes.number.isRequired,
  profession_rating: PropTypes.number.isRequired,
  service_rating: PropTypes.number.isRequired,
  env_rating: PropTypes.number.isRequired,
  reviewCount: PropTypes.number.isRequired,
  phone_number: PropTypes.string,
  address: PropTypes.string,
  logo: PropTypes.string,
  history: PropTypes.object.isRequired,
};
SchoolIconPanel.defaultProps = {
  phone_number: null,
  address: null,
  logo: null,
};
export default withRouter(SchoolIconPanel);
