import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PAGE_NOT_FOUND, SERVER_ERROR } from './errorTypes';
import PageNotFound from './PageNotFound/PageNotFound';
import ServerError from './ServerError/ServerError';

function ErrorPage(props) {
  const { type } = props;
  switch (type) {
    case PAGE_NOT_FOUND:
      return <PageNotFound />;
    case SERVER_ERROR:
      return <ServerError />;
    default:
      return null;
  }
}

ErrorPage.propTypes = {
  type: PropTypes.oneOf([PAGE_NOT_FOUND, SERVER_ERROR]).isRequired,
};

export default ErrorPage;
