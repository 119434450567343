import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ad1 from './imgs/ad1.png';

function AdsSlider() {
  const onClickHandler = (e) => {
    if (!_.isEmpty(e.currentTarget.dataset.url)) {
      window.open(e.currentTarget.dataset.url, '_blank');
    }
  };
  return (
    <Wrapper>
      <div id="ads" className="carousel slide" data-ride="carousel" data-interval="false">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div>
              <Img src={ad1} data-url="https://www.centurypscanada.com" alt="ads1" onClick={onClickHandler} />
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#ads" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#ads" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </Wrapper>
  );
}
const Img = styled.img`
  width: 100%;
`;
const Wrapper = styled.div`
   width: 100%;
`;

export default AdsSlider;
