import { GET_SCHOOL_LOADING, GET_SCHOOL_FAIL, GET_SCHOOL_SUCCESS } from '../actions/actionTypes';

const initialState = {
  schools: [],
  errors: [],
  isLoading: false,
};
const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_SUCCESS: {
      return {
        schools: action.payload.data,
        errors: [],
        isLoading: false,
      };
    }
    case GET_SCHOOL_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_SCHOOL_FAIL: {
      return {
        schools: [],
        errors: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default schoolReducer;
