import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function CategoryCard(props) {
  const { children, backgroundImg, onClick, name } = props;
  return (
    <Card backgroundImg={backgroundImg} onClick={onClick} data-name={name}>
      {children}
    </Card>
  );
}
const Card = styled.div`
    width: 22%;
    height: 100%;
    background-image: ${props => `url("${props.backgroundImg}")`};
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
      cursor: pointer;
    }
`;
CategoryCard.propTypes = {
  children: PropTypes.element,
  backgroundImg: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
CategoryCard.defaultProps = {
  children: null,
};
export default CategoryCard;
