import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import p1 from './imgs/partners/partner-1.png';
import p2 from './imgs/partners/partner-2.png';
import p3 from './imgs/partners/partner-3.png';
import p4 from './imgs/partners/partner-4.png';
import p5 from './imgs/partners/partner-5.png';
import p6 from './imgs/partners/partner-6.png';
import p7 from './imgs/partners/partner-7.png';
import p8 from './imgs/partners/partner-8.png';

const styles = () => ({
  typography: {
    marginBottom: '30px',
    fontSize: '28px',
  },
});
function Partners(props) {
  const { classes } = props;
  return (
    <Wrapper>
      <Wrapper1>
        <Typography align="center" className={classes.typography} color="textSecondary">
          <FormattedMessage id="partners_title" />
        </Typography>
        <div>
          <Partner>
            <Ptn><Img src={p1} alt="partner1" /></Ptn>
            <Ptn><Img src={p2} alt="partner2" /></Ptn>
            <Ptn><Img src={p3} alt="partner3" /></Ptn>
            <Ptn><Img src={p4} alt="partner4" /></Ptn>
            <Ptn><Img src={p5} alt="partner5" /></Ptn>
            <Ptn><Img src={p6} alt="partner6" /></Ptn>
            <Ptn><Img src={p7} alt="partner7" /></Ptn>
            <Ptn><Img src={p8} alt="partner8" style={{ marginLeft: '-25px' }} /></Ptn>
          </Partner>
        </div>
      </Wrapper1>
    </Wrapper>
  );
}
const Img = styled.img`
  height: 60px;
`;

const Ptn = styled.div`
  margin-right: 50px;
`;
const Wrapper = styled.div`
    height: 210px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Wrapper1 = styled.div`
    width: 100%;
`;
const Partner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    space
    margin: 0 auto;
`;
Partners.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Partners);
