import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DiaLogComponent from './DialogComponent';
import { getCloseDialogAction } from '../../actions/ui/dialog';

// eslint-disable-next-line react/prefer-stateless-function
class Dialogs extends React.Component {
  render() {
    const {
      onCloseDialogHandler, isOpen, message, title,
    } = this.props;
    return (
      <DiaLogComponent
        onCloseHandler={onCloseDialogHandler}
        isOpen={isOpen}
        message={message}
        title={title}
      />
    );
  }
}

Dialogs.propTypes = {
  onCloseDialogHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
};
Dialogs.defaultProps = {
  title: null,
};
const mapStateToProps = state => ({
  isOpen: state.ui.dialog.isOpen,
  message: state.ui.dialog.message,
  title: state.ui.dialog.title,
});
const mapDispatchToProps = dispatch => ({
  onCloseDialogHandler: () => dispatch(getCloseDialogAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
