import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function InstagramIcon(props) {
  const onClick = () => {
    window.open(props.url);
  };
  return (
    <Button {...props} onClick={onClick}>
      <i className="fab fa-instagram" />
    </Button>
  );
}

const Button = styled.button`
  background: transparent;
  border: none;
  outline:none;
  :focus{
    outline: none;
  }
`;
InstagramIcon.propTypes = {
  url: PropTypes.string.isRequired,
};

export default InstagramIcon;
