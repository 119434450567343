export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_PENDING = 'LOG_IN_PENDING';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_PENDING = 'LOG_OUT_PENDING';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';
export const SIGN_UP_PENDING = 'SIGN_UP_PENDING';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const GET_SCHOOL_LOADING = 'GET_SCHOOL_LOADING';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_FAIL = 'GET_SCHOOL_FAIL';
export const CHANGE_SEARCH_FIELD_VALUE = 'CHANGE_SEARCH_FIELD_VALUE';
export const CHANGE_DISTANCE_FILTER = 'CHANGE_DISTANCE_FILTER';
export const CHANGE_STAR_FILTER = 'CHANGE_STAR_FILTER';
export const CHANGE_CATEGORY_FILTER = 'CHANGE_CATEGORY_FILTER';
export const GET_SCHOOL_DETAIL_SUCCESS = 'GET_SCHOOL_DETAIL_SUCCESS';
export const GET_SCHOOL_DETAIL_LOADING = 'GET_SCHOOL_DETAIL_LOADING';
export const GET_SCHOOL_DETAIL_FAIL = 'GET_SCHOOL_DETAIL_FAIL';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_LOADING = 'GET_REVIEW_LOADING';
export const GET_REVIEW_FAIL = 'GET_REVIEW_FAIL';
export const SET_USER = 'SET_USER';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const VALIDATE_INPUT_FAIL = 'VALIDATE_INPUT_FAIL';
export const SET_MAP_LOADING = 'SET_MAP_LOADING';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
