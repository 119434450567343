import {
  LOG_IN_SUCCESS,
  LOG_IN_PENDING,
  LOG_IN_FAIL,
  LOG_OUT_FAIL,
  LOG_OUT_PENDING,
  LOG_OUT_SUCCESS, SET_USER,
} from '../actions/actionTypes';
// Initialize User
const initialState = {
  user: {},
  error: [],
  isLoading: false,
};
const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case LOG_IN_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: [],
        isLoading: false,
      };
    }
    case LOG_IN_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOG_IN_FAIL: {
      return {
        ...state,
        user: {},
        error: action.payload,
        isLoading: false,
      };
    }
    case LOG_OUT_SUCCESS: {
      return {
        ...state,
        user: {},
        error: [],
        isLoading: false,
      };
    }
    case LOG_OUT_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOG_OUT_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default authenticationReducer;
