import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Events from './Events';

class EventsContainer extends Component {
  render() {
    const { history, location } = this.props;
    return (
      <Events
        history={history}
        location={location}
      />
    );
  }
}

EventsContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(EventsContainer);
