import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import img1 from './imgs/ad1.jpg';
import img2 from './imgs/ad2.jpg';
import img3 from './imgs/ad3.jpg';

function AdsBanner() {
  const onClickHandler = (e) => {
    if (!_.isEmpty(e.currentTarget.dataset.url)) {
      window.open(e.currentTarget.dataset.url, '_blank');
    }
  };
  return (
    <Wrapper>
      <Wrapper1>
        <Ad1 data-url="http://www.sunlife.ca/jane.mcmillan" onClick={onClickHandler} />
        <Ad2 data-url="https://www.pierrelaurent.com" onClick={onClickHandler} />
        <Ad3 data-url="https://dilawri.ca/" onClick={onClickHandler} />
      </Wrapper1>
    </Wrapper>
  );
}
const Wrapper = styled.div`
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper1 = styled.div`
    width: 85%;
    display: flex;
    justify-content: space-between;
`;

const Ad1 = styled.div`
  background: url(\"${img1}\");
  background-size: cover;
  width: 386px;
  height: 140px;
`;

const Ad2 = styled.div`
  background: url(\"${img2}\");
  background-size: cover;
  width: 386px;
  height: 140px; 
`;

const Ad3 = styled.div`
  background: url(\"${img3}\");
  background-size: cover;
  width: 386px;
  height: 140px; 
`;

export default AdsBanner;
