import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';
import logoText from '../../assets/imgs/mentors-logo-text.png';
import logo from '../../assets/imgs/mentors-logo.png';
import { jumpTo } from '../../utils/route';
import { logOut } from '../../actions/user';
import { toggleLanguage } from '../../actions/language';

const styles = theme => ({
  rootTransparent: {
    flexGrow: 1,
    background: 'transparent',
    position: 'fixed',
    zIndex: 2,
  },
  rootPrimaryColor: {
    flexGrow: 1,
    background: theme.palette.background.primary,
    position: 'fixed',
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  leftDiv: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '28px',
    paddingRight: '5px',
  },
  midDiv: {
    justifyContent: 'space-around',
    flexGrow: 1,
    color: theme.palette.textPrimary.main,
  },
  rightDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    justifySelf: 'flex-end',
    color: theme.palette.textPrimary.main,
  },
  toolbar: {
    padding: 0,
    background: 'transparent',
  },
  link: {
    marginLeft: '40px',
    color: 'white',
    textDecoration: 'none',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '500',
  },
  login: {
    marginRight: '10px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '500',
  },
  language: {
    fontSize: '11px',
    marginRight: '10px',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '500',
  },
  signUpButton: {
    background: theme.palette.background.secondary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '500',
    marginRight: '10px',
  },
});

class Nav extends Component {
  onLinkClicked = (e) => {
    const { history, location } = this.props;
    jumpTo(e.currentTarget.dataset.url, history, location);
  };

  onChangeLanguage = () => {
    const { toggleLanguage } = this.props;
    toggleLanguage();
    window.location.reload();
  };

  onLogOut = () => {
    const { logOut } = this.props;
    logOut();
  };

  render() {
    const { user, classes } = this.props;
    let root = classes.rootTransparent;
    if (document.documentElement.scrollTop !== 0) {
      root = classes.rootPrimaryColor;
    }
    return (
      <div
        className={classes.root}
        style={{
          transform: 'scale(1)', width: '100%', position: 'absolute', background: 'transparent', zIndex: '9999',
        }}
      >
        <AppBar position="static" className={root} style={{ minWidth: '1440px', width: '100%' }}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.leftDiv}>
              <Link to="/">
                <img alt="Logo" src={logo} />
                <img alt="Mentors" src={logoText} />
              </Link>
            </div>
            <div className={classes.midDiv}>
              <Button onClick={this.onLinkClicked} value="search" className={classes.link} data-url="/search">
                <FormattedMessage id="nav_search" />
              </Button>
              <Button onClick={this.onLinkClicked} to="/event" className={classes.link} data-url="/event">
                <FormattedMessage id="nav_event" />
              </Button>
              <Button onClick={this.onLinkClicked} to="/coupon" className={classes.link} data-url="/coupons">
                <FormattedMessage id="nav_coupon" />
              </Button>
              <Button onClick={this.onLinkClicked} to="/about-us" className={classes.link} data-url="/about-us">
                <FormattedMessage id="about_us" />
              </Button>
              <Button onClick={this.onLinkClicked} to="/contact" className={classes.link} data-url="/contact">
                <FormattedMessage id="nav_contact" />
              </Button>
            </div>
            <div className={classes.rightDiv}>
              {
                _.isEmpty(user)
                  ? (
                    <React.Fragment>
                      <Button color="inherit" className={classes.login} onClick={this.onLinkClicked} data-url="/signIn">
                        <FormattedMessage id="nav_login" />
                      </Button>
                      <Button color="inherit" className={classes.signUpButton} onClick={this.onLinkClicked} data-url="/signUp">
                        <FormattedMessage id="nav_signUp" />
                      </Button>
                    </React.Fragment>
                  )
                  : (
                    <React.Fragment>
                      <FormattedMessage id="nav_welcome">
                        {msg => (
                          <Welcome>
                            {msg}
                            {' '}
                            {user.first_name}
                          </Welcome>
                        )}
                      </FormattedMessage>
                      <Button color="inherit" className={classes.login} onClick={this.onLogOut}>
                        <FormattedMessage id="nav_logout" />
                      </Button>
                    </React.Fragment>
                  )
              }
              <Button
                color="inherit"
                onClick={this.onChangeLanguage}
                className={classes.language}
              >
                EN/中文
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
const Welcome = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;
const mapStateToProps = state => ({
  user: state.authentication.user,
});
const mapDispatchToProps = ({
  toggleLanguage,
  logOut,
});
Nav.propTypes = {
  user: PropTypes.object.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Nav)));
