import font from './font';

const styles = {
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#000',
    },
    textPrimary: {
      main: '#FFF',
    },
    textSecondary: {
      main: '#0B0146',
    },
    background: {
      primary: '#05B8FF',
      secondary: '#ff9700',
      navy: '#0b0146',
      white: '#FFF',
      blue: '#bbecff',
      orange: '#FF9700',
    },
    text: {
      primary: '#FFF',
      secondary: '#0B0146',
      error: '#ff0000',
    },
  },
  typography: {
    useNextVariants: true,
  },
  fontFamily: {
    primary: font.fontName,
    // secondary: font.font_2.fontName,
    // font_3: font.font_3.fontName,
    // font_4: font.font_4.fontName,
  },
  overrides: {
    MuiButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
};

export default styles;
