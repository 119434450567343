export const goPrevPage = (history, location) => {
  if (location.state !== undefined) {
    history.push({
      pathname: location.state.from ? location.state.from : '/',
      state: {
        from: location.pathname,
      },
    });
  } else {
    history.push({
      pathname: '/',
      state: {
        from: location.pathname,
      },
    });
  }
};

export const jumpTo = (pathname, history, location) => {
  history.push({
    pathname,
    state: {
      from: location.pathname,
    },
  });
};
