import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import './index.css';
import { FormattedMessage } from 'react-intl';
import img1 from './imgs/past/1.jpg';
import img2 from './imgs/past/2.JPG';
import img3 from './imgs/past/3.JPG';
import img4 from './imgs/past/4.JPG';
import img5 from './imgs/past/5.JPG';
import img6 from './imgs/past/6.JPG';
import img7 from './imgs/past/7.JPG';
import img8 from './imgs/past/8.JPG';
import img9 from './imgs/past/9.jpg';
import img10 from './imgs/past/10.jpg';
import img11 from './imgs/past/11.JPG';
import img12 from './imgs/past/12.JPG';
import img13 from './imgs/past/13.jpg';
import img14 from './imgs/past/14.jpg';
import img15 from './imgs/past/15.jpg';
import img16 from './imgs/past/16.jpg';
import img17 from './imgs/past/17.JPG';
import img18 from './imgs/past/18.JPG';
import img19 from './imgs/past/19.JPG';
import img20 from './imgs/past/20.JPG';
import img21 from './imgs/past/B1.jpg';
import img22 from './imgs/past/B2.jpg';
import img23 from './imgs/past/B3.jpg';
import img24 from './imgs/past/B4.jpg';

import img25 from './imgs/past/G1.JPG';
import img26 from './imgs/past/G2.JPG';
import img27 from './imgs/past/G3.jpg';
import img28 from './imgs/past/G4.jpg';

import img29 from './imgs/past/Education Show-1.jpg'
import img30 from './imgs/past/Education Show-2.jpg'
import img31 from './imgs/past/Education Show-3.jpg'
import img32 from './imgs/past/Education Show-4.jpg'
import img33 from './imgs/past/Education Show-5.jpg'
import img34 from './imgs/past/Education Show-6.jpg'
import img35 from './imgs/past/Education Show-7.jpg'
import img36 from './imgs/past/Education Show-8.jpg'
import img37 from './imgs/past/Education Show-9.jpg'
import img38 from './imgs/past/Education Show-10.jpg'
import img39 from './imgs/past/Education Show-11.jpg'
import img40 from './imgs/past/Education Show-12.jpg'

import background from './imgs/kids-3448011_1920.png';
import event1 from './imgs/event1.jpg';
import event2 from './imgs/event2.jpg';
import event3 from './imgs/upcoming/flyer-front-final.jpg';
import CarouselModal from './CarouselModal/CarouselModal';
import Modal from '../../components/widget/Modal';
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
  button: {
    marginTop: '18px',
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 30,
    color: theme.palette.text.primary,
    fontFamily: theme.fontFamily.primary,
    fontWeight: '400',
    width: '130px',
    height: '36px',
    lineHeight: 1,
    textTransform: 'none',
    fontSize: '16px',
  },
});

const carouselData = [
  {
    imgUrl: img40,
    index: 40
  },
  {
    imgUrl: img39,
    index: 39,
  },
  {
    imgUrl: img38,
    index: 38,
  },
  {
    imgUrl: img37,
    index: 37,
  },
  {
    imgUrl: img36,
    index: 36
  },
  {
    imgUrl: img35,
    index: 35,
  },
  {
    imgUrl: img34,
    index: 34,
  },
  {
    imgUrl: img33,
    index: 33,
  },
  {
    imgUrl: img32,
    index: 32
  },
  {
    imgUrl: img31,
    index: 31,
  },
  {
    imgUrl: img30,
    index: 30,
  },
  {
    imgUrl: img29,
    index: 29,
  },

  {
    imgUrl: img25,
    index: 28
  },
  {
    imgUrl: img26,
    index: 27,
  },
  {
    imgUrl: img27,
    index: 26,
  },
  {
    imgUrl: img28,
    index: 25,
  },

  {
    imgUrl: img21,
    index: 24,
  },
  {
    imgUrl: img22,
    index: 23,
  },
  {
    imgUrl: img23,
    index: 22,
  },
  {
    imgUrl: img24,
    index: 21,
  },
  {
    imgUrl: img17,
    index: 20,
  },
  {
    imgUrl: img18,
    index: 19
  },
  {
    imgUrl: img19,
    index: 18,
  },
  {
    imgUrl: img20,
    index: 17,
  },
  {
    imgUrl: img1,
    index: 16,
  },
  {
    imgUrl: img2,
    index: 15,
  },
  {
    imgUrl: img3,
    index: 14,
  },
  {
    imgUrl: img4,
    index: 13,
  },
  {
    imgUrl: img5,
    index: 12
  },
  {
    imgUrl: img6,
    index: 11,
  },
  {
    imgUrl: img7,
    index: 10,
  },
  {
    imgUrl: img8,
    index:9,
  },
  {
    imgUrl: img9,
    index: 8,
  },
  {
    imgUrl: img10,
    index: 7,
  },
  {
    imgUrl: img11,
    index: 6,
  },
  {
    imgUrl: img12,
    index: 5,
  },
  {
    imgUrl: img13,
    index: 4,
  },
  {
    imgUrl: img14,
    index: 3,
  },
  {
    imgUrl: img15,
    index: 2,
  },
  {
    imgUrl: img16,
    index: 1,
  },

];

const StyledButton = withStyles(styles)(
  ({classes,children,...props}) =>(<Button className={classes.button} {...props}>{children}</Button>)
)

class EventPage extends React.Component {
  state = {
    isCarouselOpen: false,
    activeIndex: 0,
  };

  _onImgClicked = (e) => {
    const activeIndex = e.target.dataset.index;
    document.getElementById('root').classList.add('blur');
    this.setState({
      activeIndex,
      isCarouselOpen: true,
    });
  };

  getModal = () => {
    const { activeIndex } = this.state;
    const removeRootNodeBlurEffect = () => document.getElementById('root').classList.remove('blur');
    return (
      <Modal onWillUnmount={removeRootNodeBlurEffect}>
        <CarouselModal
          onOutterAreaClicked={() => {
            removeRootNodeBlurEffect();
            this.setState({
              isCarouselOpen: false,
            });
          }}
          carouselData={carouselData}
          activeIndex={activeIndex}
        />
      </Modal>
    );
  };

  render() {
    const { isCarouselOpen } = this.state;
    const modal = isCarouselOpen ? this.getModal() : null;
    return (
      <div>
        <Banner>
          <Title><FormattedMessage id="eventPage_events" /></Title>
        </Banner>
        <Events>
          <SubTitle><FormattedMessage id="eventPage_subtitle" /></SubTitle>
          <EventGroup>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <Event1 />
              {/*<StyledButton p={100} href="https://www.eventbrite.ca/e/91678959235" target="_blank">*/}
              {/*  <FormattedMessage id="eventbrite_register" />*/}
              {/*</StyledButton>*/}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <Event2 />
              {/*<StyledButton p={100} href="https://www.eventbrite.ca/e/91678959235" target="_blank">*/}
              {/*  <FormattedMessage id="eventbrite_register" />*/}
              {/*</StyledButton>*/}
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <Event3 />
              {/*<StyledButton p={100} href="https://www.eventbrite.ca/e/91678959235" target="_blank">*/}
              {/*  <FormattedMessage id="eventbrite_register" />*/}
              {/*</StyledButton>*/}
            </div>
          </EventGroup>
        </Events>
        <Show>
          <SubTitle><FormattedMessage id="eventPage_previous_events" /></SubTitle>
          <Grid>
            { carouselData.sort((prev, next) => next.index-prev.index).map(item=>
            <GridItem src={item.imgUrl} onClick={this._onImgClicked} data-index={item.index} />
            )}

          </Grid>
        </Show>
        {modal}
      </div>
    );
  }
}
const GridItem = styled.div`
  width: 230px;
  height: 230px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
`;
const Grid = styled.div`
  display: grid;
  width: 950px;
  margin: 0 auto;
  grid-template-columns: 230px 230px 230px 230px;
  grid-template-rows: 230px 230px 230px 230px;
  grid-gap: 10px;
`;
const Banner = styled.div`
  background-image: url("${background}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  position: relative;
`;
const Title = styled.div`
  position: absolute;
  bottom: 32px;
  text-align: center;
  width: 100%;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
  color: ${props => props.theme.palette.textPrimary.main};
  font-size: 50px;
  line-height: 67px;
  height: 67px
`;
const Events = styled.div`
  background-color: #BBECFF;
  height: 866px;
  padding-top: 98px;
`;
const SubTitle = styled.div`
  text-align: center;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
  font-size: 28px;
  color: #0b0146;
  margin-bottom: 71px;
`;
const EventGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1201px;
  margin: 43px auto;
`;
const Event1 = styled.div`
  background-image: url("${event1}");
  background-size: cover;
  background-position: center;
  width: 393px;
  height: 524px;
`;
const Event2 = styled.div`
  background-image: url("${event3}");
  background-size: cover;
  background-position: center;
  width: 393px;
  height: 524px;
`;
const Event3 = styled.div`
  background-image: url("${event2}");
  background-size: cover;
  background-position: center;
  width: 393px;
  height: 524px;
`;
const Show = styled.div`
  padding-top: 112px;
  padding-bottom: 93px;
`;
const mapStateToProps = state => ({
  language: state.language.language,
});
export default connect(mapStateToProps, null)(withTheme(EventPage));
