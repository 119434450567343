import React from 'react';
import styled, { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import background from './imgs/kids-3448011_1920.png';
import dolphin from './imgs/dolphin.png';
import PhotoShow from '../../components/PhotoShow';
import FaceBookIcon from '../../components/FaceBookIcon/FaceBookIcon';
import { FACE_BOOK_URL, INSTAGRAM_URL } from '../../constants/socialLinks';
import InstagramIcon from '../../components/InstagramIcon/InstagramIcon';

const socialLinksStyle = {
  fontSize: '30px',
};

const BlueHeader = styled.h3`
   text-align: left;
  margin-bottom: 31px;
  font-family: ${props => props.theme.fontFamily.font_2};
  font-size: 20px;
  color: ${props => props.theme.palette.background.primary};;
`;
const P = styled.p`
  font-family: ${props => props.theme.fontFamily.font_2};
  font-size: 18px;
  color: #565656;
  margin: 31px auto;
`;
const EnAboutContext = () => (
  <>
    <Description>
      Our Value Proposition
    </Description>
    <Description>
      Mentors, One Stop Youth Education Solution Provider with Online Courses,
      Schools Information with Rating, Professional Services and Offline Events,
      to Meet All the Education Needs of Parents and Students in an Easier and Most Affordable Way !
    </Description>
    <Description>
      Why We Start?
    </Description>
    <P>
      As parents of three lovely kids, I and my partner spend so much time and money to try to find the right educational and extracurricular program providers for our kids.
      However, the result is not always as we expected...In the meantime, we have been asked often by many of our friends who also have young kids like us,
      “where to find a good school, club, trainer, or camp near us?”, questions like that.
      We are all facing the same challenges on our kids educational and personal development needs, especially to those many families who are new to this country.
    </P>
    <P>
      This is why we started our business, Mentors, at the beginning of 2019 to solve this issue and, help all the parents in GTA as well as in the entire Canada. Mentors is dedicated to providing a valuable and trusted platform to connect millions of parents and high quality education providers through our online presence as well as many offline events all year long.
    </P>
    <Description>
      Our Services
    </Description>
    <BlueHeader>
      Mentors Future Class and Future Camp
    </BlueHeader>
    <P>
      Mentors carefully choose and work closely with many high quality education institutions and teachers to
      custom build two online education programs, Mentors Future Class and Mentors Future Camp,
      which combine live classes and recorded courses to meet all the parents and students&#39; needs of ongoing online education as well as summer camp.
    </P>
    <BlueHeader>
      Education Providers&#39; Information with Advanced Search Engine
    </BlueHeader>
    <P>
      Mentors&#39; advanced Search Engine function allows parents to easily locate suitable education institutions for their kids near them. All the schools and institutions on our website are hand picked by Mentors professional team and, cover a wide range of categories of Academics, Arts, Sports, Music, After Schools, Day Cares, Summer Camps, March Break and Winter Break Camps as well as Private Schools.
    </P>
    <BlueHeader>
      Authentic Parents&#39; Online Review & Rating System
    </BlueHeader>
    <P>
      Mentors&#39; online Review and Rating System offers honest feedback and references of schools from other parents or students to help you make better and easier decision when come to choose education providers for your kids. We encourage all the parents to sign up on Mentorserv.com to leave your highly valuable feedbacks to schools that your kids tried in order to help other parents.
    </P>
    <BlueHeader>
      Exclusive Coupons and Promotions
    </BlueHeader>
    <P>
      On Mentorserv.com, we prepared many great deals for you from our partnered schools and vendors, including discounts, free trial classes, etc. Sign up to become our members to redeem these great offers Today!
    </P>
    <BlueHeader>
      Events, Education Shows and Carnival
    </BlueHeader>
    <P>
      Mentors organize many meaningful offline events all year long for parents to attend together with their kids, such as Summer Carnival, Summer Camp & Education Show, Back to School Show, Family Sports Contests, Parenting Seminars, Concerts, Holiday Parties, etc. Check out our Event page and Social Media to stay tuned with our upcoming exciting events.
    </P>
    <BlueHeader>
      Parenting Counselling and Education Planning Services
    </BlueHeader>
    <P>
      Mentors teamed up with highly experienced and certified professions and experts to offer one-on-one parenting and family counselling, as well as assessment and education planning services to our VIP members. For more information, please contact us.
    </P>
    <Description>
      Our Community Impact
    </Description>
    <P>
      Mentors make all the parents lives much easier to find local trusted and high quality extracurricular program providers, camps and private schools for their kids;
      <br />
      Mentors bring many meaningful, helpful and exciting events, shows and camps to our community in order to increase the bond between parents and their kids, such as Parenting Seminars, Summer Carnival, Education Trade Shows, Schools/Clubs Tour and Open House, International Summer Camps, Family Sports Contests, Concerts, etc.;
      <br />
      Mentors always help people in need by donation to charities like Salvation Army, Sick Kids Hospital, etc.;
      <br />
      Mentors help to improve local education providers&#39; service level and their programs&#39; quality.
      <br />
      Overall, we are striving and determined to give our future generations better education to help them grow and succeed in the new era.
    </P>
    <Description style={{ fontStyle: 'italic' }}>
      "The Best Choice for your Kids"
    </Description>
  </>
);

const CnAboutContext = () => (
  <>
    <Description>
      <FormattedMessage id="about_us_description" />
    </Description>
    <SubDescription>
      <FormattedMessage id="about_us_sub_description" />
    </SubDescription>
    <PointsWrapper>
      <Points>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <FormattedMessage id="about_us_point_1" />
        </Li>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <FormattedMessage id="about_us_point_2" />
        </Li>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <FormattedMessage id="about_us_point_3" />
        </Li>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <FormattedMessage id="about_us_point_4" />
        </Li>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <FormattedMessage id="about_us_point_5" />
        </Li>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <span>
            教育机构课程优惠以及合作商家特价
          </span>
        </Li>
        <Li>
          <Icon src={dolphin} alt="icon" />
          <span>
            一对一亲子咨询和教育规划服务（VIP 会员 Only)
          </span>
        </Li>
      </Points>
    </PointsWrapper>
  </>
);

const AboutUs = ({language, ...props}) => {
  const contentRender = language === 'zh-hans' ? <CnAboutContext /> : <EnAboutContext />
  return (
    <React.Fragment>
      <Wrapper>
        <Img alt="background" src={background} />
        <Title>
          <FormattedMessage id="about_us" />
        </Title>
      </Wrapper>
      <SubTitle>
        <FormattedMessage id="the_best_choice_for_your_kids" />
      </SubTitle>
      <Wrapper2>
        <DescriptionWrapper>
          {contentRender}
          <BottomDescription><FormattedMessage id="about_us_sub_description_bottom" /></BottomDescription>
          <SocialLinks>
            <FaceBookIcon
              url={FACE_BOOK_URL}
              style={socialLinksStyle}
            />
            <InstagramIcon
              url={INSTAGRAM_URL}
              style={socialLinksStyle}
            />
          </SocialLinks>
        </DescriptionWrapper>
      </Wrapper2>
      <PhotoShow />
    </React.Fragment>
  );
};
const BottomDescription = styled.div`
  text-align: center;
  font-family: ${props => props.theme.fontFamily.font_2};
  color: #565656;
`;
const Wrapper = styled.div`
  position: relative;
`;
const Img = styled.img`
  width: 100%
`;
const Icon = styled.img`
  margin-right: 14px;
`;
const Title = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 65px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-weight: 400;
  font-size: 50px;
  color: ${props => props.theme.palette.textPrimary.main};
`;
const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: 35px;
  color: ${props => props.theme.palette.textPrimary.main};
  background-color: ${props => props.theme.palette.background.secondary};
  height: 70px;
`;

const DescriptionWrapper = styled.div`
  width: 947px;
  margin: 176px 0;
`;
const Description = styled.div`
  text-align: center;
  margin-bottom: 31px;
  font-family: ${props => props.theme.fontFamily.font_2};
  font-size: 28px;
  color: #565656;
`;
const SubDescription = styled.div`
  text-align: center;
  font-family: ${props => props.theme.fontFamily.font_2};
  font-size: 24px;
  color: #565656;
  width: 70%;
  margin: 31px auto;
`;
const PointsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Points = styled.ul`
  list-style: none;
  padding: 0;
`;
const Wrapper2 = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
`;
const Li = styled.li`
  margin-bottom: 13px;
  font-size: 18px;
  color: #565656
`;
const SocialLinks = styled.div`
  text-align: center;
  margin: 10px;
`;
const mapStateToProps = state => ({
  language: state.language.language,
});
AboutUs.propTypes = {
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(withTheme(AboutUs));
