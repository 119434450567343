import { SET_MAP_CENTER, SET_MAP_LOADING } from './actionTypes';

export const setMapCenter = location => (
  {
    type: SET_MAP_CENTER,
    payload: location,
  }
);

export const setMapLoading = () => ({
  type: SET_MAP_LOADING,
});
