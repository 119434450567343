import {
  LOG_IN_SUCCESS,
  LOG_IN_PENDING,
  LOG_IN_FAIL,
  LOG_OUT_SUCCESS,
  LOG_OUT_PENDING,
  LOG_OUT_FAIL,
  SIGN_UP_PENDING,
  SIGN_UP_FAIL,
  SIGN_UP_SUCCESS,
  SET_USER,
  VALIDATE_INPUT_FAIL,
} from './actionTypes';

import axios from '../axios';

export const setUser = user => ({
  type: SET_USER,
  payload: user,
});
export const login = (user) => (dispatch) => {
  dispatch({
    type: LOG_IN_PENDING,
  });
  return axios.post('/account/login/', user)
    .then((response) => {
      dispatch({
        type: LOG_IN_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(); // is equal to not call return statement
    })
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: LOG_IN_FAIL,
          payload: error.response.data.errors,
        });
      }
      throw error; // important! you have to throw error here in order to let chained RejectHandler use error object
    });
};

export const signUp = userInfo => (dispatch) => {
  dispatch({
    type: SIGN_UP_PENDING,
  });
  return axios.post('/account/registration/', userInfo, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then((response) => {
      dispatch({ type: SIGN_UP_SUCCESS });
      return Promise.resolve(response);
    })
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: SIGN_UP_FAIL,
          payload: error.response.data.errors,
        });
      }
      throw error;
    });
};

export const logOut = () => (dispatch) => {
  dispatch({
    type: LOG_OUT_PENDING,
  });
  axios.post('/account/logout/')
    .then(response => dispatch({
      type: LOG_OUT_SUCCESS,
      payload: response.data,
    }))
    .catch(error => dispatch({
      type: LOG_OUT_FAIL,
      payload: error.response.data.errors,
    }));
};

export const setError = error => ({
  type: VALIDATE_INPUT_FAIL,
  payload: error,
});
