// This modal is rendered as a child node of document.body
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import '../../container/EventPage/index.css';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    // Create a DOM node as root of this modal;
    this.modalRoot = document.createElement('div');
    document.body.appendChild(this.modalRoot);
  }

  // componentDidMount() {
  //   // The portal element is inserted in the DOM tree after
  //   // the Modal's children are mounted, meaning that children
  //   // will be mounted on a detached DOM node. If a child
  //   // component requires to be attached to the DOM tree
  //   // immediately when mounted, for example to measure a
  //   // DOM node, or uses 'autoFocus' in a descendant, add
  //   // state to Modal and only render the children when Modal
  //   // is inserted in the DOM tree.
  //   const modalRoot = document.getElementById('event-page-modal-root');
  //   modalRoot.appendChild(this.el);
  // }

  componentWillUnmount() {
    const { onWillUnmount } = this.props;
    if (onWillUnmount) onWillUnmount();
    // Remove modal root from DOM
    this.modalRoot.parentElement.removeChild(this.modalRoot);
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(
      children,
      this.modalRoot,
    );
  }
}

Modal.propTypes = {
  children: PropTypes.number.isRequired,
  onWillUnmount: PropTypes.func,
};

Modal.defaultProps = {
  onWillUnmount: null,
};
export default Modal;
