import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BootstrapCarousel from '../../../components/widget/BootstrapCarousel';
import ViewPortBlurEffect from '../../../components/widget/ViewPortBlurEffect';

function CarouselModal(props) {
  const { onOutterAreaClicked, carouselData, activeIndex } = props;
  return (
    <React.Fragment>
      <ViewPortBlurEffect onClick={onOutterAreaClicked} style={{ backgroundColor: 'rgba(0,0,0,.6)' }} />
      <PopOutWrapper>
        <BootstrapCarousel data={carouselData} activeIndex={activeIndex} />
      </PopOutWrapper>
    </React.Fragment>
  );
}

const PopOutWrapper = styled.div`
    width: 65vw;
    height: 55vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
`;

CarouselModal.propTypes = {
  onOutterAreaClicked: PropTypes.func.isRequired,
  carouselData: PropTypes.array.isRequired,
  activeIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default CarouselModal;
