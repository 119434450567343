import { combineReducers } from 'redux';
import languageReducer from './languageReducer';
import authenticationReducer from './authenticationReducer';
import registrationReducer from './registrationReducer';
import locationReducer from './locationReducer';
import schoolReducer from './schoolReducer';
import searchBarReducer from './searchBarReducer';
import filterReducer from './filterReducer';
import schoolDetailReducer from './schoolDetailReducer';
import reviewReducer from './reviewReducer';
import mapReducer from './mapReducer';
import dialogReducer from './dialogReducer';

const uiReducer = combineReducers({
  dialog: dialogReducer,
});
const rootReducer = combineReducers({
  language: languageReducer,
  authentication: authenticationReducer,
  registration: registrationReducer,
  location: locationReducer,
  schools: schoolReducer,
  schoolDetail: schoolDetailReducer,
  searchBar: searchBarReducer,
  filter: filterReducer,
  review: reviewReducer,
  map: mapReducer,
  ui: uiReducer,
});

export default rootReducer;
